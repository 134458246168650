import * as React from 'react';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { useTranslation } from '../../../providers';
import { getFileHeader } from '../../../services/analysis';
import { OrganizationOfFile } from '../../../services/types/analysis';
import { TIMERs, Translation } from '../../../static';
import { SelectFieldValue } from '../../common';
import { useAnalysis } from '../analysis-context';
import {Dispatch, SetStateAction, useState} from "react";

interface IValidationContext {
  fileHeader: SelectFieldValue[] | null;
  setFileHeader: (orgOfFiles: OrganizationOfFile, next: () => void) => void;
  columnSeparator: string;
  setColumnSeparator: Dispatch<SetStateAction<string>>;
}

const MEMO: Record<string, SelectFieldValue[]> = {};
const ValidationContext = React.createContext({} as IValidationContext);
const ValidationProvider = ({ children }: { children: React.ReactNode }) => {
  const { show, clean } = useCleanPopup();
  const { state } = useAnalysis();
  const { translate } = useTranslation();
  const [fileHeader, setFileHeader] = React.useState<SelectFieldValue[] | null>(null);
  const [columnSeparator, setColumnSeparator] = useState(';');

  const value: IValidationContext = {
    fileHeader,
    setFileHeader: (orgOfFiles, next) => {
      console.log('setFileHeader', state)
      const datasetId = state.dataset?._id;
      const key = `${Object.values(orgOfFiles).join('-')}_${datasetId}`;

      if (!MEMO[key]) {
        getFileHeader(orgOfFiles, datasetId)
          .then((columns: string[]) => {
            const fileHeader = columns.map((col: string) => ({ label: col, value: col }));

            setFileHeader(fileHeader);
            MEMO[key] = fileHeader;
            next();
          })
          .catch(() => {
            show(translate(Translation.analysis.validation.failedToGetFileHeader));
            clean(TIMERs.threeSeconds);
          });
      } else {
        setFileHeader(MEMO[key]);
        next();
      }
    },
    columnSeparator,
    setColumnSeparator
  };

  return <ValidationContext.Provider value={value}>{children}</ValidationContext.Provider>;
};

const useValidation = () => React.useContext(ValidationContext);

export { useValidation, ValidationProvider };
