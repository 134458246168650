import * as React from 'react';
import styled from 'styled-components';
import { Colors, dropShadow } from '../static';

type SetPopup = {
  popup: JSX.Element | null;
  isError: boolean;
  opacity: number;
};

const MessageContainer = styled.section`
  height: 100%;
  width: 100%;
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: ${({ opacity }: { opacity: number }) => opacity}; // 0.9 | 0.5
    background-color: ${Colors.white};
  }

  .content {
    filter: ${dropShadow};
    z-index: 4;
    width: 100%;
    height: 96%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

type Fn = { setPopup: (popup: JSX.Element | null, isError?: boolean, opacity?: number) => void };
const PopupContext = React.createContext<Fn>({} as Fn);

const PopupProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ popup, isError, opacity }, setInternalPopup] = React.useState<SetPopup>({
    popup: null,
    isError: false,
    opacity: 0.9,
  });
  const ref = React.useRef({
    setPopup: (popup: JSX.Element | null, isError = false, opacity = 0.9) =>
      setInternalPopup?.({
        popup,
        isError,
        opacity,
      }),
  });

  return (
    <PopupContext.Provider value={ref.current}>
      {popup ? (
        <MessageContainer opacity={opacity ? opacity : isError ? 0.9 : 0.5}>
          <section className='content'>{popup}</section>
          <section className='overlay'></section>
        </MessageContainer>
      ) : null}
      {children}
    </PopupContext.Provider>
  );
};

const usePopup = () => React.useContext(PopupContext);
export { usePopup, PopupProvider };
