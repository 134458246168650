import { Colors } from '../../../static';

export const AddIcon = () => (
  <svg width='23' height='24' viewBox='0 0 23 24' fill='none'>
    <line x1='11.3666' y1='6.85718' x2='11.3666' y2='17.1429' stroke='white' />
    <line x1='6.51965' y1='12.0714' x2='16.2995' y2='12.0714' stroke='white' />
    <path
      d='M0.5 11.4098C0.5 5.3845 5.3845 0.5 11.4098 0.5C17.4352 0.5 22.3197 5.3845 22.3197 11.4098V12.5902C22.3197 18.6155 17.4352 23.5 11.4098 23.5C5.3845 23.5 0.5 18.6155 0.5 12.5902V11.4098Z'
      stroke='white'
    />
  </svg>
);

export const ChangeClientIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M2.61426 17.8587H17.8143C20.5285 17.8587 21.6143 16.0126 21.6143 13.551M2.61426 17.8587L5.8714 14.1664M2.61426 17.8587L5.8714 21.551'
      stroke={Colors.tileBtnBgColor}
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M21.6143 6.24333L6.41426 6.24333C3.69997 6.24333 2.61426 8.08949 2.61426 10.551M21.6143 6.24333L18.3571 9.93564M21.6143 6.24333L18.3571 2.55103'
      stroke={Colors.tileBtnBgColor}
      strokeLinecap='square'
      strokeLinejoin='round'
    />
  </svg>
);

type ArrowTypes = {
  className?: string;
  active: boolean;
  style?: React.CSSProperties;
};

export const RightArrowIcon = ({ className, active, style }: ArrowTypes) => (
  <svg width='5' height='8' viewBox='0 0 5 8' fill='none' className={className} style={style}>
    <path
      d='M4.35355 4.35355C4.54882 4.15829 4.54882 3.84171 4.35355 3.64645L1.17157 0.464466C0.976311 0.269204 0.659729 0.269203 0.464467 0.464466C0.269204 0.659728 0.269204 0.97631 0.464467 1.17157L3.29289 4L0.464466 6.82843C0.269203 7.02369 0.269203 7.34027 0.464465 7.53553C0.659728 7.7308 0.97631 7.7308 1.17157 7.53553L4.35355 4.35355ZM3 4.5L4 4.5L4 3.5L3 3.5L3 4.5Z'
      fill={active ? 'black' : 'white'}
    />
  </svg>
);

export const LeftArrowIcon = () => (
  <svg width='5' height='8' viewBox='0 0 5 8' fill='none'>
    <path
      d='M0.646447 3.64645C0.451185 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659729 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM2 3.5L1 3.5L1 4.5L2 4.5L2 3.5Z'
      fill='white'
    />
  </svg>
);

export const LogoutIcon = () => (
  <svg width='21' height='23' viewBox='0 0 21 23' fill='none'>
    <path
      d='M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5H15.918C18.4033 0.5 20.418 2.51472 20.418 5V18C20.418 20.4853 18.4033 22.5 15.918 22.5H5C2.51472 22.5 0.5 20.4853 0.5 18V5Z'
      stroke={Colors.white}
    />
    <svg width='18' height='20' viewBox='0 -4 7 15' fill='none'>
      <path
        d='M8.61587 4.35355C8.81113 4.15829 8.81113 3.84171 8.61587 3.64645L5.43389 0.464467C5.23863 0.269204 4.92205 0.269204 4.72679 0.464466C4.53152 0.659729 4.53152 0.976311 4.72678 1.17157L7.55521 4L4.72678 6.82843C4.53152 7.02369 4.53152 7.34027 4.72678 7.53553C4.92205 7.7308 5.23863 7.7308 5.43389 7.53553L8.61587 4.35355ZM0.655762 4.5L8.26232 4.5L8.26232 3.5L0.655762 3.5L0.655762 4.5Z'
        fill={Colors.white}
      />
    </svg>
  </svg>
);
