export const FormNames = Object.freeze({
  firstName: 'firstName',
  lastName: 'lastName',
  countryCode: 'countryCode',
  email: 'email',
  password: 'password',
  phone: 'phone',
  image: 'image',
  role: 'role',
  disabled: 'disabled',
  clientId: 'clientId',
  newAnalysis: 'newAnalysis',
  viewResults: 'viewResults',
  deleteDatasets: 'deleteDatasets',
  updateDatasets: 'updateDatasets',
  deleteResults: 'deleteResults',
  downloadResults: 'downloadResults',
});

export const EditableFormNames = Object.freeze([
  FormNames.firstName,
  FormNames.lastName,
  FormNames.countryCode,
  FormNames.email,
  FormNames.phone,
]);

export const AccessRightsFormNames = Object.freeze([
  FormNames.newAnalysis,
  FormNames.viewResults,
  FormNames.deleteDatasets,
  FormNames.updateDatasets,
  FormNames.deleteResults,
  FormNames.downloadResults,
]);

export const AnalysisDirectionMode = Object.freeze([
  
])

export const DownloadError = Object.freeze({
  socketHangUp: 'socket hang up'
})

export const PopUpMessageColor = Object.freeze({
  green: 'green'
})
