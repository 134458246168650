import { useTranslation } from '../../../providers';
import { Colors, Translation } from '../../../static';
import { Button } from '../../common';
import { BigErrorIcon } from '../../common/icons';
import { useAnalysis } from '../analysis-context';

const { summary } = Translation.analysis;
const errorStyle = { color: Colors.menuColor, fontWeight: 500 };

export const ErrorLaunchedSummary = () => {
  const { translate } = useTranslation();
  const { start } = useAnalysis();

  return (
    <section className='border errorLaunched'>
      <BigErrorIcon size={80} />

      <section style={errorStyle}>{translate(summary.errorLaunchAnalysis)}</section>

      <Button className='launchNewBtn' onClick={start}>
        {translate(summary.launchNewAnalysis)}
      </Button>
    </section>
  );
};
