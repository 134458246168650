import styled from 'styled-components';
import * as React from 'react';
import { Colors } from '../../static';
import { FieldContainer } from './styled';
import { Circle, SmallErrorIcon, SuccessIcon } from './icons';
import { ForbiddenIcon } from './icons/ForbiddenIcon';

const SliderContainer = styled.label`
  position: relative;
  width: 50px;
  height: 25px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #999;
  }

  input:checked + .slider {
    background-color: ${Colors.blue};
  }

  .slider {
    border-radius: 34px;
  }
`;

type SliderProps = {
  onChange?: (value: boolean) => void;
  name?: string;
  label?: string;
  defaultChecked: boolean;
  disabled?: boolean;
  inline?: boolean;
  style?: React.CSSProperties;
};

const getStyle = (checked: boolean) => ({
  transition: '0.3s',
  margin: '2px',
  transform: checked ? 'translateX(26px)' : '',
});
const disabledStyle = { opacity: 0.5 };
export const Slider = ({
  onChange,
  defaultChecked,
  name,
  disabled,
  label,
  inline = true,
  style,
}: SliderProps) => {
  const [checked, setChecked] = React.useState(defaultChecked);

  return (
    <FieldContainer
      hasLabel={Boolean(label)}
      className={inline ? '_inline' : '_block'}
      style={style}
    >
      {label ? <label>{label}</label> : null}

      <SliderContainer className='switch' style={disabled ? disabledStyle : { opacity: 1 }}>
        <input
          disabled={disabled}
          name={name}
          type='checkbox'
          checked={checked}
          onChange={async ({ target }) => {
            setChecked(target.checked);
            try {
              await onChange?.(target.checked);
            } catch {
              setChecked(!target.checked);
            }
          }}
        />
        <span
          className='slider round'
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
        >
          <Circle size={20} bgColor={Colors.white} style={getStyle(checked)}>
            {disabled ? (
              <ForbiddenIcon strokeColor={Colors.error} />
            ) : checked ? (
              <SuccessIcon strokeColor={Colors.blue} />
            ) : (
              <SmallErrorIcon />
            )}
          </Circle>
        </span>
      </SliderContainer>
    </FieldContainer>
  );
};
