import {AgGridColumn, AgGridReact, AgGridReactProps} from 'ag-grid-react';
import * as React from 'react';
import {useState} from 'react';
import styled from 'styled-components';
import {useClickOutside} from '../../hooks/use-click-outside';
import {useCleanPopup} from '../../hooks/useCleanPopup';
import { useTranslation, useAuth } from '../../providers';
import { TIMERs, Translation, Colors } from '../../static';
import { CloseIcon, TrashIcon } from './icons';
import { DeletePopupComponent } from './DeletePopup';
import {Loader} from './Loader';
import { DatasetFileMenuItem } from '../../services/types/analysis';

const Container = styled.section`
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;

  .display {
    margin-right: 5px;
    visibility: hidden;
  }

  :hover {
    .display {
      visibility: visible;
    }
  }

  .explore-menu {
    width: calc(15% + 5px);
    display: flex;
    flex-direction: column;
    /*padding: 3px;*/
    padding-right: 5px;
    padding-top: 5px;
    margin: 10px 0;
    margin-right: -17px;
    background-color: ${Colors.menuColor};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow-y: scroll;

    .dataset-file {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      margin: 5px 10px;
      margin-right: 0;

      &.active {
        background-color: ${Colors.blue};
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      button {
        border: none;
        padding: 3px;
        margin: 3px 0;
        background: none;
        cursor: pointer;
        text-align: left;
        font-weight: 600;
        color: white;
        font-size: 12px;
      }
    }
  }
`;

const forbiddenStyle = { cursor: 'not-allowed', opacity: 0.5 };
const okStyle = { cursor: 'pointer' };

type RawRenderProps = {
  callback: (args?:any) => Promise<Record<string, string>[]>;
  deleteCallback?: (args?:any) => Promise<any>;
  menu?: DatasetFileMenuItem[];
};

const commonProps = { sortable: true, filter: true, resizable: true };

export const RawRendererPopup = ({ callback, menu, deleteCallback }: RawRenderProps) => {
  const { clean, show } = useCleanPopup();
  const { translate } = useTranslation();
  const ref = React.useRef<HTMLElement | null>(null);
  const GridRef = React.useRef<AgGridReactProps>(null);
  const [columns, setColumns] = React.useState<string[]>([]);
  const [headers, setHeaders] = useState<Record<string, string>>();
  useClickOutside(ref, clean);
  const { userInfo } = useAuth()
  const canDelete = userInfo.user.accessRights.deleteDatasets;
  const [selectedMenuIndex, setSelectedMenuIndex] = React.useState<number>(0);

  React.useEffect(() => {
    if (!columns.length) {
      load((menu === undefined ? undefined : menu[0].args))
    }
  }, [callback, clean, columns, show, translate]);

  const load = (args:any) => {
    callback(args)
      .then(data => {
        if (data && data.length > 0) {
          const row = data[0];
          const headers = {};
          Object.keys(row).forEach(value => {
            const key = value.split(' ').join('').split('.').join('_');
            Object.assign(headers, {[key]: value});
          });
          setColumns(Object.keys(headers));
          setHeaders(headers);

          GridRef.current?.api?.setRowData(data.map(value => {
            const rowData = {};
            for (const [k, v] of Object.entries(value)) {
              const key = k.split(' ').join('').split('.').join('_');
              Object.assign(rowData, {[key]: v})
            }
            return rowData;
          }));
          GridRef.current?.columnApi?.autoSizeAllColumns();
        }
      })
      .catch(() => {
        show(translate(Translation.analysis.validation.failedToPreview));
        clean(TIMERs.threeSeconds, () => GridRef.current?.api?.setRowData([]));
      });
  }

  const triggerCallbatWith = (args:any) => {
    setSelectedMenuIndex(args.offset)
    GridRef.current?.api?.setRowData([]);
    load(args)
  }

  if (!columns.length) {
    return <Loader />;
  }

  return (
    <Container ref={ref}>
      <CloseIcon size={25} onClose={() => clean()} className='display' />
      <section style={{ display: 'flex', height: '100%' }}>
        { menu !== undefined ? (
          <section className="explore-menu">
            {menu.map((mi:any, index:number) => (
              <div className={`dataset-file ${selectedMenuIndex == mi.args.offset ? 'active' : ''}`} key={index}>
                <button onClick={() => triggerCallbatWith(mi.args)}>{mi.label}</button>
                <TrashIcon
                  color={Colors.white}
                  style={{ ...(canDelete ? okStyle : forbiddenStyle), height: 20 }}
                  onClick={() => {
                    if (canDelete)
                      show(
                        <DeletePopupComponent
                          close={clean}
                          text={translate(Translation.inputFiles.table.deleteFileMsg, [mi.label])}
                          yesCbk={() => {
                            if(deleteCallback === undefined) {
                              clean()
                              return
                            }
                            deleteCallback(mi.args)
                              .then(() => {
                                clean();
                              })
                              .catch(() => {
                                show(translate(Translation.inputFiles.table.deleteErrorMsg, [mi.label]));
                                clean(TIMERs.threeSeconds);
                              })
                          }}
                        />
                      );
                  }}
                />
              </div>
            ))}
          </section>
        ) : (<></>)}
        <section className='ag-theme-alpine ag-grid table' style={{ flexGrow: 1 }}>
          <AgGridReact ref={GridRef as any} >
            {columns.map(col => (
              <AgGridColumn field={col} key={col} headerName={headers ? headers[col] : col} {...commonProps} />
            ))}
          </AgGridReact>
        </section>
      </section>
    </Container>
  );
};
