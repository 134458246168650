import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../../providers';
import { AccessRights } from '../../services/types/authentication';
import { Routes } from '../../static';
import { getTitle } from '../../utils';

type Key = keyof AccessRights;

type SecuredRouteProps = {
  name: string;
  children: React.ReactNode;
  path: string | string[];
  dynamicRights?: Key[];
};

export const SecuredRoute = ({ children, dynamicRights, name, ...rest }: SecuredRouteProps) => {
  const { userInfo } = useAuth();

  const accessRights = userInfo.user?.accessRights ?? {};
  const hasAccessRight = !dynamicRights?.length || dynamicRights.some(key => accessRights[key]);
  const hasAccess = hasAccessRight;

  document.title = getTitle(name);

  return (
    <Route {...rest} render={() => (hasAccess ? children : <Redirect to={Routes.Overview} />)} />
  );
};
