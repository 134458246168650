import * as React from 'react';
import { DEFAULT_LANG, TranslationService } from '../services/translation';

interface State {
  isReady: boolean;
  language: string;
}

interface ApplicationState {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  translate: (key: string, params?: any[]) => string;
}

const TranslateContext = React.createContext({} as ApplicationState);

type AppProviderProps = {
  children: React.ReactNode;
};

const TranslationProvider = ({ children }: AppProviderProps) => {
  const [state, setState] = React.useState<State>({
    isReady: false,
    language: DEFAULT_LANG,
  });
  const stateRef = React.useRef(setState);

  const applicationState: ApplicationState = {
    translate: (key: string, params?: any[]) =>
      TranslationService.translate(key, params ?? [], state.language),
    setState: stateRef.current,
    state,
  };

  return <TranslateContext.Provider value={applicationState}>{children}</TranslateContext.Provider>;
};

const useTranslation = () => React.useContext(TranslateContext);

export { useTranslation, TranslationProvider };
