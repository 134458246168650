export const AddFileIcon = ({ width = '40' }: { width?: string }) => (
  <svg width={width} viewBox='0 0 256 256' style={{ pointerEvents: 'none' }}>
    <g transform='translate(0,256) scale(0.1,-0.1)'>
      <path
        d='M330 2551 c-79 -24 -149 -82 -185 -150 -33 -64 -36 -158 -33 -991 l3
    -825 26 -55 c32 -69 79 -116 144 -146 50 -24 53 -24 421 -24 l372 0 48 -66
    c91 -124 248 -230 404 -271 100 -27 284 -24 380 6 249 77 429 256 505 501 25
    82 31 267 11 360 -52 243 -242 455 -482 536 l-84 29 0 250 0 251 -255 270
    c-141 148 -271 284 -290 302 l-35 32 -462 -1 c-255 0 -474 -4 -488 -8z m882
    -303 c3 -223 6 -234 69 -296 62 -60 86 -66 287 -70 l182 -4 0 -203 0 -202 -89
    -7 c-123 -10 -255 -57 -364 -129 l-85 -57 -346 0 -346 0 -16 -25 c-15 -23 -15
    -27 0 -50 l16 -25 300 0 c165 0 300 -3 300 -6 0 -3 -8 -18 -19 -32 -10 -15
    -30 -51 -45 -79 l-26 -53 -255 0 -255 0 -16 -25 c-15 -23 -15 -27 0 -50 l16
    -25 234 0 233 0 -5 -133 c-4 -113 -2 -144 17 -216 11 -47 21 -88 21 -93 0 -12
    -618 -10 -662 2 -50 14 -97 55 -119 105 -18 38 -19 86 -19 875 0 544 4 848 10
    872 13 47 49 90 92 111 29 14 87 17 460 17 l427 0 3 -202z m448 -240 c10 -17
    2 -18 -115 -18 -137 0 -166 8 -204 56 -19 24 -21 40 -21 173 l1 146 164 -170
    c91 -93 170 -178 175 -187z m196 -668 c123 -31 198 -74 290 -165 132 -132 187
    -265 186 -450 -1 -240 -132 -447 -346 -550 -107 -51 -167 -65 -279 -65 -209 1
    -400 102 -516 275 -73 109 -103 211 -104 345 0 120 15 185 70 294 42 84 159
    206 243 253 52 30 156 67 210 76 67 11 173 5 246 -13z'
      />
      <path
        d='M505 1526 c-20 -30 -7 -62 31 -76 29 -11 526 -12 564 -1 36 10 49 54
    25 81 -17 19 -29 20 -311 20 l-294 0 -15 -24z'
      />
      <path
        d='M1672 987 c-6 -7 -13 -56 -14 -108 l-3 -94 -94 -3 c-52 -1 -101 -8
    -108 -14 -17 -14 -17 -62 0 -76 7 -6 56 -13 108 -14 l94 -3 5 -98 c5 -99 13
    -117 50 -117 37 0 45 18 50 117 l5 98 98 5 c99 5 117 13 117 50 0 37 -18 45
    -117 50 l-98 5 -3 94 c-1 52 -8 101 -14 108 -6 7 -23 13 -38 13 -15 0 -32 -6
    -38 -13z'
      />
    </g>
  </svg>
);
