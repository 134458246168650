import { AgGridReactProps } from 'ag-grid-react';
import styled from 'styled-components';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { useAuth, useTranslation } from '../../../providers';
import { deleteReport, deleteResult } from '../../../services/results';
import { Result } from '../../../services/types/results';
import { Colors, TIMERs, Translation } from '../../../static';
import { DeletePopupComponent } from '../DeletePopup';
import { TrashIcon } from '../icons';

const DataCellRendererContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

export const ManageResultCellRenderer = ({ data, api }: { data: Result } & AgGridReactProps) => {
  const { translate } = useTranslation();
  const { show, clean } = useCleanPopup();
  const { userInfo } = useAuth();
  const canDelete = userInfo.user.accessRights.deleteResults;
  const style = canDelete ? {} : { opacity: 0.5, cursor: 'not-allowed' };

  const onClickHandler = () => {
    if (canDelete)
      show(
        <DeletePopupComponent
          close={clean}
          text={translate(Translation.inputFiles.table.deleteFileMsg, [data.name.replace('.csv','')])}
          yesCbk={async () => {
            try {
              await (data.isReport ? deleteReport(data._id) : deleteResult(data._id));
              api?.applyTransaction({ remove: [data] });
              clean();
            } catch {
              show(translate(Translation.results.failedToDelete, [data.name]));
              clean(TIMERs.threeSeconds);
            }
          }}
        />
      );
  };

  return (
    <DataCellRendererContainer className='delete-action'>
      <TrashIcon color={Colors.orange} onClick={onClickHandler} style={{ ...style, height: 20 }} />
    </DataCellRendererContainer>
  );
};
