import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { useTranslation } from '../../../providers';
import { getReport } from '../../../services/results';
import { Colors, dropShadow, TIMERs, Translation } from '../../../static';
import { useResults } from '../results-context';

const Container = styled.section`
  width: 100%;
  height: 100%;

  .report-style-class {
    height: 100%;
    width: 100%;
    border: 0;

    iframe {
      border: 1px;
      background-color: ${Colors.white};
      border-radius: 10px;
      filter: ${dropShadow};
    }
  }
`;

export const PowerBI = () => {
  const { result } = useResults();
  const { show, clean } = useCleanPopup();
  const { translate } = useTranslation();
  const [sampleReportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    embedUrl: undefined,
    accessToken: undefined,
    type: 'report',
    tokenType: models.TokenType.Embed,
    settings: {
      background: models.BackgroundType.Transparent,
    },
  });

  useEffect(() => {
    getReport(result?._id)
      .then(({ embedToken, embedUrl }) => {
        setReportConfig(report => ({
          ...report,
          embedUrl,
          accessToken: embedToken,
        }));
      })
      .catch(() => {
        show(translate(Translation.results.failedToGetReports));
        clean(TIMERs.threeSeconds);
      });
  }, [clean, result?._id, show, translate]);

  return (
    <Container>
      <PowerBIEmbed embedConfig={sampleReportConfig} cssClassName={'report-style-class'} />
    </Container>
  );
};
