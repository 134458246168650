import { AgGridReactProps } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { useAuth, useTranslation } from '../../../providers';
import { useServices } from '../../../providers/services-context';
import { deleteDataset } from '../../../services/files';
import { uploadFile, updateDataset } from '../../../services/analysis';
import { AnalysisDirectionMode, Dataset, Status } from '../../../services/types/analysis';
import { Service } from '../../../services/types/settings';
import { Colors, Routes, TIMERs, Translation, CSVExtension, FileExtensions } from '../../../static';
import { useFileDispatch } from '../../files/file-store';
import { DeletePopupComponent } from '../DeletePopup';
import { TrashIcon, EditFileIcon } from '../icons';
import { EditIcon } from '../icons/EditIcon';
import { DownloadRenderer } from './download-renderer';
import { EyeCellRenderer } from './eye-renderer';
import { DataCellRendererContainer } from './styled';
import { getStateFromLastService, datasetHasFiles } from '../../../utils';
import * as React from "react";
import {DraggableInput} from '../DraggableInput';

const forbiddenStyle = { cursor: 'not-allowed', opacity: 0.5 };
const okStyle = { cursor: 'pointer' };

export const ManageDatasetCellRenderer = ({ data, api }: { data: Dataset } & AgGridReactProps) => {
  const { translate } = useTranslation();
  const { services, activeServices } = useServices();
  const history = useHistory();
  const dispatch = useFileDispatch();
  const { show, clean } = useCleanPopup();
  const { userInfo } = useAuth();
  const canDelete = userInfo.user.accessRights.deleteDatasets;
  const canUpdate = userInfo.user.accessRights.updateDatasets;
  const { table } = Translation.inputFiles;
  const translations: Record<string, string> = React.useMemo(
      () => ({
        [Status.Analyzed]: translate(table.analyzed),
        [Status.ToValidate]: translate(table.toValidate),
        [Status.Validated]: translate(table.validated),
      }),
      [translate]
  );
  const newAnalysis = activeServices?.length && getEnableBasedOnStatus(activeServices, translations, data);
  const canStartNewAnalysis = newAnalysis && userInfo.user.accessRights.newAnalysis;

  const onChange = (payload: { file: any; fileName?: string }) => {
    api?.showLoadingOverlay();
    uploadFile(payload, userInfo.user.client._id, data._id)
      .then(dataset => {
        updateRow(dataset)
      })
      .catch(() => {
        show(translate(Translation.analysis.upload.failedToUpload));
        clean(TIMERs.fiveSeconds);
        api?.hideOverlay();
      });
  };

  const onChangeFileName = (payload: { file: any; fileName?: string }) => {
    api?.showLoadingOverlay();
    updateDataset(payload, userInfo.user.client._id, data._id)
      .then(dataset => {
        updateRow(dataset)
      })
      .catch(() => {
        show(translate(Translation.analysis.update.failedToUpdate));
        clean(TIMERs.fiveSeconds);
        api?.hideOverlay();
      });
  };

  const updateRow = (dataset:Dataset) => {
    const { rowsToDisplay }: any = api?.getModel();
    const data = rowsToDisplay.map(({ data }: any) => {
      if(dataset._id == data._id) {
        return dataset
      }
      return data
    });
    dataset.statusForFilter = translations?.[dataset.status] ?? dataset.status;
    api?.hideOverlay();
    api?.setRowData([...data]);
  }

  return (
    <DataCellRendererContainer>
      <span
        data-testid='newAnalysis'
        style={canStartNewAnalysis ? okStyle : forbiddenStyle}
        onClick={() => {
          if (canStartNewAnalysis) {
            history.push(
              Routes.Analysis,
              getStateFromLastService(data, services, AnalysisDirectionMode.alreadyModified)
            );
          }
        }}>
        {translate(Translation.inputFiles.table.chooseAService)}
      </span>
      <EyeCellRenderer data={data} onUpdate={updateRow}/>
      {datasetHasFiles(data) && (
        <EditIcon
          size={20}
          color={Colors.orange}
          disabled={!canUpdate}
          onClick={() => {
            if (canUpdate)
              show(
                <DraggableInput
                  data={data} 
                  onClose={clean} 
                  accept={[...CSVExtension, FileExtensions.TXT]} 
                  onChange={onChangeFileName}
                />
              );
          }}
        />
      )}
      <TrashIcon
        color={Colors.orange}
        style={{ ...(canDelete ? okStyle : forbiddenStyle), height: 20 }}
        onClick={() => {
          if (canDelete)
            show(
              <DeletePopupComponent
                close={clean}
                text={translate(Translation.inputFiles.table.deleteFileMsg, [data.name])}
                yesCbk={() =>
                  deleteDataset(data._id)
                    .then(() => {
                      api?.applyTransaction({ remove: [data] });
                      const { rowsToDisplay }: any = api?.getModel();

                      dispatch({ type: 'setCounter', counter: rowsToDisplay.length });
                      clean();
                    })
                    .catch(() => {
                      show(translate(Translation.inputFiles.table.deleteErrorMsg, [data.name]));
                      clean(TIMERs.threeSeconds);
                    })
                }
              />
            );
        }}
      />
      {datasetHasFiles(data) && (
        <EditFileIcon
          size={20}
          color={Colors.orange}
          disabled={!canUpdate}
          strokeWidth={1}
          onClick={() => {
            if (canUpdate)
              show(
                <DraggableInput 
                  onClose={clean} 
                  accept={[...CSVExtension, FileExtensions.TXT]} 
                  onChange={onChange}
                />
              );
          }}
        />
      )}
      <DownloadRenderer data={data} />
    </DataCellRendererContainer>
  );
};

function getEnableBasedOnStatus(
  enabledServices: Service[],
  translations: Record<string, string>,
  { statusForFilter, service, validation }: Dataset
): boolean {
  const hasService = service != null && service.length > 0;
  const isTheServiceActivated = Boolean(enabledServices.find(({ _id }) => _id === service));

  if (statusForFilter === translations?.[Status.Analyzed] || statusForFilter === translations?.[Status.Validated]) {
    return hasService && isTheServiceActivated && Boolean(validation);
  }

  return false;
}
