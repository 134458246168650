import { Colors } from '../../../static';

export const CreditCardBgIcon = () => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none'>
    <rect x='3.92188' y='6' width='18' height='13' rx='2' stroke={Colors.menuColor} />
    <path d='M7.92188 15H7.93187' stroke={Colors.menuColor} strokeLinecap='round' />
    <path d='M4.92188 11H21.9219' stroke={Colors.menuColor} strokeLinecap='round' />
  </svg>
);

export const FileIcon = () => (
  <svg width='50' height='53' viewBox='0 0 50 53' fill='none'>
    <path
      d='M34.4365 6.06641H24.2109C23.6582 6.06641 23.2109 6.51367 23.2109 7.06641C23.2109 7.61914 23.6582 8.06641 24.2109 8.06641H34.4365C34.9893 8.06641 35.4365 7.61914 35.4365 7.06641C35.4365 6.51367 34.9893 6.06641 34.4365 6.06641Z'
      fill={Colors.blue}
    />
    <path
      d='M34.4355 13.3994H15.5645C15.0117 13.3994 14.5645 13.8467 14.5645 14.3994C14.5645 14.9521 15.0117 15.3994 15.5645 15.3994H34.4355C34.9883 15.3994 35.4355 14.9521 35.4355 14.3994C35.4355 13.8467 34.9883 13.3994 34.4355 13.3994Z'
      fill={Colors.blue}
    />
    <path
      d='M34.4355 20.7334H15.5645C15.0117 20.7334 14.5645 21.1807 14.5645 21.7334C14.5645 22.2861 15.0117 22.7334 15.5645 22.7334H34.4355C34.9883 22.7334 35.4355 22.2861 35.4355 21.7334C35.4355 21.1807 34.9883 20.7334 34.4355 20.7334Z'
      fill={Colors.blue}
    />
    <path
      d='M34.4355 28.0664H15.5645C15.0117 28.0664 14.5645 28.5137 14.5645 29.0664C14.5645 29.6191 15.0117 30.0664 15.5645 30.0664H34.4355C34.9883 30.0664 35.4355 29.6191 35.4355 29.0664C35.4355 28.5137 34.9883 28.0664 34.4355 28.0664Z'
      fill={Colors.blue}
    />
    <path
      d='M49 44.0752H30.8386C30.4167 41.6069 28.4683 39.6584 26 39.2366V35.1377H37.9561C38.5088 35.1377 38.9561 34.6904 38.9561 34.1377V1.99512C38.9561 1.44238 38.5088 0.995117 37.9561 0.995117H20.9688C20.9366 0.995117 20.9096 1.0105 20.8783 1.01343C20.8077 1.02002 20.7401 1.03369 20.6724 1.05493C20.6245 1.07007 20.5781 1.08252 20.5336 1.10449C20.4643 1.13818 20.402 1.18433 20.3411 1.23413C20.3112 1.25879 20.2772 1.27637 20.2504 1.3042L11.3382 10.2175C11.3359 10.2197 11.3346 10.2227 11.3323 10.2249C11.2508 10.3076 11.1907 10.4038 11.1433 10.5054C11.1291 10.5359 11.1209 10.5676 11.1097 10.5996C11.0795 10.686 11.0618 10.7739 11.0562 10.8652C11.0548 10.8862 11.0439 10.9043 11.0439 10.9258V34.1377C11.0439 34.6904 11.4912 35.1377 12.0439 35.1377H24V39.2366C21.5317 39.6584 19.5833 41.6069 19.1614 44.0752H1C0.447266 44.0752 0 44.5225 0 45.0752C0 45.6279 0.447266 46.0752 1 46.0752H19.1614C19.639 48.8691 22.0719 51.0049 25 51.0049C27.9281 51.0049 30.361 48.8691 30.8386 46.0752H49C49.5527 46.0752 50 45.6279 50 45.0752C50 44.5225 49.5527 44.0752 49 44.0752ZM19.9688 4.41406V9.92578H14.458L19.9688 4.41406ZM13.0439 33.1377V11.9258H20.9688C21.5215 11.9258 21.9688 11.4785 21.9688 10.9258V2.99512H36.9561V33.1377H13.0439ZM25 49.0049C22.833 49.0049 21.0703 47.2422 21.0703 45.0752C21.0703 42.9082 22.833 41.1455 25 41.1455C27.167 41.1455 28.9297 42.9082 28.9297 45.0752C28.9297 47.2422 27.167 49.0049 25 49.0049Z'
      fill={Colors.blue}
    />
    <circle cx='24.9995' cy='44.9995' r='7.07371' fill='#BDFF00' />
    <path
      d='M21.7461 45.0004L22.7214 45.9757C23.4455 46.6998 23.8075 47.0619 24.2481 47.0419C24.6887 47.0219 25.0165 46.6286 25.672 45.8419L28.4766 42.4764'
      stroke='#59940F'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);

export const SearchIcon = ({ size = '12', revert = false }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 14 14'
    fill='none'
    style={revert ? { transform: 'rotateY(190deg)' } : {}}
  >
    <circle cx='5.48618' cy='5.40513' r='3.56431' stroke='#FF764D' strokeWidth='2' />
    <path
      d='M10.8063 10.7257L8.54102 8.46045'
      stroke='#FF764D'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);

export const SearchIconMain = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
    <path d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z' />
  </svg>
);
