import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../static';
import { Move } from './animations';
import { Circle, CrossIcon } from './icons';

type ItemProps = {
  text: string;
  beforeIcon?: React.ReactNode;
  afterIcon?: React.ReactNode;
  isAnalysis?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  counter?: number;
  noAnimation?: boolean;
  height?: number;
  iconWidth?: number;
};

type ContainerProps = {
  height: number;
  iconWidth?: number;
};

const Container = styled.section`
  cursor: pointer;
  height: ${({ height }: ContainerProps) => height}px;
  align-items: center;
  display: grid;
  grid-template-columns: ${({ iconWidth = 25 }: ContainerProps) => {
    const totalFirstWidth = 75;
    const px = totalFirstWidth - iconWidth;

    return `${iconWidth}% ${px}% 1fr`;
  }};
`;

const CounterContainer = styled.span`
  position: absolute;
  margin: -5px 0 0 -10px;
`;

const Icon = styled.span`
  justify-self: center;
`;

export const ItemLink = React.forwardRef(
  ({ height = 65, iconWidth, ...prop }: ItemProps, ref: any) => {
    const [circleSize, setCircleSize] = React.useState(33);

    const container = (
      <Container
        ref={ref}
        className={prop.className}
        onClick={prop.onClick}
        iconWidth={iconWidth}
        height={height}
      >
        <Icon>
          {prop.beforeIcon}
          {prop.counter ? (
            <CounterContainer>
              <Circle
                size={14}
                bgColor={Colors.analysisCircleBg}
                color={Colors.black}
                fontSize={10}
                children={prop.counter}
              />
            </CounterContainer>
          ) : null}
        </Icon>

        <span>{prop.text}</span>

        {prop.isAnalysis ? (
          <Icon
            style={{ width: '90%' }}
            onMouseLeave={() => setCircleSize(33)}
            onMouseEnter={() => setCircleSize(40)}
          >
            <Circle size={circleSize} bgColor={Colors.analysisCircleBg} children={<CrossIcon />} />
          </Icon>
        ) : (
          <Icon>{prop.afterIcon}</Icon>
        )}
      </Container>
    );

    return prop.noAnimation ? (
      container
    ) : (
      <Move direction='right' transition='0.4s' px={20} ref={ref}>
        {container}
      </Move>
    );
  }
);
