import styled from 'styled-components';
import { Colors, dropShadow } from '../../../static';

export const Row = styled.section`
  padding: 10px 0;
  display: flex;
`;

export const ResultsSummaryHeader = styled.section`
  padding: 20px 30px;
  height: 200px;
  background-color: ${Colors.white};
  filter: ${dropShadow};
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .title {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .download {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .table {
    display: grid;
    grid-template-columns: 90px repeat(3, minmax(0, 1fr));
    column-gap: 20px;
    align-items: center;

    .col {
      height: 100%;
      font-size: 13px;
      display: flex;
      flex-direction: column;

      .view {
        cursor: pointer;
        text-decoration: underline;
      }

      label {
        font-weight: 600;
      }
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

    .item-dataset-name {
      font-weight: 600;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;

      .overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .item-download {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 60px;
    }
  }
`;

export const NoData = styled.section`
  width: 100%;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.menuColor};
  font-size: 14px;
`;
