import React, {ReactNode} from 'react';
import {useTooltipContext} from "./tooltip-context";
import styled, {css, ThemeProps} from "styled-components";
import {Colors} from "../static";

const TooltipWrapper = styled.div`${(props: ThemeProps<any> & { xPos: number, yPos: number, maxWidth: number }) => {
  return css`
    display: block;
    position: absolute;
    padding: 5px;
    color: ${Colors.menuColor};
    font-size: 15px;
    background-color: ${Colors.bgColorTransactions};
    border-radius: 7px;
    font-weight: normal;
    inset: 0 auto auto 0;
    translate: ${props.xPos}px ${props.yPos}px;
    transform-origin: center;
    transform: translate(-48%);
    border: .5px solid rgb(189, 189, 189);
    z-index: 999;
    max-width: ${props.maxWidth}px;
    word-wrap: break-word;
  `
}}`

type Props = {
  children: ReactNode
};
export const Tooltip = ({children}: Props) => {
  const {show, msg, xPos, yPos, maxWidth} = useTooltipContext()
  return (
      <>
        {children}
        {show && <TooltipWrapper xPos={xPos} yPos={yPos} maxWidth={maxWidth}>{msg}</TooltipWrapper>}
      </>
  );
};
