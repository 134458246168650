import * as React from 'react';
import styled from 'styled-components';
import { OnlineOptionType } from '../../../services/types/settings';
import { FileHeaderComponent } from '../AnalysisFileHeader';
import { IdentificationOfColumns } from './IdentificationOfColumns';
import { OrganizationOfFiles } from './OrganizationOfFiles';
import { ValidationProvider } from './validation-context';

const Container = styled.section`
  height: 100%;
  padding: 20px;
`;

export const AnalysisValidationComponent = () => {
  const [showFirstValidation, setShowFirstValidation] = React.useState(true);

  return (
      <Container className='validation'>
          <ValidationProvider>
              <FileHeaderComponent
                  type={showFirstValidation ? OnlineOptionType.FileFormat : OnlineOptionType.ListOfFields}
              />

              {showFirstValidation ? (
                  <OrganizationOfFiles goToSecondPage={() => setShowFirstValidation(false)}/>
              ) : (
                  <IdentificationOfColumns goToFirstPage={() => setShowFirstValidation(true)}/>
              )}
          </ValidationProvider>
      </Container>
  );
};
