import styled from 'styled-components';
import { Button, ShortUpRightArrow } from '.';
import { useTranslation } from '../../providers';
import { Colors, Translation } from '../../static';

const btnWidth = 180;
const shortArrowStyle = { paddingLeft: 10, marginTop: 5 };

type RawRenderProps = {
  onClick: () => void | Promise<void>;
  disabled?: boolean;
  text?: string;
  className?: string;
  width?: number;
};

const Preview = styled(Button)`
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  background-color: ${Colors.white};
  color: ${Colors.menuColor};
  border: 2px solid ${Colors.menuColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
`;

export const PreviewButton = (props: RawRenderProps) => {
  const { onClick, className, text, width = btnWidth, disabled } = props;
  const { translate } = useTranslation();

  return (
    <Preview
      className={className}
      onClick={() => onClick()}
      style={{ width }}
      disabled={disabled}
      data-testid='previewBtn'
    >
      <span>{text ?? translate(Translation.analysis.validation.previewFile)}</span>
      <ShortUpRightArrow style={shortArrowStyle} />
    </Preview>
  );
};
