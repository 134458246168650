import * as React from 'react';
import { useHistory } from 'react-router';
import { useAuth, useTranslation } from '../../providers';
import { getLast10Results, getResultsWithService } from '../../services/results';
import { Result } from '../../services/types/results';
import { ClientService } from '../../services/types/settings';
import { Routes, Translation } from '../../static';
import { BigTile } from '../common';
import { ScrollHeaderWithResults } from './ScrollHeader';
import './style.scss';

const overviewResStyle = { height: 340 };
const boxWidth = 270;

export const OverviewResults = ({ services }: { services: ClientService[] }) => {
  const history = useHistory();
  const { userInfo, isManager, isAdmin } = useAuth();
  const { _id: clientId } = userInfo?.user?.client ?? {};
  const { translate } = useTranslation();
  const tilesRef = React.useRef<HTMLElement>(null);
  const [results, setResults] = React.useState<Result[]>([]);
  const text = translate(Translation.overview.viewResults);
  const resultsWithService = React.useMemo(
    () => getResultsWithService(services, results),
    [services, results]
  );

  React.useEffect(() => {
    const payload = isManager || isAdmin ? { clientId } : { userId: userInfo?.user?._id };

    getLast10Results(payload)
      .then(results => setResults(results))
      .catch(() => console.error('[Forbidden]'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <section className='bSection' style={overviewResStyle}>
      <ScrollHeaderWithResults
        tilesRef={tilesRef}
        length={results.length}
        boxWidth={boxWidth}
        header={translate(Translation.overview.filesSectionHeader)}
      />

      <section ref={tilesRef} className='bgResContainer'>
        {resultsWithService?.length ? (
          resultsWithService.map((result, index) => (
            <BigTile
              result={result}
              text={text}
              key={index}
              viewResultsHandler={() => {
                if (!services) return;
                const [service] = services.filter(({ _id }) => _id === result.serviceId);

                history.push(Routes.Results, {
                  steps: [{ isActive: false }, { isActive: true }],
                  service,
                  result,
                });
              }}
            />
          ))
        ) : (
          <section className='empty'>{translate(Translation.overview.noRecentResults)}</section>
        )}
      </section>
    </section>
  );
};
