import styled from 'styled-components';
import { Colors } from '../../static';

export const Button = styled.button`
  border-radius: 25px;
  background-color: ${({ color = Colors.blue }: { color?: string }) => color};
  color: ${Colors.white};
  height: 52px;
  border: 0;
  cursor: pointer;
  opacity: ${({ disabled = false }: { disabled?: boolean }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled = false }: { disabled?: boolean }) =>
    disabled ? 'not-allowed' : 'pointer'};
`;
