import { ActivityType } from '../components/activities/utils';
import { Endpoint } from '../static';
import { AuthFetch } from './request';

export interface Activity {
  type: ActivityType;
  updatedAt: Date | string;
  text: string;
}
export const getActivities = async (limit = 7): Promise<Activity[]> => {
  return AuthFetch.GET<Activity[]>(`${Endpoint.getActivities}/${limit}`);
};

export const exportActivities = async ({
  clientId,
  userId,
}: {
  clientId?: string;
  userId?: string;
}): Promise<any> => {
  const req = userId != null ? `userId=${userId}` : `clientId=${clientId}`;

  return AuthFetch.GET<any>(`${Endpoint.getActivitiesCSV}?${req}`, 'text');
};
