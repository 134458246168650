import { Colors } from '../../../static';

export const NewAnalysisIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path d='M15 15V8H19V12.2' stroke='#F5F6F8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M1 10V20H5V10H1Z' stroke='#F5F6F8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8 1V20H12V1H8Z' stroke='#F5F6F8' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M21.5 17C21.5 17.6932 21.2185 18.3197 20.7624 18.7731C20.3099 19.2229 19.6878 19.5 19 19.5C17.6193 19.5 16.5 18.3807 16.5 17C16.5 15.6193 17.6193 14.5 19 14.5C20.3807 14.5 21.5 15.6193 21.5 17Z'
      stroke='#F5F6F8'
    />
    <path d='M23 21L21 19' stroke='#F5F6F8' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

type CrossIconProps = {
  color?: string;
};

export const CrossIcon = ({ color = Colors.menuColor }: CrossIconProps) => (
  <svg width='33' height='33' viewBox='0 0 33 33'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.8121 12.6924C17.8121 12.1401 17.3644 11.6924 16.8121 11.6924C16.2599 11.6924 15.8121 12.1401 15.8121 12.6924V15.9992H12.6914C12.1391 15.9992 11.6914 16.4469 11.6914 16.9992C11.6914 17.5515 12.1391 17.9992 12.6914 17.9992H15.8121V21.3062C15.8121 21.8585 16.2599 22.3062 16.8121 22.3062C17.3644 22.3062 17.8121 21.8585 17.8121 21.3062V17.9992H20.932C21.4843 17.9992 21.932 17.5515 21.932 16.9992C21.932 16.4469 21.4843 15.9992 20.932 15.9992H17.8121V12.6924Z'
      fill={color}
    />
  </svg>
);

export const MinusIcon = ({ color = Colors.menuColor }: CrossIconProps) => (
  <svg width='15' height='12' viewBox='0 0 124 124'>
    <path
      d='M112,50H12C5.4,50,0,55.4,0,62c0,6.6,5.4,12,12,12h100c6.6,0,12-5.4,12-12C124,55.4,118.6,50,112,50z'
      fill={color}
    />
  </svg>
);

export const OverviewIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path d='M8 10L8 16' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12 12V16' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16 8V16' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <rect x='3' y='4' width='18' height='16' rx='2' stroke='white' />
  </svg>
);

export const InputDataSetsIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M13.1716 3H9C7.11438 3 6.17157 3 5.58579 3.58579C5 4.17157 5 5.11438 5 7V17C5 18.8856 5 19.8284 5.58579 20.4142C6.17157 21 7.11438 21 9 21H15C16.8856 21 17.8284 21 18.4142 20.4142C19 19.8284 19 18.8856 19 17V8.82843C19 8.41968 19 8.2153 18.9239 8.03153C18.8478 7.84776 18.7032 7.70324 18.4142 7.41421L14.5858 3.58579C14.2968 3.29676 14.1522 3.15224 13.9685 3.07612C13.7847 3 13.5803 3 13.1716 3Z'
      stroke='white'
    />
    <path
      d='M13 3V7C13 7.94281 13 8.41421 13.2929 8.70711C13.5858 9 14.0572 9 15 9H19'
      stroke='white'
    />
  </svg>
);

export const ResultsIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.88281 17.3106C7.17959 17.7429 7.52228 18.1449 7.90653 18.5091C8.95858 19.5063 10.2771 20.1772 11.7024 20.4407C13.1278 20.7042 14.599 20.549 15.938 19.9939C17.2771 19.4388 18.4265 18.5076 19.2474 17.3129C20.0683 16.1182 20.5253 14.7112 20.5633 13.2622C20.6013 11.8132 20.2186 10.3842 19.4614 9.14812C18.7043 7.91206 17.6052 6.92189 16.297 6.2974C15.4619 5.8987 14.5644 5.65996 13.6506 5.5885L13.9278 6.62307C14.5966 6.71256 15.251 6.90611 15.8662 7.19984C16.9999 7.74106 17.9525 8.59921 18.6087 9.67046C19.2649 10.7417 19.5966 11.9802 19.5637 13.236C19.5307 14.4918 19.1346 15.7112 18.4232 16.7466C17.7118 17.782 16.7156 18.5891 15.5551 19.0702C14.3946 19.5513 13.1195 19.6857 11.8842 19.4574C10.6489 19.229 9.50623 18.6475 8.59445 17.7833C8.34863 17.5503 8.12243 17.2995 7.91738 17.0334L6.88281 17.3106Z'
      fill='white'
    />
    <path
      d='M6.97879 5.13177C7.75564 4.53568 8.61437 4.05802 9.52682 3.7127C9.94618 3.55398 10.1559 3.47463 10.3533 3.57584C10.5508 3.67705 10.6129 3.90869 10.737 4.37198L12.8076 12.0994C12.9296 12.5547 12.9906 12.7824 12.887 12.9618C12.7835 13.1411 12.5558 13.2021 12.1005 13.3241L4.37307 15.3947C3.90979 15.5188 3.67815 15.5809 3.49176 15.4605C3.30536 15.34 3.26925 15.1188 3.19701 14.6762C3.03985 13.7134 3.02415 12.7309 3.15196 11.76C3.32337 10.4581 3.74954 9.20259 4.40615 8.06531C5.06276 6.92803 5.93695 5.93121 6.97879 5.13177Z'
      stroke='white'
    />
  </svg>
);

type SettingsProps = {
  active: boolean;
};

export const SettingsIcon = ({ active }: SettingsProps) => {
  const color = active ? Colors.black : Colors.white;

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path d='M5 12L5 4' stroke={color} strokeLinecap='round' />
      <path d='M19 20L19 17' stroke={color} strokeLinecap='round' />
      <path d='M5 20L5 16' stroke={color} strokeLinecap='round' />
      <path d='M19 13L19 4' stroke={color} strokeLinecap='round' />
      <path d='M12 7L12 4' stroke={color} strokeLinecap='round' />
      <path d='M12 20L12 11' stroke={color} strokeLinecap='round' />
      <circle cx='5' cy='14' r='2' stroke={color} strokeLinecap='round' />
      <circle cx='12' cy='9' r='2' stroke={color} strokeLinecap='round' />
      <circle cx='19' cy='15' r='2' stroke={color} strokeLinecap='round' />
    </svg>
  );
};

export const MenuLogo = () => (
  <section style={{ width: '80%' }}>
    <img srcSet='/reveal_logo.png' alt='Reveal logo' height='100%' width='100%' />
  </section>
);
