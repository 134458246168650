import * as React from 'react';
import { useAuth, useTranslation } from '../../../providers';
import { getFinalData } from '../../../services/results';
import {
  AnalysisResults,
  DataAnalyzed,
  DuplicatePaymentsKpiData,
  KPIData,
} from '../../../services/types/results';
import { Translation } from '../../../static';
import { RangeField } from '../../common/RangeField';
import { KPIContainer } from '../styled';
import { BarChart } from './BarChart';
import { TileKPI } from './TileKPI';

type Props = KPIData & { data: DuplicatePaymentsKpiData[] };
type KPIType = { kpiData: Props };
const { results } = Translation;

export const DataAnalysedComponent = ({ kpiData }: KPIType) => {
  const { currency } = useAuth().userInfo.user.client;
  const { translate } = useTranslation();
  const [innerState, setInnerState] = React.useState(kpiData);

  React.useEffect(() => {
    setInnerState(kpiData);
  }, [kpiData]);

  return (
    <KPIContainer>
      <section className='sectionHeader'> {translate(results.dataAnalyzed)}</section>
      <section style={{ padding: '0 0 10px 0' }}>
        <RangeField
          data={innerState.rawData.map(({ year }) => year)}
          label={translate(Translation.results.rangeOfYears)}
          min={0}
          max={innerState.rawData.length}
          callback={(min, max) => {
            const { rawData } = innerState;
            const {
              rawData: data = [],
              analysisResults = {} as AnalysisResults,
              dataAnalyzed = {} as DataAnalyzed,
            } = getFinalData(rawData.slice(min, max)) ?? {};

            setInnerState({ rawData, data, analysisResults, dataAnalyzed });
          }}
          style={{ width: 300 }}
        />
      </section>

      <section className='container'>
        <TileKPI
          value={innerState.dataAnalyzed.transactions}
          title={translate(results.dataAnalyzedTransactions)}
        />
        <TileKPI
          value={innerState.dataAnalyzed.totalAmount}
          title={translate(results.dataAnalyzedTotalAmount)}
          currency={currency}
        />
        <TileKPI value={innerState.dataAnalyzed.vendors} title={translate(results.vendors)} />
      </section>

      <BarChart
        data={innerState.data}
        input='inputAmounts'
        output='inputTransactions'
        inputMessage={translate(results.inputAmount)}
        outputMessage={translate(results.dataAnalyzedTransactions)}
      />
    </KPIContainer>
  );
};
