import { Result } from '../../../services/types/results';
import { useResults } from '../results-context';
import { KPIComponent } from './KPI';
import { ResultSummaryHeader } from './ResultSummaryHeader';
import { LargeResultSummary } from './LargeResultSummary';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .view {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ResultSummary = () => {
  const { service, result } = useResults();
  if (!result || !service) return null;

  return !service.hasDashboardOnResults ? (
    <Container>
      <LargeResultSummary
        style={{ width: '80%' }}
        isOutSideApp={false}
        result={result}
        service={service}
      />
    </Container>
  ) : (
    <>
      <ResultSummaryHeader result={result as Result} service={service} />
      <KPIComponent />
    </>
  );
};
