import { useTranslation } from '../../providers';
import { Colors, Translation } from '../../static';
import { Header, LongRightArrow, MainContainer } from '../common';
import { ResultSummary } from './summary';
import { PowerBI } from './summary/PowerBI';
import { useResults, useResultsDispatch } from './results-context';
import { ResultsTable } from './ResultsTable';

const mainConatinerStyle = { padding: '20px 1% 20px 60px', width: '93%' };
const resultStyle = { display: 'flex', paddingTop: 20, cursor: 'pointer' };

export const ResultsPerSericeComponent = () => {
  const { translate } = useTranslation();
  const dispatch = useResultsDispatch();
  const { steps, service, result } = useResults();

  const onClickHandler = () => {
    if (result) {
      dispatch({ type: 'setResult', result: null });
    } else {
      const [FirstStep, SecondStep] = steps;

      dispatch({
        type: 'setSteps',
        steps: [{ ...FirstStep, isActive: true }, { ...SecondStep }],
      });
    }
  };

  return (
    <>
      <Header
        title={service?.name ?? ''}
        subtitle={translate(Translation.analysis.summary.results)}
      >
        <section style={resultStyle} onClick={onClickHandler}>
          <LongRightArrow color={Colors.white} />
          <span style={{ paddingLeft: 20 }}>{translate(Translation.results.return)}</span>
        </section>
      </Header>

      <MainContainer style={mainConatinerStyle} scrollY>
        {result ? service?.isReport ? <PowerBI /> : <ResultSummary /> : <ResultsTable />}
      </MainContainer>
    </>
  );
};
