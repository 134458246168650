import React from 'react';
import styled from 'styled-components';
import { Colors, dropShadow } from '../../static';
import { Move } from './animations';
import { RectanglePhoto } from './icons';

type Size = 'small' | 'large' | 'xLarge';

const sizes = {
  small: 144,
  large: 220,
  xLarge: 300,
};

const Container = styled.section`
  margin: 0 20px;
  height: ${({ size }: { size: Size }) => sizes[size]}px;
  width: ${({ size }: { size: Size }) => (size === 'large' ? sizes[size] + 50 : sizes[size])}px;
  background-color: ${Colors.white};
  border-radius: 15px;
  cursor: pointer;

  padding: 20px;
  filter: ${dropShadow};
  display: grid;
  grid-template-rows: 30% minmax(0, 1fr);
`;

const Description = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Text = styled.span`
  font-weight: 600;
`;

const Footer = styled.span`
  padding-top: 5px;
  font-size: 13px;
  color: ${Colors.tileTextColor};
`;

type TileProps = {
  photo: string;
  description: string;
  value?: number | string;
  size?: Size;
  className?: string;
  status?: string;
  onClick: () => void;
};

type SmallTileProps = TileProps & {
  enabled: boolean;
};

const getStyle = (enabled: boolean) => {
  return enabled
    ? {}
    : {
        cursor: 'not-allowed',
        opacity: 0.6,
        backgroundColor: Colors.disabled,
      };
};

export const SmallTile = ({
  photo,
  description,
  value,
  onClick,
  className,
  enabled,
}: SmallTileProps) => (
  <Move transition='0.4s' direction='top' px={20}>
    <Container
      style={getStyle(enabled)}
      onClick={() => {
        if (enabled) onClick();
      }}
      size='small'
      className={className}
    >
      <RectanglePhoto photo={undefined} size={50} url={photo ?? ''} />
      <Description>
        <Text>{description}</Text>
        <Footer>{value}</Footer>
      </Description>
    </Container>
  </Move>
);

export const LargeTile = ({ photo, description, value, onClick, className, status }: TileProps) => {
  const [active, setActive] = React.useState(false);
  const style = {
    backgroundColor: active ? Colors.orange : Colors.white,
    color: active ? Colors.white : Colors.menuColor,
  };

  return (
    <Container
      className={className}
      style={style}
      onClick={onClick}
      size='large'
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <RectanglePhoto photo={undefined} size={70} url={photo ?? ''} />

        <span style={{ ...style, fontSize: 15, fontWeight: 500, opacity: 0.8 }}>{status}</span>
      </section>
      <Description>
        <Text>{description}</Text>
        <Footer>{value}</Footer>
      </Description>
    </Container>
  );
};
