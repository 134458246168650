import { useDownload } from '../../../hooks/useDownload';
import { useAuth } from '../../../providers';
import { downloadDataset } from '../../../services/files';
import { Dataset } from '../../../services/types/analysis';
import { Colors } from '../../../static';
import { DownloadIcon } from '../icons';
import { datasetHasFiles } from '../../../utils';

export const DownloadRenderer = ({ data }: { data: Dataset }) => {
  const { accessRights } = useAuth().userInfo.user;

  let type = 'text/csv'
  let name = data.name
  if(datasetHasFiles(data)) {
    type = 'application/x-zip'
    name = name + '.zip'
  }

  const download = useDownload(
    setProgress => downloadDataset(data._id, data?.fileSize ? data?.fileSize : '0', setProgress),
    name,
    type
  );
  return (
    <>
      <DownloadIcon
        size={20}
        color={Colors.orange}
        disabled={accessRights.downloadResults === false}
        strokeWidth={1}
        onClick={() => download()}
      />
    </>
  );
};
