import { formatValue } from '../../../utils';
import { TileContainer } from '../styled';

type TileProps = {
  title: string;
  value: number;
  percentage?: number;
  currency?: string;
};

export const TileKPI = ({ title, value, percentage, currency }: TileProps) => (
  <TileContainer>
    <span className='title'>{title}</span>
    <section style={{ justifySelf: 'center' }}>
      {currency && !isNaN(value) ? <span className='currency'>{currency}</span> : null}
      <span className='value'>{isNaN(value) ? '—' : formatValue(value)}</span>
      <span className='percentage'>
        {percentage !== undefined ? (isNaN(percentage) ? '' : `(${formatValue(percentage)}%)`) : ''}
      </span>
    </section>
  </TileContainer>
);
