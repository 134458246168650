function deepFreeze<T>(object: T): T {
  Object.freeze(object);

  Object.values(object).map((value: any) =>
    typeof value === 'object' && value !== null ? deepFreeze(value) : value
  );

  return object;
}

const Basis = deepFreeze<Record<string, string>>({
  login: 'login',
  account: 'account',
  accountForm: 'account_form',
  reset: 'reset',
});

export const Translation = deepFreeze({
  ok: 'ok',
  cancel: 'cancel',
  help: 'Help',
  serverError: 'serverError',
  savedSuccessfully: 'savedSuccessfully',
  table: {
    uploadDate: 'table_uploadDate',
    source: 'table_source',
    status: 'table_status',
    numOfAnalysis: 'table_numOfAnalysis',
    analysis: {
      name: 'table_analysis_name',
      serviceUsed: 'table_analysis_serviceUsed',
    },
    inputDatasets: {
      datasetName: 'table_inputDatasets_datasetName',
      fileSize: 'table_inputDatasets_fileSize',
      lastServiceUsed: 'table_inputDatasets_lastServiceUsed',
      numberOfFiles: 'table_inputDatasets_numberOfFiles',
    },
    results: {
      resultName: 'table_results_resultName',
      date: 'table_results_date',
      service: 'table_results_service',
    },
  },
  reset: {
    header: `${Basis.reset}_header`,
    textRow1: `${Basis.reset}_textRow1`,
    textRow2: `${Basis.reset}_textRow2`,
    textRow3: `${Basis.reset}_textRow3`,
    minimumChars: `${Basis.reset}_minimumChars`,
    letterAndDigits: `${Basis.reset}_letterAndDigits`,
    oneCapitalLetter: `${Basis.reset}_oneCapitalLetter`,
    oneSpecialChar: `${Basis.reset}_oneSpecialChar`,
    noWhiteSpaces: `${Basis.reset}_noWhiteSpaces`,
    newPasswordLabel: `${Basis.reset}_newPasswordLabel`,
    confirmPasswordLabel: `${Basis.reset}_confirmPasswordLabel`,
    newPassowordErrMsg: `${Basis.reset}_newPassowordErrMsg`,
    confirmPasswordErrMsg: `${Basis.reset}_confirmPasswordErrMsg`,
    successToReset: `${Basis.reset}_successToReset`,
    errorToReset: `${Basis.reset}_errorToReset`,
    save: `${Basis.reset}_save`,
    successToRequestToResetPassword: `${Basis.reset}_successToRequestToResetPassword`,
    failedToRequestToResetPassword: `${Basis.reset}_failedToRequestToResetPassword`,
    invalidToken: `${Basis.reset}_invalidToken`,
  },
  login: {
    errorPassword: `${Basis.login}_errorPassword`,
    errorEmail: `${Basis.login}_errorEmail`,
    failedToLogin: `${Basis.login}_failedToLogin`,
    disabledAccount: `${Basis.login}_disabledAccount`,
    forgotPassword: `${Basis.login}_forgotPassword`,
    successToResetPassword: `${Basis.login}_successToResetPassword`,
    failedToResetPassword: `${Basis.login}_failedToResetPassword`,
    resetPassword: `${Basis.login}_resetPassword`,
    loginBtn: `${Basis.login}_loginBtn`,
    noUser: `${Basis.login}_noUser`,
  },
  account: {
    viewAccount: 'account_viewAccount',
    addClient: 'account_addClient',
    changeClient: 'account_changeClient',
    deleteClientConfirmationMsg: 'account_deleteClientConfirmationMsg',
    duplicateClientNumberErrorMsg: 'account_duplicateClientNumberErrorMsg',
    failedToDeleteClient: 'account_failedToDeleteClient',
    delete: 'account_delete',
    logout: 'account_logout',
    header: {
      subtitle: 'account_header_subtitle',
      title: 'account_header_title',
    },
    links: {
      personalInformation: 'account_links_personalInformation',
      companyInformation: 'account_links_companyInformation',
      languageManagement: 'account_links_languageManagement',
    },
    form: {
      companyInfo: `${Basis.accountForm}_companyInfo`,
      name: `${Basis.accountForm}_name`,
      msgName: `${Basis.accountForm}_msgName`,
      clientNumber: `${Basis.accountForm}_clientNumber`,
      msgClientNumber: `${Basis.accountForm}_msgClientNumber`,
      users: `${Basis.accountForm}_users`,
      members: `${Basis.accountForm}_members`,
      see: `${Basis.accountForm}_see`,
      servicesAndOptions: `${Basis.accountForm}_servicesAndOptions`,
      services: `${Basis.accountForm}_services`,
      activatedServices: `${Basis.accountForm}_activatedServices`,
      credit: `${Basis.accountForm}_credit`,
      msgCredit: `${Basis.accountForm}_msgCredit`,
      currency: `${Basis.accountForm}_currency`,
      msgCurrency: `${Basis.accountForm}_msgCurrency`,
      remainingCredit: `${Basis.accountForm}_remainingCredit`,
      receiveEmails: `${Basis.accountForm}_receiveEmails`,
    },
    languageManagement: {
      deleteBtnTitle: 'account_languageManagement_deleteBtnTitle',
      addBtnTitle: 'account_languageManagement_addBtnTitle',
      saveBtnText: 'account_languageManagement_saveBtnText',
      exportLanguage: 'account_languageManagement_export',
      importLanguage: 'account_languageManagement_import',
      failedToUpload: 'account_languageManagement_failedToUpload',
      successToUpload: 'account_languageManagement_successToUpload',
      failedToDeleteLang: 'account_languageManagement_failedToDeleteLang',
    },
    deleteLanguage: {
      deleteMsg: 'account_deleteLanguage_deleteMsg',
      noBtn: 'account_deleteLanguage_noBtn',
      yesBtn: 'account_deleteLanguage_yesBtn',
    },
    addLanguage: {
      addBtn: 'account_addLanguage_addBtn',
      newLanguage: 'account_addLanguage_newLanguage',
      flag: 'account_addLanguage_flag',
      languageName: 'account_addLanguage_languageName',
      isoCode: 'account_addLanguage_isoCode',
      selectPicture: 'account_addLanguage_selectPicture',
      languageNameErrMessage: 'account_addLanguage_languageNameErrMessage',
      isoCodeErrMessage: 'account_addLanguage_isoCodeErrMessage',
    },
    personalInfo: {
      language: 'account_personalInfo_language',
    },
  },
  menu: {
    analysis: 'menu_analysis',
    overview: 'menu_overview',
    datasets: 'menu_datasets',
    results: 'menu_results',
    settings: 'menu_settings',
    licence: 'menu_licence',
    pointsServices: 'menu_pointsServices',
  },
  overview: {
    filesSectionHeader: 'overview_filesSectionHeader',
    servicesSectionHeader: 'overview_servicesSectionHeader',
    viewResults: 'overview_viewResults',
    files: 'overview_files',
    readyToLaunch: 'overview_readyToLaunch',
    inProgress: 'overview_inProgress',
    analyzed: 'overview_analyzed',
    noRecentResults: 'overview_noRecentResults',
    noServices: 'overview_noServices',
  },
  header: {
    subHeaderText: 'header_subHeaderText',
    headerText: 'header_headerText',
  },
  activities: {
    header: 'activities_header',
    exportAllActivities: 'activities_exportAllActivities',
    noActivities: 'activities_noActivities',
  },
  settings: {
    header: {
      subtitle: 'settings_header_subtitle',
      title: 'settings_header_title',
    },
    links: {
      services: 'settings_links_services',
      options: 'settings_links_options',
      users: 'settings_links_users',
      system: 'settings_links_system',
      onlineHelp: 'settings_links_onlineHelp',
    },
    users: {
      edit: 'settings_users_edit',
      addUserHeader: 'settings_users_addUserHeader',
      editUserHeader: 'settings_users_editUserHeader',
      removePhoto: 'settings_users_removePhoto',
      deleteAccount: 'settings_users_deleteAccount',
      addPhoto: 'settings_users_addPhoto',
      personalInfo: 'settings_users_personalInfo',
      enable: 'settings_users_enable',
      clientAccount: 'settings_users_clientAccount',
      name: 'settings_users_name',
      firstName: 'settings_users_firstName',
      firstNameMsg: 'settings_users_firstNameMsg',
      lastName: 'settings_users_lastName',
      lastNameMsg: 'settings_users_lastNameMsg',
      countryCode: 'settings_users_countryCode',
      email: 'settings_users_email',
      emailMsg: 'settings_users_emailMsg',
      password: 'settings_users_password',
      phone: 'settings_users_phone',
      phoneMsg: 'settings_users_phoneMsg',
      role: 'settings_users_role',
      apply: 'settings_users_apply',
      addUser: 'settings_users_addUser',
      confirmationDeletionMsg: 'settings_users_confirmationDeletionMsg',
      confirmationTransferingMsg: 'settings_users_confirmationTransferingMsg',
      transferUserLabel: 'settings_users_transferUserLabel',
      duplicateEmailError: 'settings_users_duplicateEmailError',
      accessRights: {
        accessRightsLabel: 'settings_users_accessRights_accessRightsLabel',
        newAnalysis: 'settings_users_accessRights_newAnalysis',
        viewResults: 'settings_users_accessRights_viewResults',
        deleteResults: 'settings_users_accessRights_deleteResults',
        deleteDatasets: 'settings_users_accessRights_deleteDatasets',
        updateDatasets: 'settings_users_accessRights_updateDatasets',
        downloadResults: 'settings_users_accessRights_downloadResults',
      },
    },
    services: {
      enable: 'settings_services_enable',
      display: 'settings_services_display',
      createNewService: 'settings_services_createNewService',
      failedToDeleteService: 'settings_services_failedToDeleteServiceMsg',
      confirmationServiceDeletionMsg: 'settings_services_confirmationServiceDeletionMsg',
      header: 'settings_services_header',
      serviceName: 'settings_services_serviceName',
      serviceId: 'settings_services_serviceId',
      serviceDescription: 'settings_services_serviceDescription',
      index: 'settings_services_index',
      isReport: 'settings_services_isReport',
      hasDashboardOnResults: 'settings_services_hasDashboardOnResults',
      icon: 'settings_services_icon',
      fields: 'settings_services_fields',
      clearName: 'settings_services_clearName',
      technicalName: 'settings_services_technicalName',
      type: 'settings_services_type',
      mandatory: 'settings_services_mandatory',
      clearNameErrorMsg: 'settings_services_clearNameErrorMsg',
      technicalNameErrorMsg: 'settings_services_technicalNameErrorMsg',
      serviceNameErrorMsg: 'settings_services_serviceNameErrorMsg',
      serviceIdErrorMsg: 'settings_services_serviceIdErrorMsg',
      serviceDescriptionErrorMsg: 'settings_services_serviceDescriptionErrorMsg',
      indexErrorMsg: 'settings_services_indexErrorMsg',
      failedToSaveServices: 'settings_services_failedToSaveServices',
      uniqueServiceNameError: 'settings_services_uniqueServiceNameError',
      uniqueServiceIdError: 'settings_services_uniqueServicePlatformIdError',
    },
    system: {
      outputFolderLabel: 'settings_system_outputFolderLabel',
      outputFolderErrorMsg: 'settings_system_outputFolderErrorMsg',
      inputFolderLabel: 'settings_system_inputFolderLabel',
      inputFolderErrorMsg: 'settings_system_inputFolderErrorMsg',
      retentionPeriodLabel: 'settings_system_retentionPeriodLabel',
      retentionPeriodErrorMsg: 'settings_system_retentionPeriodErrorMsg',
      maxFileSize: 'settings_system_maxFileSize',
      maxFileSizeErrorMsg: 'settings_system_maxFileSizeErrorMsg',
      save: 'settings_system_save',
    },
    options: {
      defaultValue: 'settings_options_defaultValue',
      serviceLevelBasic: 'settings_options_serviceLevelBasic',
      serviceLevelAdvanced: 'settings_options_serviceLevelAdvanced',
      serviceLevelPremium: 'settings_options_serviceLevelPremium',
      name: 'settings_options_name',
      type: 'settings_options_type',
      addOption: 'settings_options_addOption',
      save: 'settings_options_save',
      saveOptionsErrorMsg: 'settings_options_saveOptionsErrorMsg',
      requiredNameMsg: 'settings_options_requiredNameMsg',
      serviceLabel: 'settings_options_serviceLabel',
    },
    onlineHelp: {
      preview: 'settings_onlineHelp_preview',
      languageSelection: 'settings_onlineHelp_languageSelection',
    },
  },
  analysis: {
    header: {
      upload: {
        upload: 'analysis_header_upload',
        title: 'analysis_header_upload_title',
        description: 'analysis_header_upload_description',
      },
      validation: {
        validation: 'analysis_header_validation',
        title: 'analysis_header_validation_title',
        description: 'analysis_header_validation_description',
      },
      options: {
        options: 'analysis_header_options',
        title: 'analysis_header_options_title',
        description: 'analysis_header_options_description',
      },
      submission: {
        submission: 'analysis_header_submission',
        title: 'analysis_header_submission_title',
        description: 'analysis_header_submission_description',
      },
      service: {
        service: 'analysis_header_service',
        title: 'analysis_header_service_title',
        description: 'analysis_header_service_description',
      },
    },
    upload: {
      dropCsvText: 'analysis_header_upload_dropCsvText',
      datasetName: 'analysis_header_upload_datasetName',
      selectFile: 'analysis_header_upload_selectFile',
      unsupportedFile: 'analysis_header_upload_unsupportedFile',
      excedeedFileSize: 'analysis_header_upload_exceededFileSize',
      addFileTitle: 'analysis_header_upload_addFileTitle',
      addFileExtensions: 'analysis_header_upload_addFileExtensions',
      selectDataset: 'analysis_header_upload_selectDataset',
      failedToUpload: 'analysis_header_upload_failedToUpload',
      exceededFilename: 'analysis_header_upload_exceededFilename',
      chooseDatasetFile: 'analysis_upload_chooseDatasetFile',
      selectDatasetFiles: 'analysis_upload_selectDatasetFiles',
    },
    validation: {
      selectedFileTitle: 'analysis_validation_selectedFileTitle',
      previewFile: 'analysis_validation_previewFile',
      failedToPreview: 'analysis_validation_failedToPreview',
      validate: 'analysis_validation_validate',
      fileOrganizationTitle: 'analysis_validation_fileOrganizationTitle',
      columnSeparatorLabel: 'analysis_validation_columnSeparatorLabel',
      textQuotedLabel: 'analysis_validation_textQuotedLabel',
      decimalSeparatorLabel: 'analysis_validation_decimalSeparatorLabel',
      dateFormat: 'analysis_validation_dateFormat',
      sameOperatorValidationMsg: 'analysis_validation_sameOperatorValidationMsg',
      sameColsValidationMsg: 'analysis_validation_sameColsValidationMsg',
      identificationOfColsTitle: 'analysis_validation_identificationOfColsTitle',
      identificationOfColsNoParameters: 'analysis_validation_identificationOfColsNoParameters',
      identificationOfColsValidationMsg: 'analysis_validation_identificationOfColsValidationMsg',
      idenOfColsErrorValidationMsg: 'analysis_validation_identificationOfColsErrorValidationMsg',
      duplicateOfColsValidationMsg: 'analysis_validation_duplicateOfColsValidationMsg',
      identificationOfColsMandatoryFields:
        'analysis_validation_identificationOfColsMandatoryFields',
      failedToOpenFile: 'analysis_validation_failedToOpenFile',
      failedToGetFileHeader: 'analysis_validation_failedToGetFileHeader',
    },
    options: {
      optionsTitle: 'analysis_options_optionsTitle',
      noOptions: 'analysis_options_noOptions',
      serviceLevelBasicInfo: 'settings_options_serviceLevelBasicInfo',
      serviceLevelAdvancedInfo: 'settings_options_serviceLevelAdvancedInfo',
      serviceLevelPremiumInfo: 'settings_options_serviceLevelPremiumInfo',
    },
    summary: {
      options: 'analysis_summary_options',
      summaryTitle: 'analysis_summary_summaryTitle',
      analyse: 'analysis_summary_analyse',
      file: 'analysis_summary_file',
      modify: 'analysis_summary_modify',
      launchedHeader1: 'analysis_summary_launchedHeader1',
      launchedHeader2: 'analysis_summary_launchedHeader2',
      launchedDescription: 'analysis_summary_launchedDescription',
      results: 'analysis_summary_results',
      estimatedTime: 'analysis_summary_estimatedTime',
      time: 'analysis_summary_time',
      launchNewAnalysis: 'analysis_summary_launchNewAnalysis',
      errorLaunchAnalysis: 'analysis_summary_errorLaunchAnalysis',
      launch: 'analysis_summary_launch',
    },
    update: {
      failedToUpdate: 'analysis_update_failedToUpdate'
    }
  },
  inputFiles: {
    header: {
      subtitle: 'inputFiles_header_subtitle',
      title: 'inputFiles_header_title',
      filesInDatabase: 'inputFiles_header_filesInDatabase',
    },
    table: {
      chooseAService: 'inputFiles_table_chooseAService',
      analyzed: 'inputFiles_table_analyzed',
      toValidate: 'inputFiles_table_toValidate',
      validated: 'inputFiles_table_validated',
      deleteErrorMsg: 'inputFiles_table_deleteErrorMsg',
      inProgress: 'inputFiles_table_inProgress',
      done: 'inputFiles_table_done',
      error: 'inputFiles_table_error',
      deleteFileMsg: 'inputFiles_table_deleteFileMsg',
    },
  },
  results: {
    rangeOfYears: 'results_rangeOfYears',
    return: 'results_return',
    allResults: 'results_allResults',
    failedToGetReports: 'result_failedToGetReports',
    downloadResults: 'results_downloadResults',
    failedToDelete: 'results_failedToDelete',
    dateOfAnalysis: 'results_dateOfAnalysis',
    typeOfAnalysis: 'results_typeOfAnalysis',
    option: 'results_option',
    fileSize: 'results_fileSize',
    origin: 'results_origin',
    view: 'results_view',
    failedToGetResults: 'results_failedToGetResults',
    viewAllResults: 'results_viewAllResults',
    dataAnalyzed: 'results_dataAnalyzed',
    dataAnalyzedTransactions: 'results_dataAnalyzedTransactions',
    dataAnalyzedTotalAmount: 'results_dataAnalyzedTotalAmount',
    vendors: 'results_vendors',
    analysisResults: 'results_analysisResults',
    issues: 'results_issues',
    inputAmount: 'results_inputAmount',
    outputAmount: 'results_outputAmount',
    totalAmountOfIssues: 'results_totalAmountOfIssues',
    relatedVendors: 'results_relatedVendors',
    failedToGetKpiData: 'results_failedToGetKpiData',
    noKpiData: 'results_noKpiData',
    kpiTypeOfDetection: 'results_kpiTypeOfDetection',
    kpiIssueType: 'results_kpiIssueType',
  },
  download: {
    failedToDownload: 'download_failedToDownload',
    downloadLinkAlreadyUsed: 'download_linkAlreadyUsed',
    requireTimeDownload: 'Generating Excel file, please comeback in 1 - 2 minutes'
  },
  gdpr: {
    privacyAndPolicyLink: 'gdpr_privacyAndPolicyLink',
    weInformYouOfOur: 'gdpr_weInformYou',
    takeTimeToUnderstand: 'gdpr_takeTime',
    IAgree: 'gdpr_IAgree',
    IDecline: 'gdpr_IDecline',
  },
  back: 'back',
});
