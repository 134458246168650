import styled from 'styled-components';
import {useCleanPopup} from '../../../hooks/useCleanPopup';
import {useTranslation} from '../../../providers';
import {downloadFile, downloadFileOutsideApp, getResultOptions} from '../../../services/results';
import {Result} from '../../../services/types/results';
import {Service} from '../../../services/types/settings';
import {Colors, dropShadow, FileExtensions, FileExtensionsLetters, FileTypes, Translation,} from '../../../static';
import {dateFormatter, getDenominatedSizeValue, getExtension} from '../../../utils';
import {DownloadResultButton} from '../../common';
import {Circle, RectanglePhoto} from '../../common/icons';
import {ResultsOptionsPopup} from '../download/ResultOptionsPopup';

const Container = styled.section`
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: ${Colors.white};
  filter: ${dropShadow};
  color: ${Colors.menuColor};
  border-radius: 30px;

  .title {
    font-weight: 600;
    font-size: 20px;
  }

  .row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    font-size: 13px;
    padding: 5px 0;

    .view {
      cursor: pointer;
      text-decoration: underline;
    }

    label {
      font-weight: 700;
    }
  }
`;

type ResultSummaryProps = {
  result: Result;
  service?: Service | null;
  style?: React.CSSProperties;
  token?: string;
  isOutSideApp: boolean;
};

export const LargeResultSummary = ({
                                     service,
                                     result,
                                     style,
                                     token,
                                     isOutSideApp,
                                   }: ResultSummaryProps) => {
  const {translate} = useTranslation();
  const {show} = useCleanPopup();

  return (
      <Container style={style}>
        <section className='title'>{result?.datasetName}</section>
        <Circle style={{filter: dropShadow}} size={170} bgColor={Colors.white}>
          <RectanglePhoto size={120} url={service?.photo ?? ''}/>
        </Circle>

        <section style={{display: 'flex', flexDirection: 'column'}}>
          <section className='row'>
            <label>{translate(Translation.results.dateOfAnalysis)}:</label>
            <span>{dateFormatter(result?.updatedAt)}</span>
          </section>

          <section className='row'>
            <label>{translate(Translation.results.typeOfAnalysis)}:</label>
            <span>{service?.name}</span>
          </section>

          <section className='row'>
            <label>{translate(Translation.results.option)}:</label>
            <span
                className='view'
                onClick={() =>
                    show(<ResultsOptionsPopup options={getResultOptions(result, service)}/>, {
                      color: '',
                      opacity: 0.5,
                    })
                }>
            {translate(Translation.results.view)}
          </span>
          </section>

          <section className='row'>
            <label>{translate(Translation.results.origin)}:</label>
            <span>{result?.source}</span>
          </section>

          <section className='row'>
            <label>{translate(Translation.results.fileSize)}:</label>
            <span>{getDenominatedSizeValue(result?.resultFileSize)}</span>
          </section>
        </section>

        <DownloadResultButton
                hasToken={true}
                result={result}
                accept={FileTypes[getExtension(result?.name) as FileExtensions]}
                downloadFile={setProgress =>
                    isOutSideApp ? downloadFileOutsideApp(result?._id,
                        token!!,
                        result?.resultFileSize ? result?.resultFileSize.toString() : null,
                        setProgress) : downloadFile(
                        result?._id,
                        result?.resultFileSize ? result?.resultFileSize.toString() : null,
                        setProgress
                    )
                }
                downloadFileXLSX={
                  setProgress => isOutSideApp ? downloadFileOutsideApp(result?._id,
                          token!!,
                          result?.resultFileSize ? result?.resultFileSize.toString() : null,
                          setProgress,
                          FileExtensionsLetters.XLXS,) :
                      downloadFile(
                          result?._id,
                          result?.resultFileSizeExcel ? result?.resultFileSizeExcel.toString() : null,
                          setProgress,
                          FileExtensionsLetters.XLXS,
                      )
                }
                name={result?.name}
        />
      </Container>
  );
};
