import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useCleanPopup } from '../../hooks/useCleanPopup';
import { useAuth, useTranslation } from '../../providers';
import { checkTokenValidity, clearUserInfo, resetPassword } from '../../services';
import { Colors, Routes, TIMERs, Translation } from '../../static';
import { getTitle } from '../../utils';
import { Button, Form, Loader, PasswordField } from '../common';

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    color: ${Colors.menuColor};
    width: 800px;
    height: 700px;
    border-radius: 30px;
    background-color: ${Colors.white};
    filter: drop-shadow(0 20px 80px rgba(12, 41, 48, 0.2));
    padding: 40px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .message {
      padding-left: 80px;
      height: 45%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        width: 100%;
      }

      li {
        width: 100%;
        padding: 5px 0;
      }
    }

    .form {
      padding: 0 80px;
      width: 100%;
      height: 35%;
      display: flex;
      flex-direction: column;

      label {
        font-size: 14px;
      }

      button {
        margin-top: 50px;
        justify-content: flex-end;
        align-self: center;
      }
    }
  }

  .valid {
    color: ${Colors.success};
  }
`;

interface State {
  newPassword?: string;
  confirmedPassword?: string;
}

const { reset } = Translation;
const formNames = Object.freeze({ newPassword: 'newPassword', confirmPassword: 'confirmPassword' });

export const Regexes = Object.freeze([
  { test: (value: string) => value?.length >= 8 },
  /(?=.*[a-zA-Z])(?=.*\d)/,
  /(?=.*[A-Z])(?=.*[a-z])/,
  /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
  { test: (value: string) => !/\s/.test(value) },
]);

const ResetPasswordComponent = ({ token }: { token: string }) => {
  const history = useHistory();
  const { show, clean } = useCleanPopup();
  const { translate } = useTranslation();
  const { setUserInfo } = useAuth();
  const [isTokenValid, setIsTokenValid] = React.useState(false);

  React.useEffect(() => {
    clearUserInfo();
    setUserInfo(null);
  }, [setUserInfo]);

  React.useEffect(() => {
    document.title = getTitle('Reset Password');
  }, []);

  React.useEffect(() => {
    checkTokenValidity(token).then(({ valid }) => {
      if (valid) setIsTokenValid(valid);
      else {
        show(translate(reset.invalidToken));
        clean(TIMERs.fiveSeconds, () => history.push(Routes.Login));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <Container>{!isTokenValid ? <Loader /> : <ResetPasswordForm token={token} />}</Container>;
};

export default ResetPasswordComponent;

export const ResetPasswordForm = ({ token }: { token: string }) => {
  const { show, clean } = useCleanPopup();
  const { translate } = useTranslation();
  const history = useHistory();
  const [validation, setValidation] = React.useState(
    Object.freeze([false, false, false, false, true])
  );
  const [{ newPassword, confirmedPassword }, setPassword] = React.useState<State>({
    newPassword: undefined,
    confirmedPassword: undefined,
  });

  const confirmPassRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <section className='content'>
      <h2>{translate(reset.header)}</h2>
      <section className='message'>
        <span>{translate(reset.textRow1)}</span>
        <span>{translate(reset.textRow2)}</span>
        <section>
          <span>{translate(reset.textRow3)}</span>
          <ul>
            <li className={validation[0] ? 'valid' : ''}>{translate(reset.minimumChars)}</li>
            <li className={validation[1] ? 'valid' : ''}>{translate(reset.letterAndDigits)}</li>
            <li className={validation[2] ? 'valid' : ''}>{translate(reset.oneCapitalLetter)}</li>
            <li className={validation[3] ? 'valid' : ''}>{translate(reset.oneSpecialChar)}</li>
            <li className={validation[4] ? 'valid' : ''}>{translate(reset.noWhiteSpaces)}</li>
          </ul>
        </section>
      </section>

      <Form
        className='form'
        name='resetPassword'
        formNames={formNames}
        onSubmit={({ newPassword }) => {
          resetPassword(newPassword, token)
            .then(() => show(translate(reset.successToReset), { color: Colors.success }))
            .catch(() => show(translate(reset.errorToReset)))
            .finally(() => clean(TIMERs.fiveSeconds, () => history.push(Routes.Login)));
        }}
      >
        <PasswordField
          label={translate(reset.newPasswordLabel)}
          name='newPassword'
          inline
          required
          errorMsg={translate(reset.newPassowordErrMsg)}
          onChange={({ target }) => {
            setPassword({
              newPassword: target?.value,
              confirmedPassword,
            });

            if (target?.value !== confirmedPassword && confirmedPassword?.length) {
              confirmPassRef.current?.setCustomValidity(translate(reset.confirmPasswordErrMsg));
            }
          }}
          isValidFn={value => {
            if (!value) {
              setValidation([false, false, false, false, true]);
              return false;
            }
            const results = Regexes.map(regex => regex.test(value as string));

            setValidation(results);
            return results.every(Boolean);
          }}
        />
        <PasswordField
          ref={confirmPassRef}
          label={translate(reset.confirmPasswordLabel)}
          name='confirmPassword'
          inline
          required
          onChange={({ target }) =>
            setPassword({
              newPassword,
              confirmedPassword: target?.value,
            })
          }
          errorMsg={translate(reset.confirmPasswordErrMsg)}
          isValidFn={value => newPassword === value}
        />

        <Button style={{ width: 200, fontSize: 15 }}>{translate(reset.save)}</Button>
      </Form>
    </section>
  );
};
