export const Colors = {
  white: '#ffffff',
  black: '#000000',
  blue: '#3562ff',
  lightBlue: '#86d3ff',
  inProgress: '#fab802',
  lightGreen: '#bdfe00',
  analysisCircleBg: '#2CFFF2',
  menuColor: '#384147',
  grey: '#9D9DB0',
  lightGrey: '#f5f8fa',
  tileBtnBgColor: '#f5f6f8',
  link: '#8db5cc',
  forgotPasswordColor: '#7c7c7c',
  error: 'red',
  success: 'green',
  orange: '#ff764d',
  tileTextColor: '#adadbc',
  bgColorTransactions: '#f1f6f6',
  disabled: '#d2d2db',
};

export const FileColors = {
  validate: Colors.lightBlue,
  toValidate: Colors.lightBlue,
  analyzed: Colors.lightGreen,
  inProgress: Colors.inProgress,
  error: Colors.error,
};

export const dropShadow = 'drop-shadow(0 0 7px rgba(12, 41, 48, 0.2))';
export const tableShadow = 'drop-shadow(0 0 10px rgba(12, 41, 48, 0.3))';
export const DonutChartColors = Object.freeze({
  DarkBlue: '#1f77b4',
  Orange: '#ff7f0e',
  Gray: '#7f7f7f',
  BlueLight: '#17becf',
  Purple: '#9467bd',
  Pink: '#e377c2',
  GreenLight: '#bcbd22',
  Brown: '#8c564b',
  GreenDark: '#2ca02c',
  Red: '#d62728',
});
