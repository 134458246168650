import styled from 'styled-components';
import { Colors } from '../../static';

type ContainerProps = {
  height?: number;
  hide?: boolean;
  hasLabel?: boolean;
};

export const FieldContainer = styled.section`
  padding: 5px 2px 5px 0;
  display: ${({ hide }: ContainerProps) => (hide ? 'none' : 'grid')};
  color: ${Colors.menuColor};

  &._inline {
    grid-template-columns: ${({ hasLabel }) =>
      hasLabel ? '30% minmax(0, 1fr)' : 'minmax(0, 1fr)'};
    column-gap: 2%;
    grid-template-rows: ${({ height }) => height}px;
  }

  &._block {
    grid-template-rows: ${({ height, hasLabel }) =>
      hasLabel ? `10px ${height}px` : `${height}px`};
    row-gap: 8px;
  }

  label {
    font-size: 13px;
    align-self: center;
  }
`;
