export const Routes = Object.freeze({
  DownloadResult: '/downloadResult',
  ResetPassword: '/resetPassword',
  Login: '/login',
  Overview: '/overview',
  Datasets: '/datasets',
  Results: '/results',
  Account: '/account',
  Settings: '/settings',
  Analysis: '/analysis',
});

export const RoutesName = Object.freeze({
  Login: 'Login',
  Overview: 'Overview',
  Datasets: 'Datasets',
  Results: 'Results',
  Account: 'Account',
  Settings: 'Settings',
  Analysis: 'Analysis',
});

export const SettingsRoutes = Object.freeze({
  services: `${Routes.Settings}/services`,
  users: `${Routes.Settings}/users`,
  system: `${Routes.Settings}/system`,
  options: `${Routes.Settings}/options`,
  languageManagement: `${Routes.Settings}/language`,
  onlineHelp: `${Routes.Settings}/onlineHelp`,
});

export const AccountRoutes = Object.freeze({
  personalInformation: `${Routes.Account}/personal`,
  companyInformation: `${Routes.Account}/company`,
});
