import createStore from '../../providers/create-store';
import { Result } from '../../services/types/results';
import { Service } from '../../services/types/settings';
import { AllResultsComponent } from './AllResults';
import { ResultsPerSericeComponent } from './ResultsPerService';

type Action =
  | { type: 'setSteps'; steps: Step[] }
  | { type: 'setService'; service: Service }
  | { type: 'setState'; state: State }
  | { type: 'setResult'; result: Result | null };

interface Step {
  isActive: boolean;
  Component: any;
}

export interface State {
  steps: Step[];
  service: Service | null | undefined;
  result: Result | null;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setSteps':
      return {
        ...state,
        steps: action.steps,
      };

    case 'setService':
      return {
        ...state,
        service: action.service,
      };

    case 'setState':
      return { ...action.state };

    case 'setResult':
      return { ...state, result: action.result };

    default:
      throw new Error('Not implemented action');
  }
}

const ResultsSteps = [
  {
    isActive: false,
    Component: AllResultsComponent,
  },
  {
    isActive: false,
    Component: ResultsPerSericeComponent,
  },
];

export const initialState: State = {
  steps: ResultsSteps,
  service: null,
  result: null,
};

export const {
  StoreProvider: ResultsProvider,
  useDispatch: useResultsDispatch,
  useStore: useResults,
} = createStore<State, Action>(reducer, initialState);
