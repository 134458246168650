import * as React from 'react';
import styled from 'styled-components';
import { TextField, TextFieldProps } from './TextField';
import { EyeHidden, Eye } from './icons';

const Icon = styled.span`
  padding: 0 5px;
  cursor: pointer;
  height: 20px;
`;

type PasswordFieldProps = TextFieldProps & { className?: string };
export const PasswordField = React.forwardRef(
  ({ className, ...restProps }: PasswordFieldProps, ref: any) => {
    const [type, setType] = React.useState<'password' | 'text'>('password');

    return (
      <TextField type={type} {...restProps} ref={ref}>
        <Icon onClick={() => setType(type === 'text' ? 'password' : 'text')}>
          {type === 'text' ? <Eye /> : <EyeHidden />}
        </Icon>
      </TextField>
    );
  }
);
