import { useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../../providers';
import { Routes, Translation } from '../../../static';
import { Button } from '../../common';
import { AnalyzedIcon } from '../../common/icons';
import { useAnalysis } from '../analysis-context';

const { summary } = Translation.analysis;

export const SuccessLaunchedSummary = () => {
  const { translate } = useTranslation();
  const { start } = useAnalysis();
  const history = useHistory();
  const ref = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    const element = ref.current;
    const listener = () => history.push(Routes.Results);

    if (element) element.firstElementChild?.addEventListener('click', listener);

    return () => element?.firstElementChild?.removeEventListener('click', listener);
  }, [history]);

  return (
    <section className='border launched'>
      <section className='infoHeader'>
        <AnalyzedIcon border={false} size={80} />
        <span className='launchedHeader' style={{ paddingTop: 10 }}>
          {translate(summary.launchedHeader1)}
        </span>
        <span className='launchedHeader'> {translate(summary.launchedHeader2)}</span>
        <span
          className='subHeader'
          ref={ref}
          dangerouslySetInnerHTML={{
            __html: translate(summary.launchedDescription, [
              `"<span class='link'>${translate(summary.results)}</span>"`,
            ]),
          }}
        ></span>
      </section>

      <section className='newAnalysis'>
        <Button className='launchNewBtn' onClick={start}>
          {translate(summary.launchNewAnalysis)}
        </Button>
      </section>
    </section>
  );
};
