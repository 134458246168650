import createStore from './create-store';

interface State {
  active: boolean;
}

type Action = { type: 'toggleActive'; active: boolean };

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'toggleActive':
      return {
        ...state,
        active: action.active,
      };

    default:
      return { ...state };
  }
}

export const {
  StoreProvider: ActivityStore,
  useDispatch: useActivityDispatch,
  useStore: useActivity,
} = createStore(reducer, { active: false });
