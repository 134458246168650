import styled from 'styled-components';
import { Colors } from '../../../static';

const SVG = styled.svg`
  width: 1em;
  height: 1em;
  font-size: 1.5rem;
  user-select: none;
`;

export const EyeHidden = () => (
  <SVG focusable='false' viewBox='0 0 24 24' aria-hidden='true'>
    <path d='M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z'></path>
  </SVG>
);

type EyeProps = {
  color?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
};
export const Eye = ({ color = Colors.black, onClick }: EyeProps) => (
  <SVG
    focusable='false'
    viewBox='0 0 24 24'
    aria-hidden='true'
    style={{ cursor: 'pointer' }}
    onClick={onClick}
  >
    <path
      fill={color}
      d='M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z'
    ></path>
  </SVG>
);

export const EditIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg height='16' viewBox='0 0 16 16' width='16' style={style}>
    <path
      fill={Colors.menuColor}
      fillRule='evenodd'
      d='M4.72 3.22a.75.75 0 011.06 1.06L2.06 8l3.72 3.72a.75.75 0 11-1.06 1.06L.47 8.53a.75.75 0 010-1.06l4.25-4.25zm6.56 0a.75.75 0 10-1.06 1.06L13.94 8l-3.72 3.72a.75.75 0 101.06 1.06l4.25-4.25a.75.75 0 000-1.06l-4.25-4.25z'
    ></path>
  </svg>
);

export const LightEyeIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg height='16' viewBox='0 0 16 16' width='16' style={style}>
    <path
      fill={Colors.menuColor}
      fillRule='evenodd'
      d='M1.679 7.932c.412-.621 1.242-1.75 2.366-2.717C5.175 4.242 6.527 3.5 8 3.5c1.473 0 2.824.742 3.955 1.715 1.124.967 1.954 2.096 2.366 2.717a.119.119 0 010 .136c-.412.621-1.242 1.75-2.366 2.717C10.825 11.758 9.473 12.5 8 12.5c-1.473 0-2.824-.742-3.955-1.715C2.92 9.818 2.09 8.69 1.679 8.068a.119.119 0 010-.136zM8 2c-1.981 0-3.67.992-4.933 2.078C1.797 5.169.88 6.423.43 7.1a1.619 1.619 0 000 1.798c.45.678 1.367 1.932 2.637 3.024C4.329 13.008 6.019 14 8 14c1.981 0 3.67-.992 4.933-2.078 1.27-1.091 2.187-2.345 2.637-3.023a1.619 1.619 0 000-1.798c-.45-.678-1.367-1.932-2.637-3.023C11.671 2.992 9.981 2 8 2zm0 8a2 2 0 100-4 2 2 0 000 4z'
    ></path>
  </svg>
);
