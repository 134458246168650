import { AgGridColumn, AgGridReact, AgGridReactProps } from 'ag-grid-react';
import * as React from 'react';
import styled from 'styled-components';
import { useDatasets } from '../../hooks';
import { useCleanPopup } from '../../hooks/useCleanPopup';
import { useAuth, useTranslation } from '../../providers';
import { uploadFile } from '../../services/analysis';
import { getMaxFileSize, saveFileSizeToLocalStorage } from '../../services/settings';
import {Dataset, Status} from '../../services/types/analysis';
import { CSVExtension, FileExtensions, TIMERs, Translation } from '../../static';
import { getDenominatedSizeValue, getUniqueValues } from '../../utils';
import { Header, MainContainer, UploadFile } from '../common';
import { DateCol } from '../common/ag-grid/date-column';
import { EnumFilter } from '../common/ag-grid/enum-filter';
import { getStatusRenderer, ManageDatasetCellRenderer, NumberOfFilesCellRenderer } from '../common/cell-renderers';
import { FileProvider, useFileDispatch, useFiles } from './file-store';
import './files.scss';

const Container = styled.section`
  height: 100%;
  overflow-y: hidden;
`;
const { inputFiles } = Translation;

const gridColProps = {
  flex: 1,
  sortable: true,
  filter: true,
  resizable: true,
};
const statusMinWidth = 150;
const manageGridColMinWidth = 230;
const { table } = Translation.inputFiles;

export const FilesComponent = () => <FileProvider children={<InnerFilesComponent />} />;

const InnerFilesComponent = () => {
  const { translate } = useTranslation();
  const { show, clean } = useCleanPopup();
  const { datasets } = useDatasets();
  const dispatch = useFileDispatch();
  const { _id } = useAuth().userInfo.user.client;
  const GridRef = React.useRef<AgGridReactProps>(null);
  const translations: Record<string, string> = React.useMemo(
    () => ({
      [Status.Analyzed]: translate(table.analyzed),
      [Status.ToValidate]: translate(table.toValidate),
      [Status.Validated]: translate(table.validated),
    }),
    [translate]
  );

  React.useEffect(() => {
    getMaxFileSize()
      .then(({ maxFileSize }) => saveFileSizeToLocalStorage(maxFileSize))
      .catch(() => saveFileSizeToLocalStorage(1000000));
  }, []);

  React.useEffect(() => {
    GridRef.current?.api?.showLoadingOverlay();
    if (datasets && GridRef.current) {
      const rowData = datasets.map(({...dataset} : Dataset) => ({
        ...dataset,
        statusForFilter: (translations?.[dataset.status] ?? dataset.status)
      }));
      GridRef.current.api?.setRowData(rowData);
      dispatch({ type: 'setCounter', counter: datasets.length });
    }
  }, [datasets, dispatch]);

  const onChange = (payload: { file: any; fileName?: string }) => {
    GridRef.current?.api?.showLoadingOverlay();
    uploadFile(payload, _id)
      .then(dataset => {
        if (GridRef.current) {
          const { api } = GridRef.current;
          const { rowsToDisplay }: any = api?.getModel();
          const data = rowsToDisplay.map(({ data }: any) => data);
          dataset.statusForFilter = translations?.[dataset.status] ?? dataset.status;
          api?.hideOverlay();
          api?.setRowData([dataset, ...data]);
          dispatch({ type: 'setCounter', counter: data.length + 1 });
        }
      })
      .catch(() => {
        show(translate(Translation.analysis.upload.failedToUpload));
        clean(TIMERs.fiveSeconds);
        GridRef.current?.api?.hideOverlay();
      });
  };
  const filterParams = React.useMemo(() => getUniqueValues('serviceName', datasets), [datasets]);

  return (
      <>
        <FileHeader/>
        <MainContainer className='files'>
          <UploadFile
              uploadResources={true}
              onChange={onChange}
              className='file'
              accept={[...CSVExtension, FileExtensions.TXT]}
          />

          <Container className='ag-theme-alpine table ag-grid'>
            <AgGridReact
                animateRows
                ref={GridRef as any}
                gridOptions={{rowHeight: 45}}
                frameworkComponents={{
                  manageDataCellRenderer: ManageDatasetCellRenderer,
                  statusCellRenderer: getStatusRenderer('Status'),
                  numberOfFilesCellRenderer: NumberOfFilesCellRenderer,
                }}
            >
              <AgGridColumn
                  headerName={translate(Translation.table.inputDatasets.datasetName)}
                  field='name'
                  {...gridColProps}
                  flex={2}
                  cellClass='bold'
              />
              <AgGridColumn
                  {...gridColProps}
                  headerName={translate(Translation.table.source)}
                  field='source'
                  filterFramework={EnumFilter}
                  filterParams={{values: ['Uploaded', 'Dropped']}}
              />
              {DateCol({
                ...gridColProps,
                headerName: translate(Translation.table.uploadDate),
                field: 'uploadedDate',
                cellClass: 'date',
              })}
              <AgGridColumn
                  {...gridColProps}
                  headerName={translate(Translation.table.inputDatasets.fileSize)}
                  field='fileSize'
                  valueFormatter={params => getDenominatedSizeValue(params.value)}
                  filter={false}
              />
              <AgGridColumn
                  {...gridColProps}
                  headerName={translate(Translation.table.inputDatasets.numberOfFiles)}
                  field='numberOfFiles'
                  cellRenderer='numberOfFilesCellRenderer'
              />
              <AgGridColumn
                  headerName={translate(Translation.table.inputDatasets.lastServiceUsed)}
                  field='serviceName'
                  {...gridColProps}
                  cellStyle={{textAlign: 'center'}}
                  filterFramework={EnumFilter}
                  filterParams={{values: filterParams}}
              />
              <AgGridColumn
                  {...gridColProps}
                  filterFramework={EnumFilter}
                  filterParams={{values: Object.values(translations)}}
                  headerName={translate(Translation.table.status)}
                  field='statusForFilter'
                  cellRenderer='statusCellRenderer'
                  minWidth={statusMinWidth}
              />
              <AgGridColumn
                  {...gridColProps}
                  headerName={translate(Translation.table.numOfAnalysis)}
                  field='numberOfAnalysis'
                  filter='agNumberColumnFilter'
              />
              <AgGridColumn cellRenderer='manageDataCellRenderer' minWidth={manageGridColMinWidth}/>
            </AgGridReact>
          </Container>
        </MainContainer>
      </>
  );
};

const FileHeader = () => {
  const {translate} = useTranslation();
  const store = useFiles();

  return (
      <Header
          subtitle={translate(inputFiles.header.subtitle)}
          title={translate(inputFiles.header.title)}
      >
        <section>{translate(inputFiles.header.filesInDatabase, [store?.counter ?? 0])}</section>
      </Header>
  );
};
