import * as React from 'react';
import { useAnalysis } from '../analysis-context';
import { ErrorLaunchedSummary } from './ErrorLaunchedSummary';
import { SuccessLaunchedSummary } from './SuccessLaunchedSummary';
import { SummaryProperties } from './SummaryProperties';
import './summary.scss';

export const AnalysisSummaryComponent = () => {
  const { launched } = useAnalysis();
  const [time, setTime] = React.useState(null);

  return (
    <section className='summary'>
      {!launched ? (
        <SummaryProperties setTime={setTime} />
      ) : time ? (
        <SuccessLaunchedSummary />
      ) : (
        <ErrorLaunchedSummary />
      )}
    </section>
  );
};
