import * as d3 from 'd3';
import * as React from 'react';
import { useTranslation } from '../../../providers';
import { DuplicatePaymentsKpiData } from '../../../services/types/results';
import { Colors, DonutChartColors, Translation } from '../../../static';

const margin = { top: 20, right: 60, bottom: 30, left: 60 };
const height = 350;

type PieChartProps = {
  data: DuplicatePaymentsKpiData[];
};

type DataToRender = {
  name: string;
  value: number;
};

const COLORS = Object.freeze([
  DonutChartColors.DarkBlue,
  DonutChartColors.Orange,
  DonutChartColors.Gray,
  DonutChartColors.BlueLight,
  DonutChartColors.Purple,
  DonutChartColors.Pink,
  DonutChartColors.GreenLight,
  DonutChartColors.Brown,
  DonutChartColors.GreenDark,
  DonutChartColors.Red,
]);

export const DonutChart = ({ data }: PieChartProps) => {
  const { translate } = useTranslation();
  const svgRef = React.useRef<SVGSVGElement | null>(null);
  const [issues, setIssues] = React.useState<Issue[]>([]);

  React.useEffect(() => {
    if (svgRef.current) {
      const issues: any[] = getData(data);

      const width = svgRef.current.clientWidth;
      const svg = d3.select(svgRef.current);
      const radius = Math.min(width, height) / 2;
      const innerRadius = radius - 10;
      const outerRadius = radius - 70;

      const pie = d3.pie().value((d: any) => d.value);
      const path: any = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

      const arcLabel = d3.arc().outerRadius(innerRadius).innerRadius(outerRadius);

      const g = svg
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2 + margin.top + 30})`);

      const arc = g
        .selectAll('arc')
        .data(pie(issues))
        .enter()
        .append('g')
        .style('cursor', 'pointer');

      const text = g.append('text').attr('class', 'text');
      arc
        .append('path')
        .attr('class', 'path')
        .attr('fill', (_: any, i) => COLORS[i])
        .attr('d', path)
        .on('mouseover', function (_: MouseEvent, data: any) {
          d3.select(this).style('fill-opacity', 0.8);

          text
            .attr('transform', () => {
              const [x, y] = arcLabel.centroid(data);

              return `translate(${x - 5}, ${y + margin.top / 2})`;
            })
            .text(d3.format(',')(data.value))
            .style('opacity', 1)
            .attr('stroke', Colors.menuColor);
        })
        .on('mouseout', function () {
          d3.select(this).style('fill-opacity', 1);
          text.style('opacity', 0);
        })
        .attr('stroke', Colors.white)
        .attr('stroke-width', 3);

      svg
        .append('g')
        .attr('transform', `translate(${width / 2}, 40)`)
        .attr('data-testid', 'title')
        .append('text')
        .text(translate(Translation.results.kpiTypeOfDetection))
        .style('text-anchor', 'middle')
        .attr('stroke', Colors.menuColor)
        .attr('stroke-width', 0.5);
      setIssues(issues);
    }
  }, [data, translate]);

  return (
    <section className='donutChart'>
      <svg width='100%' height={height + margin.top + margin.bottom} ref={svgRef}></svg>

      <section className='legend'>
        {COLORS.slice(0, issues.length).map((color, index) => {
          const issue = issues[index];

          return !issue ? null : (
            <span className='legendBar' key={index}>
              <section className='_bar' style={{ backgroundColor: color }}></section>
              <span>{issue.name}</span>
            </span>
          );
        })}
      </section>
    </section>
  );
};

interface Issue {
  name: string;
  value: number;
}

function getData(data: DuplicatePaymentsKpiData[]): DataToRender[] {
  const issues: Issue[] = [];

  for (const d of data) {
    d.outputIssues.forEach((issue, index) => {
      if (issues[index]) issues[index].value += +(issue.Issues_Number ?? issue.Issues_number ?? 0);
      else
        issues[index] = {
          name: issue.Name,
          value: +(issue.Issues_Number ?? issue.Issues_number ?? 0),
        };
    });
  }

  return issues.sort((a, b) => b.value - a.value);
}
