import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { previewDataset, previewDatasetFile } from '../../../services/analysis';
import { deleteDatasetFile } from '../../../services/files';
import { Dataset } from '../../../services/types/analysis';
import { Colors } from '../../../static';
import { Eye } from '../icons';
import { getDatasetFilesAsList, datasetHasFiles } from '../../../utils';
import { RawRendererPopup } from '../RawRendererPopup';

export const EyeCellRenderer = ({ data, onUpdate }: { data: Dataset, onUpdate?: (dataset: Dataset) => void }) => {
  const { show } = useCleanPopup();

  if(datasetHasFiles(data)) {

    const deleteFile = (datasetId:string, offset:string) => {
      return new Promise((resolve, reject) => {
        deleteDatasetFile(datasetId, offset)
          .then((ds) => {
            if(onUpdate !== undefined) {
              onUpdate(ds)
            }
            resolve(ds);
          })
          .catch(() => {
            reject()
          })
      });
    }

    return (
      <Eye
        color={Colors.orange}
        onClick={() => show(
          <RawRendererPopup callback={(args:any) => previewDatasetFile(data._id, args.offset)}
            menu={getDatasetFilesAsList(data.files)}
            deleteCallback={(args:any) => deleteFile(data._id, args.offset)}
          />
        )}
      />
    );  
  }

  return (
    <Eye
      color={Colors.orange}
      onClick={() => show(<RawRendererPopup callback={() => previewDataset(data._id)} />)}
    />
  );
};
