import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../static';

type HeaderProps = {
  children: React.ReactNode | React.ReactNode[];
  aside?: React.ReactNode | React.ReactNode[];
  className?: string;
  flex?: boolean;
  title: string;
  subtitle: string;
};

const Container = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 93% 7%;
  color: ${Colors.white};
`;

const Children = styled.section`
  height: 100%;
  border-bottom-right-radius: 200px;
  background-color: ${Colors.blue};
  padding: 30px 10px 20px 60px;

  &.flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const TitleContainer = styled.section`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;

  .subtitle {
    font-weight: 400;
    font-size: 20px;
  }

  .title {
    font-weight: 900;
    font-size: 80px;

    @media (max-width: 1700px) {
      font-size: 70px;
    }
  }
`;

export const Header = ({ children, aside, className = '', flex, title, subtitle }: HeaderProps) => (
  <Container>
    <Children className={flex ? `${className} flex` : className}>
      <TitleContainer>
        <span className='subtitle'>{subtitle}</span>
        <span className='title'>{title}</span>
      </TitleContainer>
      {children}
    </Children>
    <aside>{aside}</aside>
  </Container>
);
