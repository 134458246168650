import * as React from 'react';
import { Circle } from '.';

type PhotoProps = {
  photo?: React.ReactNode | null;
  size: number;
  borderColor?: string;
  bgColor?: string;
  borderWidth?: number;
  url?: string;
};

export const Photo = ({ url, photo, borderColor, size, bgColor, borderWidth = 2 }: PhotoProps) => (
  <Circle size={size} borderColor={borderColor} borderWidth={borderWidth} bgColor={bgColor}>
    {url ? (
      <img
        src={url}
        height={size - 3}
        width={size - 3}
        style={{ borderRadius: '50%' }}
        alt='profile'
      />
    ) : (
      photo
    )}
  </Circle>
);

export const RectanglePhoto = ({
  url,
  borderColor,
  size,
  bgColor,
  borderWidth = 2,
}: PhotoProps) => {
  return (
    <section
      style={{
        width: size,
        height: size,
        backgroundColor: bgColor,
        border: `${borderWidth}px solid ${borderColor}`,
      }}
    >
      {url ? <img src={url} height={size} width={size} alt='profile' /> : null}
    </section>
  );
};
