import { Colors } from '../../../static';

export const SmallErrorIcon = ({ strokeColor = Colors.black }: { strokeColor?: string }) => (
  <svg width='100%' height='100%' viewBox='0 0 22 22' fill='none'>
    <path
      d='M8.42188 8.44043L13.787 13.7695M13.787 13.7695L8.42188 8.44043'
      stroke={strokeColor}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M13.7695 8.42188L8.44047 13.787M8.44047 13.787L13.7695 8.42188'
      stroke={strokeColor}
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
