import styled from 'styled-components';
import { Colors } from '../../../static';

const Container = styled.section`
  width: ${({ size }: { size: number }) => size}px;
  height: ${({ size }) => size}px;
  align-self: flex-end;
  display: flex;
  cursor: pointer;

  .close {
    position: relative;
  }

  .close:before,
  .close:after {
    position: absolute;
    left: 0;
    content: ' ';
    background-color: ${({ color }) => color ?? Colors.menuColor};
    height: ${({ size }) => size}px;
    width: ${({ size }) => Math.floor(size / 6)}px;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }
`;

type CloseIconProps = {
  onClose?: React.MouseEventHandler<HTMLElement>;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
  color?: string;
};
export const CloseIcon = ({ onClose, size = 20, style, className, color }: CloseIconProps) => (
  <Container onClick={onClose} size={size} style={style} className={className} color={color}>
    <span className='close'></span>
  </Container>
);
