import { useRef } from 'react';
import { MessageComponent } from '../components/common';
import { usePopup } from '../providers';
import { Colors } from '../static';

type ShowProps = { color?: string; opacity?: number };
export const useCleanPopup = () => {
  const { setPopup } = usePopup();

  const ref = useRef({
    clean: (timer?: number, callback?: () => void) => {
      if (!timer) {
        setPopup(null);
        callback?.();
      } else {
        const timeoutId = setTimeout(() => {
          setPopup(null);
          clearTimeout(timeoutId);
          callback?.();
        }, timer);
      }
    },

    show: (Text: string | JSX.Element, props: ShowProps = {}) => {
      const { color = Colors.error, opacity } = props;
      const content =
        typeof Text === 'string' ? <MessageComponent color={color}>{Text}</MessageComponent> : Text;

      setPopup(content, true, opacity);
    },
  });

  return ref.current;
};
