import * as React from 'react';
import { useAuth, useTranslation } from '../../providers';
import { getFilesSummary } from '../../services/results';
import { Translation } from '../../static';
import { Header } from '../common';
import { AnalyzedIcon, InProgressIcon, ReadyIcon } from '../common/icons';

interface HeaderDescription {
  value: number;
  key: string;
  icon: React.ReactNode;
}

const defaultValues = [
  {
    icon: <AnalyzedIcon />,
    value: 0,
    key: Translation.overview.analyzed,
  },
  {
    icon: <InProgressIcon />,
    value: 0,
    key: Translation.overview.inProgress,
  },
  {
    icon: <ReadyIcon />,
    value: 0,
    key: Translation.overview.readyToLaunch,
  },
];

export const OverviewHeader = () => {
  const { translate } = useTranslation();
  const { _id: clientId } = useAuth().userInfo?.user?.client ?? {};
  const [data, setData] = React.useState<HeaderDescription[]>(defaultValues);

  React.useEffect(() => {
    getFilesSummary(clientId).then(response => {
      const [Analyzed, InProgress, Ready] = defaultValues;

      Analyzed.value = response.analyzed;
      InProgress.value = response.onGoing;
      Ready.value = response.readyToLaunch;
      setData([Analyzed, InProgress, Ready]);
    });
  }, [clientId]);

  return (
    <Header
      flex
      subtitle={translate(Translation.header.subHeaderText)}
      title={translate(Translation.header.headerText)}
    >
      <section className='ovHeader'>
        {data.map(({ value, key, icon }) => (
          <section key={key} className='ovHCol'>
            {icon}
            <section style={{ paddingLeft: 20 }}>
              <section className='numbers'>{value}</section>
              <section>{translate(Translation.overview.files)}</section>
              <section className='text'>{translate(key)}</section>
            </section>
          </section>
        ))}
      </section>
    </Header>
  );
};
