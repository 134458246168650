export const ArrowUp = () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 0.96 0.96"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h0.96v0.96H0z" fill="none" />
      <g id="Shopicon">
        <path
            points="6.586,30.586 9.414,33.414 24,18.828 38.586,33.414 41.414,30.586 24,13.172  "
            d="M0.132 0.612L0.188 0.668L0.48 0.377L0.772 0.668L0.828 0.612L0.48 0.263Z"
        />
      </g>
    </svg>
);
