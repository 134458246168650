/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from '../components/common';
import { AccountNavRoutes, Roles, SettingsNavRoutes } from '../components/routing/NavItems';
import { clearUserInfo, getUserInfo, logout, setUserInfo } from '../services';
import { getLanguageResources } from '../services/translation';
import { UserInfo } from '../services/types/authentication';
import { Routes } from '../static';
import { useTranslation } from './translation-context';

interface Auth {
  userInfo: UserInfo;
  setUserInfo: (user: UserInfo | null) => void;
  logout: () => void;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isManager: boolean;
}

const AuthContext = React.createContext({} as Auth);

type AuthProviderProps = {
  children: React.ReactNode;
};
const isAuthenticated = (userInfo: UserInfo | null) => userInfo?.token != null;
const AuthProvider = ({ children }: AuthProviderProps) => {
  const {
    state: { isReady, language },
    setState,
  } = useTranslation();
  const [userInfo, setUser] = React.useState<UserInfo | null>(getUserInfo());
  const history = useHistory();

  React.useEffect(() => {
    getLanguageResources(language)
      .then(() => setState({ language, isReady: true }))
      .catch(() => setState({ language, isReady: false }));
  }, [setState, language]);

  React.useEffect(() => {
    if (userInfo?.user?.lang && userInfo.user.lang !== language) {
      const lang = userInfo?.user?.lang ?? language;

      getLanguageResources(lang)
        .then(() => setState(() => ({ language: lang, isReady: true })))
        .catch(() => {
          const language = 'en';
          getLanguageResources(language)
            .then(() => setState(() => ({ language, isReady: true })))
            .catch(() => setState({ language, isReady: false }));
        });
    }
  }, [userInfo?.user?.lang]);

  React.useEffect(() => {
    if (userInfo?.user) {
      SettingsNavRoutes[userInfo.user.role.type].forEach(({ Component }) => Component());
      AccountNavRoutes[userInfo.user.role.type].forEach(({ Component }) => Component());
    }
  }, [userInfo?.user]);

  const setUserInfoRef = React.useRef(async (userInfo: UserInfo) => {
    if (userInfo?.token !== null) {
      await setUserInfo(userInfo);
      setUser(userInfo);
    }
  });

  if (!isReady) {
    return <Loader />;
  }

  const value = {
    userInfo,
    setUserInfo: setUserInfoRef.current,
    isAuthenticated: isAuthenticated(userInfo),
    logout: () => {
      logout();
      clearUserInfo();
      setUser({} as UserInfo);
      history.push(Routes.Login);
    },
    isAdmin: isAuthenticated(userInfo) && userInfo?.user?.role?.type === Roles.admin,
    isManager: isAuthenticated(userInfo) && userInfo?.user?.role?.type === Roles.manager,
  } as Auth;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
