import * as React from 'react';
import { ScorllLeftIcon, ScrollRightIcon } from '../common/icons';
import './style.scss';

type Props = {
  tilesRef: any;
  length: number;
  boxWidth: number;
  header: string;
};

export const ScrollHeaderWithResults = ({ tilesRef, length, boxWidth, header }: Props) => {
  const [disabled, setDisabled] = React.useState({ left: true, right: true });
  const scrollWidth = boxWidth * 2;

  React.useEffect(() => {
    if (tilesRef.current?.clientWidth <= boxWidth * length) {
      setDisabled(({ left }) => ({ left, right: false }));
    }
  }, [tilesRef, length, boxWidth]);

  return (
    <section className='bHeader'>
      <span>{header}</span>

      <section className='btns'>
        <ScorllLeftIcon
          disabled={disabled.left}
          onClick={() => {
            tilesRef.current.scrollBy({ behavior: 'smooth', left: -scrollWidth });
            setDisabled({ left: tilesRef.current.scrollLeft - scrollWidth <= 0, right: false });
          }}
        />

        <ScrollRightIcon
          disabled={disabled.right}
          onClick={() => {
            tilesRef.current.scrollBy({ behavior: 'smooth', left: scrollWidth });
            const { scrollLeft, clientWidth, scrollWidth: SW } = tilesRef.current;
            const right = clientWidth + scrollLeft + scrollWidth >= SW;

            setDisabled({ left: false, right });
          }}
        />
      </section>
    </section>
  );
};
