import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../static';
import { FieldContainer } from './styled';

type ContainerProps = {
  isError?: boolean;
  disabled?: boolean;
};

const Container = styled(FieldContainer)`
  select {
    height: 100%;
    font-size: 15px;
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    color: ${Colors.menuColor};
    background-color: ${Colors.tileBtnBgColor};
    border: ${({ isError = false }: ContainerProps) =>
      isError ? `1px solid ${Colors.error}` : 'none'};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export type SelectFieldValue = { value: any; label: string };

type SelextFieldProps = {
  values: SelectFieldValue[];
  inline?: boolean;
  name?: string;
  defaultValue?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: SelectFieldValue, target?: EventTarget & HTMLSelectElement) => void;
  height?: number;
  style?: React.CSSProperties;
  includeNone?: boolean;
  includeStar?: boolean;
  errorMsg?: string;
  callback?: (target?: (EventTarget & HTMLSelectElement) | null) => void;
};
const defaultOption = '[None]';

export const SelectField = ({
  values,
  inline = true,
  defaultValue,
  label,
  onChange,
  height = 45,
  includeNone,
  includeStar,
  errorMsg,
  callback,
  ...rest
}: SelextFieldProps) => {
  const [value, setValue] = React.useState(defaultValue ?? defaultOption);
  const selectRef = React.useRef<HTMLSelectElement>(null);

  React.useEffect(() => {
    setValue(defaultValue ?? defaultOption);
  }, [defaultValue]);

  React.useEffect(() => {
    if (callback) callback(selectRef.current);
  }, [callback]);

  return (
    <section style={{ position: 'relative' }}>
      <Container
        disabled={rest.disabled}
        height={height}
        hasLabel={Boolean(label)}
        className={inline ? '_inline' : '_block'}
      >
        {label ? <label>{label}</label> : null}

        <select
          ref={selectRef}
          {...rest}
          value={value}
          onInvalid={(event: any) => {
            if (!event.target.value) {
              event.target.style.border = `1px solid ${Colors.error}`;
              event.target.setCustomValidity(errorMsg);
            }
          }}
          onChange={function ({ target, target: { value } }) {
            if (onChange) {
              const [currentValue] = values.filter(({ value: val }) => val === value);

              onChange(currentValue, target);
            }
            if (value) {
              target.style.border = 'none';
              target.setCustomValidity('');
            }

            setValue(value);
          }}
        >
          {includeNone ? <option value=''>{defaultOption}</option> : null}
          {values.map((value, index) => (
            <option key={index} value={value?.value ?? value}>
              {value?.label ?? value}
            </option>
          ))}
        </select>
      </Container>
      {rest.required && includeStar ? (
        <span style={{ position: 'absolute', top: 20, right: -8, color: Colors.error }}>*</span>
      ) : null}
    </section>
  );
};
