import React from 'react';
import styled from 'styled-components';
import { useActivityDispatch } from '../../providers/activities-context';

type ContainerProps = {
  scrollY?: boolean;
  scrollX?: boolean;
};

const Container = styled.section`
  padding: 0 10px 0 60px;
  overflow-y: ${({ scrollY }: ContainerProps) => (scrollY ? 'scroll' : 'hidden')};
  overflow-x: ${({ scrollX }: ContainerProps) => (scrollX ? 'scroll' : 'hidden')};
`;

type MainProps = {
  children: React.ReactNode | React.ReactNode[];
  scrollY?: boolean;
  scrollX?: boolean;
  showActivities?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const MainContainer = ({
  showActivities = false,
  scrollY = false,
  scrollX,
  ...rest
}: MainProps) => {
  const dispatch = useActivityDispatch();

  React.useEffect(() => {
    dispatch({ type: 'toggleActive', active: showActivities });
  }, [dispatch, showActivities]);

  return <Container scrollY={scrollY} scrollX={scrollX} {...rest} />;
};
