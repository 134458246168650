import {
  Language,
  LanguageResponse,
  PartialLanguage,
  TranslationLanguage,
} from '../services/types/account';
import { Endpoint, Translation } from '../static';
import { AuthFetch } from './request';
import { TranslationService } from './translation';
import { Client } from './types/authentication';

interface CompanyInfo {
  name: string;
  clientNumber: string;
  credit: number;
  photo: string;
}

export const getRowData = (language: string) =>
  getKeys(Translation).map(key => ({
    key,
    english: TranslationService.translate(key, [], 'en'),
    newLang: TranslationService.translate(key, [], language),
  }));

const getKeys = (object: Record<string, string | Object>): string[] =>
  Object.values(object).reduce((acc: string[], next: any): string[] => {
    if (typeof next !== 'string') {
      return acc.concat(getKeys(next));
    }

    return [...acc, next];
  }, []);

interface Rows {
  data: CompanyInfo;
}

export const getLanguage = (isoCode: string): Promise<TranslationLanguage> => {
  try {
    return AuthFetch.GET(`${Endpoint.acccount.getLanguage}/${isoCode}`);
  } catch (err) {
    throw err;
  }
};

export const savePreferredLanguage = async (iso: string): Promise<void> =>
  AuthFetch.POST(Endpoint.acccount.setLangauge, { iso });

export const saveTranslations = async (rows: Rows[], isoCode: string, keys: string[]) => {
  const translations = rows.reduce((acc: any, { data: { key, newLang } }: any) => {
    if (keys.includes(key)) acc[key] = newLang;

    return acc;
  }, {});

  try {
    const { translations: langs } = await AuthFetch.POST(Endpoint.acccount.saveTranslations, {
      isoCode,
      translations,
    });

    TranslationService.setLanguageResources(isoCode, { ...langs, ...translations });
  } catch (err) {
    console.error('Failed ', err);
  }
};

export const addLanguage = async (language: Language): Promise<Language[]> => {
  try {
    const response = await AuthFetch.POST<LanguageResponse & { error: string }>(
      Endpoint.acccount.addLanguage,
      {
        isoCode: language.id,
        name: language.value,
        flag: language.flag,
      }
    );

    if (response.error) throw response.error;

    const { isoCode: id, name: value, flag } = response;

    return [{ id, value, flag }];
  } catch (err) {
    throw err;
  }
};

export const deleteLanguage = async (isoCode: string) => {
  try {
    const response = await AuthFetch.DELETE<{ error: string }>(
      `${Endpoint.acccount.deleteLanguage}/${isoCode}`
    );

    if (response.error) throw response.error;
  } catch (error) {
    throw error;
  }
};

export const getAllLanguages = async (): Promise<PartialLanguage[]> => {
  try {
    const { languages } = await AuthFetch.GET<{ languages: LanguageResponse[] }>(
      Endpoint.acccount.getAllLanguages
    );

    return languages.map(({ isoCode: id, name: value, flag }: LanguageResponse) => ({
      id,
      value,
    }));
  } catch (err) {
    throw err;
  }
};

export const exportLanguage = async (isoCode: string): Promise<string> => {
  return AuthFetch.GET<string>(`${Endpoint.acccount.exportLanguage}/${isoCode}`, 'text');
};

export const importLanguage = async (file: any): Promise<TranslationLanguage> => {
  const formData = new FormData();
  formData.append('file', file);

  return AuthFetch.POST<TranslationLanguage>(Endpoint.acccount.importLanguage, formData, 'text');
};

export const deleteClient = async (_id: string): Promise<void> =>
  AuthFetch.DELETE(`${Endpoint.acccount.clients}/${_id}`);

export interface ClientRequest {
  currency: string;
  name: string;
  clientNumber: string;
  credit: number;
  photo: string;
  _id: string | null;
}

export const addClient = async (client: ClientRequest): Promise<Client> => {
  try {
    const response: Client & { error: string } = await AuthFetch.POST(
      Endpoint.acccount.clients,
      client
    );
    if (response.error) {
      throw response.error;
    }

    return response;
  } catch (err) {
    throw err;
  }
};

export const getAllClients = async (): Promise<Client[]> =>
  AuthFetch.GET(Endpoint.acccount.clients);
