import { useServices } from '../../providers/services-context';
import { MainContainer } from '../common';
import { OverviewHeader } from './OverviewHeader';
import { OverviewResults } from './OverviewResults';
import { OverviewServices } from './OverviewServices';
import './style.scss';

const overviewStyle = { padding: 0 };

const Overview = () => {
  const { activeServices } = useServices();

  return (
    <>
      <OverviewHeader />

      <MainContainer showActivities style={overviewStyle} className='overview'>
        <OverviewResults services={activeServices} />
        <OverviewServices services={activeServices} />
      </MainContainer>
    </>
  );
};

export default Overview;
