export enum StatusCode {
  None = 0,
  InProgress = 1,
  Done = 2,
  Error = 3,
  InLaunchProgress = 4,
}

export interface Result {
  _id: string;
  name: string;
  datasetName: string;
  updatedAt: Date | string;
  statusCode: string | StatusCode;
  statusText: string;
  analysisSettings: AnalysisSettings;
  source: string;
  service: string;
  serviceId?: string;
  isReport?: boolean;
  hasDashboardOnResults?: boolean;
  resultFileSize?: number;
  resultFileSizeExcel? :number;
  tooBigExcelFile?: boolean;
}

interface AnalysisSettings {
  options: Record<string, boolean | number | string | RangeDate>;
}

export interface ResultSummary {
  serviceId: string;
  serviceName: string;
  readyToLaunch: number;
  inProgress: number;
  analyzed: number;
  errors: number;
  isReport: boolean;
}

export interface DuplicatePaymentsKpiData {
  year: string;
  inputTransactions: number;
  inputAmounts: number;
  inputVendors: number;
  outputAmounts: number;
  outputVendors: number;
  outputIssues: OutputIssues[];
  issues: number;
}

export interface AnalysisResults {
  issues: number;
  totalAmmountOfIssues: number;
  vendors: number;
  percentageIssues: number;
  totalPercentageIssues: number;
}

export interface DataAnalyzed {
  transactions: number;
  totalAmount: number;
  vendors: number;
}

export interface KPIData {
  analysisResults: AnalysisResults;
  dataAnalyzed: DataAnalyzed;
  rawData: DuplicatePaymentsKpiData[];
}

interface ObsoleteKpiResponseBase {
  Input_vendors?: string;
  Output_vendors?: string;
}
interface KPIReponseBase extends ObsoleteKpiResponseBase {
  Year: string;
  Input_Transactions: string;
  Input_Amounts: string;
  Input_Vendors: string;
  Output_Amounts: string;
  Output_Vendors: string;
}
export interface KPIResponseDataObsolete extends KPIReponseBase {
  Output_Issues_Type1: string;
  Output_Issues_Type2: string;
  Output_Issues_Type3: string;
  Output_Issues_Type4: string;
  Output_Issues_Type5: string;
}

export interface OutputIssues {
  Name: string;
  Type: string;
  Issues_Number?: number;
  Issues_number?: number;
}
export interface KPIResponseData extends KPIReponseBase {
  Output_Issues: OutputIssues[];
}

export interface RangeDate {
  from: string | null | any;
  to: string | null | any;
}

export interface FilesSummary {
  readyToLaunch: number;
  onGoing: number;
  analyzed: number;
}
