import { Dataset } from '../../../services/types/analysis';
import { DownloadRenderer } from './download-renderer';
import { EyeCellRenderer } from './eye-renderer';
import { DataCellRendererContainer } from './styled';

export const AnalysisDatasetsCellRenderer = ({ data }: { data: Dataset }) => (
  <DataCellRendererContainer style={{ height: 41 }}>
    <EyeCellRenderer data={data} />
    <DownloadRenderer data={data} />
  </DataCellRendererContainer>
);
