import { UserInfo } from './types/authentication';

const AUTH = Object.freeze({
  userInfo: '$user-info',
});

export const isAuthenticated = (): boolean => {
  const { token } = getUserInfo();

  return !!token;
};

export const clearUserInfo = (): void => sessionStorage.clear();

export const setUserInfo = (userInfo: UserInfo | null) =>
  sessionStorage.setItem(AUTH.userInfo, JSON.stringify(userInfo ?? {}));

export const getUserInfo = (): UserInfo => {
  const userInfo = sessionStorage.getItem(AUTH.userInfo);

  return userInfo === null ? {} : JSON.parse(userInfo);
};
