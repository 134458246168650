import { GridApi } from 'ag-grid-community';
import { AgGridColumn, AgGridReact, AgGridReactProps } from 'ag-grid-react';
import * as React from 'react';
import { useDatasets } from '../../../hooks';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { useAuth, useTranslation } from '../../../providers';
import { uploadFile } from '../../../services/analysis';
import { Dataset } from '../../../services/types/analysis';
import { CSVExtension, FileExtensions, TIMERs, Translation } from '../../../static';
import { getUniqueValues } from '../../../utils';
import { UploadFile } from '../../common';
import { DateCol } from '../../common/ag-grid/date-column';
import { EnumFilter } from '../../common/ag-grid/enum-filter';
import { AnalysisDatasetsCellRenderer, NumberOfFilesCellRenderer } from '../../common/cell-renderers';
import { useAnalysis } from '../analysis-context';

type TableProps = {
  selectDataset: React.Dispatch<React.SetStateAction<Dataset | null>>;
};

const gridColProps = {
  flex: 1,
  sortable: true,
  filter: true,
  resizable: true,
};

export const DatasetsTable = ({ selectDataset }: TableProps) => {
  const { _id: clientId } = useAuth().userInfo?.user?.client ?? {};
  const { state } = useAnalysis();
  const { show, clean } = useCleanPopup();
  const { datasets, updateCache, getFromCache } = useDatasets(true);
  const { translate } = useTranslation();
  const GridRef = React.useRef<AgGridReactProps>(null);

  React.useEffect(() => {
    if (GridRef.current) {
      const api = GridRef.current.api as GridApi;
      if (datasets) {
        api.setRowData(datasets);

        // Select the file already used in wizard flow
        if (state.dataset) {
          api.getRowNode(String(state.dataset.rowIndex))?.setSelected(true);
        }
      } else {
        api.showLoadingOverlay();
      }
    }
  }, [datasets, state.dataset]);

  const onChange = (file: any) => {
    GridRef.current?.api?.showLoadingOverlay();
    uploadFile(file, clientId)
      .then(dataset => {
        if (GridRef.current) {
          const cache = getFromCache(clientId);
          const newData = { ...dataset, rowIndex: 0 };
          const api = GridRef.current.api as GridApi;

          api?.hideOverlay();
          api.applyTransaction({ add: [dataset], addIndex: 0 });
          api.selectIndex(0, null, null);
          selectDataset(newData);
          updateCache(cache ? [newData, ...cache] : [newData]);
        }
      })
      .catch(() => {
        show(translate(Translation.analysis.upload.failedToUpload));
        clean(TIMERs.fiveSeconds);
        GridRef.current?.api?.hideOverlay();
      });
  };
  const filterParams = React.useMemo(() => getUniqueValues('serviceName', datasets), [datasets]);

  return (
    <>
      <UploadFile
        className='file'
        onChange={onChange}
        uploadResources={true}
        accept={[...CSVExtension, FileExtensions.TXT]}
      />

      <section className='ag-theme-alpine ag-grid table' style={{ paddingBottom: 5 }}>
        <AgGridReact
          animateRows
          ref={GridRef as any}
          rowSelection='single'
          onRowClicked={({ data, rowIndex }) => selectDataset({ ...data, rowIndex })}
          frameworkComponents={{ 
            renderer: AnalysisDatasetsCellRenderer,
            numberOfFilesCellRenderer: NumberOfFilesCellRenderer,
          }}
        >
          <AgGridColumn
            headerName={translate(Translation.table.analysis.name)}
            field='name'
            {...gridColProps}
            flex={2}
            cellClass='cursor'
          />
          {DateCol({
            ...gridColProps,
            headerName: translate(Translation.table.uploadDate),
            field: 'uploadedDate',
            cellClass: 'date cursor',
          })}
          <AgGridColumn
            headerName={translate(Translation.table.source)}
            field='source'
            {...gridColProps}
            cellClass='cursor'
            filterFramework={EnumFilter}
            filterParams={{ values: ['Uploaded', 'Dropped'] }}
          />
          <AgGridColumn
            {...gridColProps}
            headerName={translate(Translation.table.inputDatasets.numberOfFiles)}
            field='numberOfFiles'
            cellRenderer='numberOfFilesCellRenderer'
          />
          <AgGridColumn
            headerName={translate(Translation.table.analysis.serviceUsed)}
            field='serviceName'
            {...gridColProps}
            cellClass='cursor'
            filterFramework={EnumFilter}
            filterParams={{ values: filterParams }}
          />
          <AgGridColumn
            headerName={translate(Translation.table.numOfAnalysis)}
            field='numberOfAnalysis'
            {...gridColProps}
            filter='agNumberColumnFilter'
            minWidth={200}
            cellClass='cursor'
          />
          <AgGridColumn field='' maxWidth={100} cellRenderer='renderer' />
        </AgGridReact>
      </section>
    </>
  );
};
