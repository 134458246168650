import { AgGridReactProps } from 'ag-grid-react';
import { Dataset } from '../../../services/types/analysis';
import { DataCellRendererContainer } from './styled';
import { datasetHasFiles } from '../../../utils';

export const NumberOfFilesCellRenderer = ({ data, api }: { data: Dataset } & AgGridReactProps) => {
  
  return (
    <DataCellRendererContainer>
      {datasetHasFiles(data) ? data.files.length : ''}
    </DataCellRendererContainer>
  );
};
