import styled from 'styled-components';
import { Colors, dropShadow } from '../../static';

const Spinner = styled.div`
  background-color: ${Colors.white};
  filter: ${dropShadow};
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid ${Colors.blue};
  width: 50px;
  height: 50px;
  animation: spin 0.5s linear infinite;
  z-index: 1;
  margin-left: calc(50% - 25px);
`;

const Wrapper = styled.section`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const ProgressBarContainer = styled.div`
  background-color: ${Colors.white};
  filter: ${dropShadow};
  border: 2px solid #f3f3f3;
  border-radius: 15px;
  margin-top: 30px;
  width: 100%;
  z-index: 1;
  text-align: center;
`;

const ProgressBar = styled.div`
  background-color: ${Colors.blue};
  z-index: 1;
  height: 20px;
  border-radius: 15px;
`;

const Progress = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  font-size: 16px;
  translation: all 0.4s ease;
`;

const WarningText = styled.div`
  text-align: center;
  line-height: 1.5em;
  font-weight: bolder;
`;

export const Loader = ({
  relative = false,
  progress = null,
}: {
  relative?: boolean;
  progress?: number | null;
}) => {
  const LoadElement = (
    <Wrapper>
      {progress ? (
        <>
          <WarningText>
            Download in progress <br />
            Please do not close this tab until the download is completed.
          </WarningText>
          <ProgressBarContainer>
            <ProgressBar style={{ width: progress + '%' }} />
            <Progress
              style={{
                color: progress < 50 ? 'black' : 'white',
              }}
            >
              {progress}%
            </Progress>
          </ProgressBarContainer>
        </>
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );

  return relative ? <section style={{ position: 'relative' }}>{LoadElement}</section> : LoadElement;
};
