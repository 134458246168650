export const SuccessIcon = ({ strokeColor = '#436F0B' }: { strokeColor?: string }) => (
  <svg width='100%' height='100%' viewBox='0 0 24 22' fill='none'>
    <path
      d='M7.99082 11L9.40331 12.3911C10.1229 13.0998 10.4827 13.4541 10.9188 13.4343C11.3549 13.4145 11.6811 13.029 12.3335 12.258L15.8557 8.09534'
      stroke={strokeColor}
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
