import styled from 'styled-components';
import { Colors } from '../../static';

const Line = styled.span`
  width: ${({ width }: { width: number }) => width}px;
  height: 2px;
  background: ${({ color = Colors.menuColor }) => color};
`;

const Arrow = styled.i`
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-bottom: 0;
  border-left: 0;

  &.left {
    margin-left: -10px;
    transform: rotate(45deg);
  }

  &.right {
    margin-right: -10px;
    transform: rotate(225deg);
  }

  &.upRight {
    transform: rotate(30deg);
  }
`;

const Container = styled.section`
  height: 20px;
  display: flex;
  align-items: center;
`;

type ArrowProps = {
  width?: number;
  color?: string;
  style?: React.CSSProperties;
};
export const LongLeftArrow = ({ width = 90, color }: ArrowProps) => (
  <Container>
    <Line width={width} />
    <Arrow className='left' color={color} />
  </Container>
);

export const LongRightArrow = ({ width = 90, color }: ArrowProps) => (
  <Container>
    <Arrow className='right' />
    <Line width={width} color={color} />
  </Container>
);

export const ShortUpRightArrow = ({ width = 15, style = {} }: ArrowProps) => (
  <Container style={{ ...style, transform: 'rotate(320deg)' }}>
    <Line width={width} />
    <Arrow className='left' />
  </Container>
);
