import * as d3 from 'd3';
import * as React from 'react';
import { DuplicatePaymentsKpiData } from '../../../services/types/results';
import { Colors } from '../../../static';

const margin = { top: 20, right: 60, bottom: 30, left: 60 };
const height = 350;
const ticks = 5;
const maxBarWidth = 70;

type DataAnalyzedProps = {
  data: DuplicatePaymentsKpiData[];
  input: 'inputAmounts' | 'outputAmounts';
  output: 'inputTransactions' | 'issues';
  inputMessage: string;
  outputMessage: string;
};

export const BarChart = ({
  data,
  input,
  output,
  inputMessage,
  outputMessage,
}: DataAnalyzedProps) => {
  const svgRef = React.useRef<SVGSVGElement | null>(null);
  const tooltipRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    updateBarChart(svgRef, data, input, output, tooltipRef);
  }, [input, data, output]);

  return (
    <section className='dataAnalyzedChart'>
      <section className='tooltip' ref={tooltipRef}></section>
      <svg
        width='100%'
        height={height + margin.top + margin.bottom}
        ref={svgRef}
        style={{ overflow: 'visible' }}
      />

      <section className='legend'>
        <span className='legendBar'>
          <section className='_bar' style={{ backgroundColor: Colors.blue }}></section>
          <span>{inputMessage}</span>
        </span>
        <span className='legendBar'>
          <section className='_bar' style={{ backgroundColor: Colors.grey }}></section>
          <span>{outputMessage}</span>
        </span>
      </section>
    </section>
  );
};

function updateBarChart(
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
  data: DuplicatePaymentsKpiData[],
  input: string,
  output: string,
  tooltipRef: React.MutableRefObject<HTMLDivElement | null>
) {
  if (svgRef.current && data && input && output) {
    const tooltip = d3.select(tooltipRef.current);
    const width = svgRef.current.clientWidth;
    const svg = d3.select(svgRef.current);
    const scaleWidth = width - margin.right - margin.left;
    const maxAmounts = d3.max(data, (data: any) => data[input]) ?? 0;
    const maxTransactions = d3.max(data, (data: any) => data[output]) ?? 0;

    const yScale = d3.scaleLinear().domain([0, maxAmounts]).range([height, 0]);
    const yScaleRight = d3.scaleLinear().domain([0, maxTransactions]).range([height, 0]);

    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.year))
      .range([0, scaleWidth])
      .padding(0.5);
    const bandwidth = xScale.bandwidth();
    const dataRenderProps = { data, xScale, yScaleRight, output, yScale, input, bandwidth };
    const dataToRender = getDataToRender(dataRenderProps);

    svg.select('g').remove();
    const g = svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);

    g.append('g')
      .attr('class', 'axis-x')
      .call(d3.axisBottom(xScale).tickSize(15))
      .attr('transform', `translate(0, ${height})`)
      .selectAll('text')
      .attr('dx', '-1.2em')
      .attr('dy', '0em')
      .attr('font-size', 16)
      .attr('font-weight', 600)
      .attr('transform', 'rotate(-35)')
      .attr('color', Colors.menuColor);

    g.append('g')
      .attr('class', 'axis-y-left')
      .call(
        d3
          .axisLeft(yScale)
          .ticks(ticks)
          .tickPadding(0.5)
          .tickFormat((value: any) => format(value))
      )
      .selectAll('.tick line')
      .attr('x2', scaleWidth);

    g.append('g')
      .attr('class', 'axis-y-right')
      .call(
        d3
          .axisRight(yScaleRight)
          .ticks(ticks)
          .tickFormat((value: any) => format(value))
      )
      .attr('transform', `translate(${width - margin.left - margin.right}, ${0})`)
      .selectAll('.tick line') // hide the small lines for right axes
      .attr('x2', 0);

    const bar = g.selectAll('rect').data(dataToRender).enter().append('g');
    const text = g.append('text').attr('class', 'text');
    const line = d3
      .line(
        (d: any) => d.xLine,
        (d: any) => d.yLine
      )
      .curve(d3.curveMonotoneX);

    bar
      .append('rect')
      .attr('class', 'data-rect')
      .attr('x', (data: any) => data.xRect)
      .attr('y', (data: any) => data.yRect)
      .attr('width', Math.min(bandwidth, maxBarWidth))
      .attr('height', (data: any) => height - data.yRect)
      .attr('fill', Colors.blue)
      .on('mouseover', function (_, data) {
        text
          .attr('x', data.xText)
          .attr('y', data.yText)
          .text(d3.format(',')(data.amounts))
          .style('opacity', 1);
        d3.select(this).style('fill-opacity', 0.8);
      })
      .on('mouseout', function () {
        d3.select(this).style('fill-opacity', 1);
        text.style('opacity', 0);
      });

    bar.append('path').attr('class', 'line').attr('d', line(dataToRender));
    bar
      .append('g')
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', data => data.cx)
      .attr('cy', data => data.cy)
      .attr('r', data => data.r)
      .on('mouseover', function (event: MouseEvent, data: any) {
        d3.select(this).transition().duration(100).attr('r', 8);
        tooltip.transition().duration(100).style('display', 'block');
        tooltip
          .html(d3.format(',')(data.transactions))
          .style('left', `${event.clientX - 15}px`)
          .style('top', `${event.clientY - 40}px`);
      })
      .on('mouseout', function () {
        d3.select(this).transition().duration(300).attr('r', 5);
        tooltip.transition().duration(300).style('display', 'none');
      });
  }
}

type DataRenderProps = {
  data: DuplicatePaymentsKpiData[];
  xScale: d3.ScaleBand<string>;
  yScaleRight: d3.ScaleLinear<number, number, never>;
  output: string;
  yScale: d3.ScaleLinear<number, number, never>;
  input: string;
  bandwidth: number;
};

function getDataToRender(props: DataRenderProps) {
  const { data, xScale, yScaleRight, output, yScale, input, bandwidth } = props;

  return data.map((d: any) => {
    const x = xScale(d.year) || 0;
    const yTrans = yScaleRight(d[output]);
    const yAmounts = yScale(d[input]);
    const middleOnX = x + bandwidth / 2;
    const correction = bandwidth <= maxBarWidth ? 0 : (bandwidth - maxBarWidth) / 2;

    return {
      amounts: d[input] > 0 ? d[input] : 0,
      transactions: d[output] > 0 ? d[output] : 0,
      year: d.year,
      xLine: middleOnX >= 0 ? middleOnX : 0,
      yLine: yTrans >= 0 ? yTrans : 0,
      xRect: x + correction >= 0 ? x + correction : 0,
      yRect: yAmounts >= 0 ? yAmounts : 0,
      xText: middleOnX >= 0 ? middleOnX : 0,
      yText: yAmounts - 5 >= 0 ? yAmounts - 5 : 0,
      cx: middleOnX >= 0 ? middleOnX : 0,
      cy: yTrans >= 0 ? yTrans : 0,
      r: 5,
    };
  });
}

function format(value: any): string {
  const denominator = 1_000_000;
  const format = d3.format(',');

  return value >= denominator ? `${format(value / denominator)}M` : format(value);
}
