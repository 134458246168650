import { config } from 'dotenv';
config?.();

const base = process.env.REACT_APP_API_URL;
const languages = `${base}languages/`;
const dataset = `${base}dataset/`;

export const Endpoint = {
  maxFileSize: `${base}setting/maxFileSize`,
  login: `${base}login`,
  logout: `${base}logout`,
  acceptPrivacyPolicy: `${base}acceptPrivacyPolicy`,
  resetPassword: `${base}resetPassword`,
  checkTokenValidity: `${base}checkTokenValidity`,
  requestResetPassword: `${base}requestResetPassword`,
  forgotPassword: `${base}forgotPassword`,
  sendCsvFile: `${base}sendFile`,
  token: `${base}token`,
  getActivities: `${base}getActivities`,
  getActivitiesCSV: `${base}getActivitiesCSV`,
  acccount: {
    getLanguage: `${languages}get`,
    setLangauge: `${languages}set`,
    getAllLanguages: `${languages}getAll`,
    addLanguage: `${languages}add`,
    deleteLanguage: `${languages}delete`,
    saveTranslations: `${languages}save`,
    exportLanguage: `${languages}export`,
    importLanguage: `${languages}importCSV`,
    clients: `${base}clients`,
    roles: `${base}roles`,
  },
  settings: {
    saveEmailPreferences: `${base}receiveEmails`,
    options: `${base}options`,
    clientServices: `${base}clientservices`,
    saveServices: `${base}services`,
    deleteServices: `${base}services/delete`,
    users: `${base}users`,
    systemParameters: `${base}parameters`,
    getMarkdown: `${languages}getHelp`,
    saveMarkdown: `${languages}setHelp`,
    getAllOptionsHelp: `${languages}getActiveHelp`,
    saveAllOptionsHelp: `${languages}setActiveHelp`,
  },
  uploadFile: `${base}uploadFile`,
  datasets: `${base}datasets`,
  checkDatasets: `${dataset}duplicateColumns`,
  previewDataset: `${dataset}preview`,
  getAutoColSDataset: `${dataset}get-col-separator`,
  datasetHeader: `${dataset}header`,
  validateDataset: `${dataset}validate`,
  launchAnalysis: `${dataset}process`,
  deleteDataset: `${dataset}delete`,
  downloadDataset: `${dataset}download`,
  updateDataset: `${dataset}update`,
  setService: `${dataset}service`,
  filesSummary: `${base}filesSummary`,
  results: {
    getClientResultsByService: `${base}results`,
    getLatestResults: `${base}latestResults`,
    getReportsByService: `${base}reports`,
    delete: `${base}result/delete`,
    deleteReport: `${base}report/delete`,
    report: `${base}report`,
    download: `${base}result/download`,
    preview: `${base}result/preview`,
    downloadOutside: `${base}resultdownload`,
    getResultByIdAndToken: `${base}getResultByIdToken`,
    getResultsSummary: `${base}servicesResults`,
    getKPIData: `${base}analysis`,
  },
};

export const ENVIRONMENT = {
  production: 'production',
  development: 'development',
};

export const ENV_MODE = process.env.NODE_ENV;
