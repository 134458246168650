import { ClientService } from './settings';

export enum Status {
  ToValidate = 'toValidate',
  Analyzed = 'Analyzed',
  Validated = 'Validated',
}

export enum AnalysisDirectionMode {
  alreadyModified = 'alreadyModified',
  fromMenuInput = 'fromMenuInput',
}

export interface DatasetFile {
  filePath: string
  fileSize: number
  source: string
  uploadedDate: Date | string
  rows: number
  columns: string[]
  comments: string
  separator: string
  deletedDate: Status | string
  lastServiceUsed?: string;
}

export interface Dataset {
  _id: string;
  name: string;
  updatedAt: Date | string;
  source: string;
  status: Status | string;
  service: string;
  serviceName?: string;
  fileSize: string;
  files: DatasetFile[];
  validation: Validation | null;
  numberOfAnalysis: string;
  rowIndex?: number;
  uploadedDate?: Date | string;
  lastServiceUsed?: string;
  statusForFilter?: string;
}

export interface Step {
  isDone: boolean;
  isActive: boolean;
  Component: any;
  title: string;
  description: string;
}

export interface File {
  id: string;
  name: string;
}

export interface OrganizationOfFile {
  textQuoted: boolean;
  columnSeparator: string;
  decimalSeparator: string;
  dateFormat: string;
}

export interface Validation {
  organizationOfFile: OrganizationOfFile;
  identificationOfColumns: any;
}

export interface AnalysisState {
  service: ClientService;
  dataset: Dataset | null;
  options: Record<string, string> | null;
  validation: Validation | null;
  selectedFiles?: boolean[] | null
}

export interface DatasetFileMenuItem {
  label: string,
  args: any
}