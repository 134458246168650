import * as React from 'react';
import { useDatasets } from '../../hooks';
import { AnalysisState, Step } from '../../services/types/analysis';
import { Steps } from './steps';

interface ContextState {
  steps: Step[];
  state: AnalysisState;
  setState: (state: AnalysisState) => void;
  go: (index: number, state?: Partial<AnalysisState>, recheckIsDone?: boolean) => void;
  goForward: (state: Partial<AnalysisState>, recheckIsDoneFlag: boolean) => void;
  goBackward(): void;
  launched: boolean;
  setLaunched: (value: boolean) => void;
  start: () => void;
}

type AnalysisProviderProps = {
  children: React.ReactNode;
  steps: Step[];
  state: AnalysisState;
};

type State = {
  steps: Step[];
  state: AnalysisState;
  launched: boolean;
};

const AnalysisContext = React.createContext({} as ContextState);
const AnalysisProvider = (props: AnalysisProviderProps) => {
  const { children, steps: initialSteps, state: initialState } = props;
  const { clear } = useDatasets(true);
  const [{ state, steps, launched }, setAnalysisState] = React.useState<State>({
    steps: initialSteps,
    state: initialState,
    launched: false,
  });

  React.useEffect(() => {
    clear();
  }, [clear]);

  const go = (
    newActiveIndex: number,
    state: Partial<AnalysisState> = {},
    recheckIsDone = false
  ) => {
    const newSteps = steps.map((step, stepIndex) => ({
      ...step,
      ...getActiveStatus(stepIndex, newActiveIndex),
      ...(recheckIsDone ? getDoneStatus(stepIndex, newActiveIndex) : {}),
    }));

    setAnalysisState(prevState => ({
      state: { ...prevState.state, ...state },
      steps: newSteps,
      launched,
    }));
  };

  const value: ContextState = {
    launched,
    setLaunched: (launched: boolean) => setAnalysisState(prevState => ({ ...prevState, launched })),
    state,
    steps,
    go,
    goForward(state: Partial<AnalysisState>, recheckIsDone: boolean) {
      const index = getActiveIndex(steps);
      if (index !== steps.length) {
        go(index + 1, state, recheckIsDone);
      }
    },
    goBackward() {
      const index = getActiveIndex(steps);
      if (index !== 0) {
        go(index - 1);
      }
    },
    setState: (state: AnalysisState) =>
      setAnalysisState(prevState => ({
        ...prevState,
        state,
      })),
    start: () => setAnalysisState({ steps: Steps, state: {} as AnalysisState, launched: false }),
  };

  return <AnalysisContext.Provider value={value}>{children}</AnalysisContext.Provider>;
};

const useAnalysis = () => React.useContext(AnalysisContext);

export { useAnalysis, AnalysisProvider };

function getActiveIndex(steps: Step[]): number {
  return steps.findIndex(step => step.isActive);
}

function getActiveStatus(stepIndex: number, newActiveIndex: number): Partial<Step> {
  if (stepIndex < newActiveIndex)
    return {
      isDone: true,
      isActive: false,
    };

  if (stepIndex === newActiveIndex)
    return {
      isActive: true,
    };

  return {
    isActive: false,
  };
}

function getDoneStatus(stepIndex: number, newActiveIndex: number): Partial<Step> {
  if (stepIndex < newActiveIndex)
    return {
      isDone: true,
    };

  return {
    isDone: false,
  };
}

export function _go(newActiveIndex: number) {
  return Steps.map((step, stepIndex) => ({
    ...step,
    ...getActiveStatus(stepIndex, newActiveIndex),
  }));
}
