import styled from 'styled-components';
import { useTranslation } from '../../providers';
import { Colors, Translation } from '../../static';
import { Button } from '.';
import { CloseIcon } from './icons';
import { DatasetFileMenuItem } from '../../services/types/analysis';
import { Slider } from './Slider';
import * as React from 'react';
import { useEffect } from 'react';

export const PopupContainer = styled.section`
  width: 500px;
  padding: 20px;
  border-radius: 20px;
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.menuColor};

  &.error {
    font-size: 12px;
    color: ${Colors.error};
  }

  .btns {
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    .btn {
      width: 80px;
      height: 40px;
      :first-child {
        margin-right: 10px;
      }
    }
  }
  
  .content {
    word-break: break-word;
    margin-bottom: 20px;
    justify-content: left !important;
  }

  .dsf-list {
    width: 100%;
  }
`;

type Props = {
  close: () => void;
  selectedCbk: (selectedIndexes:boolean[]) => void;
  menu: DatasetFileMenuItem[];
};

export const ChooseDatasetFilePopup = ({ selectedCbk, close, menu }: Props) => {
  const { translate } = useTranslation();
  const [selectedIndexes, setSelectedIndexes] = React.useState(menu.map(() => true));
  const [disabledIndexes, setDisabledIndexes] = React.useState(menu.map(() => false));

  const toggleSelected = (dsfIndex:number, value:boolean) => {
    let si = selectedIndexes.slice(0)
    si[dsfIndex] = value
    
    disableIncompatibleFiles(si)
  }

  const disableIncompatibleFiles = (si:boolean[]) => {
    let di = menu.map(() => false)
    let datasetSignature:string = ''
    selectedIndexes.forEach((v:boolean, index:number) => {
      let { file } = menu[index].args
      if(!v) {
        return
      }
      const signature = `${file.separator}-${file.columns.join('')}`
      if(datasetSignature == '') {
        datasetSignature = signature
        return 
      }
      if(datasetSignature != signature) {
        si[index] = false
        di[index] = true
      }
    })

    setSelectedIndexes(si)
    setDisabledIndexes(di)
  }

  const isValidSelection = () => {
    return selectedIndexes.filter((v:boolean) => v).length > 0
  }

  useEffect(() => {
    disableIncompatibleFiles(selectedIndexes.slice(0))
  }, []);

  return (
    <PopupContainer>
      <CloseIcon size={25} onClose={close} className='display' />
      <span className={'content'}>
        {translate(Translation.analysis.upload.chooseDatasetFile)}
      </span>

      <div className="dsf-list">
        {menu.map((mi:any, index:number) => (
          <Slider
            defaultChecked={selectedIndexes[index]}
            disabled={disabledIndexes[index]}
            name={`dsf-${index}`}
            label={mi.label}
            key={index}
            onChange={(value:boolean) => toggleSelected(index, value)}
          />
        ))}
      </div>

      <span className='btns'>
        <Button className='btn' color={Colors.blue}
          onClick={() => {
            selectedCbk(selectedIndexes)
            close()
          }}
          disabled={!isValidSelection()}>
          {translate(Translation.analysis.upload.selectDatasetFiles)}
        </Button>
      </span>
    </PopupContainer>
  );
};
