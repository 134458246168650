import * as React from 'react';
import { useAuth, useTranslation } from '../providers';
import { useServices } from '../providers/services-context';
import { getDatasets } from '../services/analysis';
import { Dataset, Status } from '../services/types/analysis';
import { Translation } from '../static';

const { table } = Translation.inputFiles;
const Cache = (() => {
  const key = 'datasets_keys';

  return {
    cacheDatasets(serviceId: string | null, datasets: Dataset[]) {
      if (serviceId === null) return;
      sessionStorage.setItem(serviceId, JSON.stringify(datasets));
      sessionStorage.setItem(key, `${sessionStorage.getItem(key) || ''},${serviceId}`);
    },
    getCachedDatasets(serviceId: string | null): Dataset[] | null {
      const item = sessionStorage.getItem(String(serviceId));

      return item ? (JSON.parse(item) as Dataset[]) : null;
    },
    clear() {
      sessionStorage
        .getItem(key)
        ?.split(',')
        .forEach((key: string) => {
          sessionStorage.removeItem(key);
        });
      sessionStorage.removeItem(key);
    },
  };
})();

export const useDatasets = (doCache?: boolean) => {
  const { _id: clientId } = useAuth().userInfo?.user?.client ?? {};
  const { services } = useServices();
  const { translate } = useTranslation();

  const cachedDatasets = doCache ? Cache.getCachedDatasets(clientId) : null;
  const [datasets, setDatasets] = React.useState<Dataset[] | null>(cachedDatasets);
  const translations: Record<string, string> = React.useMemo(
    () => ({
      [Status.Analyzed]: translate(table.analyzed),
      [Status.ToValidate]: translate(table.toValidate),
      [Status.Validated]: translate(table.validated),
    }),
    [translate]
  );

  React.useEffect(() => {
    if (clientId && cachedDatasets == null) {
      getDatasets(clientId).then((data: Dataset[]) => {
        const datasets = data.map(data => ({
          ...data,
          name: data.name,
          serviceName: services?.find(service => service._id === data.lastServiceUsed)?.name ?? '-',
        }));

        setDatasets(datasets);

        if (doCache) Cache.cacheDatasets(clientId, datasets);
      });
    }
  }, [clientId, doCache, services, cachedDatasets, translations]);

  const updateCacheRef = React.useRef({
    updateCache: (datasets: Dataset[]) => Cache.cacheDatasets(clientId, datasets),
    getFromCache: (clientId: string) => Cache.getCachedDatasets(clientId),
  });

  return {
    datasets,
    clear: Cache.clear,
    ...updateCacheRef.current,
  };
};
