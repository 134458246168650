import * as React from 'react';
import styled from 'styled-components';
import { useCleanPopup } from '../../hooks/useCleanPopup';
import { useTranslation } from '../../providers';
import {previewDataset, previewDatasetWithColSeparator} from '../../services/analysis';
import { getMarkdownContent, getOnlineHelpOptions } from '../../services/settings';
import { OnlineOptionType } from '../../services/types/settings';
import { Colors, dropShadow, Translation } from '../../static';
import { CloseIcon, FileIcon } from '../common/icons';
import { Markdown } from '../common/Markdown';
import { PreviewButton } from '../common/PreviewButton';
import { RawRendererPopup } from '../common/RawRendererPopup';
import { useAnalysis } from './analysis-context';
import {useValidation} from "./validation/validation-context";
import { getDatasetFilesAsList, datasetHasFiles } from '../../utils';

const { validation } = Translation.analysis;
const fileNameStyle = { paddingLeft: 20 };
type Props = { style?: React.CSSProperties; type: OnlineOptionType };

export const FileHeaderComponent = ({ style, type }: Props) => {
  const [enabled, setEnabled] = React.useState(false);
  const buttonsStyles = {
    display: 'flex',
    justifyContent: enabled ? 'space-between' : 'flex-end',
    width: 330,
  };
  const { translate, state: langState } = useTranslation();
  const { state } = useAnalysis();
  const { show, clean } = useCleanPopup();
  const {columnSeparator} = useValidation();

  React.useEffect(() => {
    getOnlineHelpOptions(state.service._id).then(response => setEnabled(response[type]));
  }, [state.service._id, type]);

  const getSelectedDatasetFilesAsList = () => {
    const selectedFiles = state.selectedFiles ?? []
    const files = state.dataset?.files ?? []
    return getDatasetFilesAsList(
      files.filter((_, index:number) => selectedFiles[index])
    )
  }

  return (
    <section className='border v-header' style={style}>
      <div className='v-title'>{translate(validation.selectedFileTitle)}</div>
      <section className='footer'>
        <section className='v-file'>
          <FileIcon />
          <span style={fileNameStyle}>{state.dataset?.name}</span>
        </section>

        <section style={buttonsStyles}>
          {datasetHasFiles(state.dataset) ? (
            <PreviewButton
              onClick={() => {
                show(<RawRendererPopup callback={(args:any) => previewDatasetWithColSeparator(state.dataset?._id, columnSeparator, args.offset)}
                    menu={getSelectedDatasetFilesAsList()}
                  />);
              }}
              className='backward'
            />
          ) : (
            <PreviewButton
              onClick={() => {
                show(<RawRendererPopup callback={() => previewDatasetWithColSeparator(state.dataset?._id, columnSeparator)} />);
              }}
              className='backward'
            />
          )}

          {enabled ? (
            <PreviewButton
              width={120}
              text={translate(Translation.help)}
              onClick={async () => {
                let content = '';
                try {
                  ({ content } = await getMarkdownContent({
                    type,
                    serviceId: state.service._id,
                    isoCode: langState.language,
                  }));
                } catch {}

                show(
                  <MarkdownContainer>
                    <CloseIcon onClose={() => clean()} />
                    <Markdown markdown={content} />
                  </MarkdownContainer>
                );
              }}
              className='backward'
            />
          ) : null}
        </section>
      </section>
    </section>
  );
};

const MarkdownContainer = styled.section`
  width: 50%;
  height: 80%;
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 10px 10px 20px;
  border-radius: 10px;
  background-color: ${Colors.white};
  filter: ${dropShadow};
  overflow-y: auto;
`;
