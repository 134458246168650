/* eslint-disable no-throw-literal */
import { Endpoint } from '../static';
import { getUserInfo, setUserInfo } from './localStorage';
import axios from 'axios';

type Type = 'json' | 'text' | 'blob';
export const HTTP = {
  Unauthorized: 401,
  Forbidden: 403,
  Success: 200,
};

export const SUCCESS_STATUS = [200, 201];
export const UNAUTH_STATUS = [HTTP.Unauthorized];
export const SERVER_STATUS = [500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511];

const calculationProgress = (progress: number, size: string | null, setProgress?: any) => {
  const fileSize = Number(size) * 1024;
  if (setProgress) setProgress(((100 * progress) / fileSize).toFixed(0));
};

const error = (fn: () => any, error: any): Promise<any> => {
  console.error(error);
  if (UNAUTH_STATUS.includes(error?.response?.status)) return fn();

  throw error;
};

const makeRequest = async <T, R = {}>(
  type: string,
  url: string,
  body?: R,
  contentType: Type = 'json',
  fileSize: string | null = '0',
  setProgress: any = null
): Promise<T> => {
  const { token } = getUserInfo();
  const headers = { Authorization: `Bearer ${token}` };
  let response;

  switch (type) {
    case 'get': {

      response = await axios({
        url,
        method: 'GET',
        responseType: contentType,
        headers,
        withCredentials: true,
        onDownloadProgress: data =>
          calculationProgress(data.loaded ? data.loaded : 0, fileSize, setProgress),
      });
      break;
    }
    case 'post': {
      response = await axios.post(url, body, {
        responseType: contentType,
        headers,
      });
      break;
    }
    case 'delete': {
      response = await axios.delete(url, {
        headers,
      });
      break;
    }
  }

  return response?.data;
};

export const AuthFetch = (() => {
  const getNewToken = async <T>(cbk: () => Promise<T>): Promise<T> => {
    const { refreshToken, ...rest } = getUserInfo();

    try {
      const response = await axios.post(Endpoint.token, {
        token: refreshToken,
        email: rest?.user?.email,
      });

      setUserInfo({
        ...rest,
        ...response.data,
      });

      return cbk();
    } catch (err) {
      throw err;
    }
  };

  return {
    GET: async <T>(
      url: string,
      type: Type = 'json',
      fileSize: string | null = 'O',
      setProgress: void | null = null
    ): Promise<T> => {
      const request = async () => await makeRequest<T>('get', url, {}, type, fileSize, setProgress);

      try {
        return await request();
      } catch (err: any) {
        return error(async () => getNewToken(request), err);
      }
    },
    POST: async <T, R = {}>(url: string, data: R, type: Type = 'json'): Promise<T> => {
      const request = async () => await makeRequest<T>('post', url, data);

      try {
        return await request();
      } catch (err: any) {
        return error(async () => getNewToken(request), err);
      }
    },
    DELETE: async <T>(url: string, options: RequestInit = {}): Promise<T> => {
      const request = async () => await makeRequest<T>('delete', url);

      try {
        return await request();
      } catch (err: any) {
        return error(async () => getNewToken(request), err);
      }
    },
  };
})();
