import {lazy} from 'react';
import {Colors} from '../static';

import {AnalysisDirectionMode, AnalysisState, Dataset, Status, DatasetFile} from '../services/types/analysis';
import {ClientService} from '../services/types/settings';

export const getUniqueValues = (key: string, values: any[] | null) => {
  return !values ? [] : Array.from(new Set(values.map((val: any) => val[key])));
};

const getFormattedValue = (value: number, order: number, prefix: string) =>
  `${(value / order).toFixed(2)} ${prefix}`;

export const getTitle = (page: string): string => `Reveal - ${page}`;
export const getDenominatedSizeValue = (value?: number) => {
  if (!value) return '-';
  const megaByteOrder = 2 ** 10;
  const gigaByteOrder = 2 ** 20;
  const terraByteOrder = 2 ** 30;

  if (value >= terraByteOrder) return getFormattedValue(value, terraByteOrder, 'TB');
  if (value >= gigaByteOrder) return getFormattedValue(value, gigaByteOrder, 'GB');
  if (value >= megaByteOrder) return getFormattedValue(value, megaByteOrder, 'MB');

  return `${value} KB`;
};

export const validateField = (
  message: string,
  target?: (EventTarget & HTMLSelectElement) | null
) => {
  if (target) {
    target.style.border = message ? `1px solid ${Colors.error}` : '';
    target.setCustomValidity(message);
    target.reportValidity();
  }
};

export const delay = (callback: () => void, timeout: any = 0) => {
  const timer = setTimeout(() => {
    callback();
    clearTimeout(timer);
  }, timeout);
};

export const debounce = (() => {
  type Timeout = 5000 | 1000 | 300 | 250 | 200 | 150 | 100 | 50 | 10 | 5 | 0;
  let timer: any;

  // TODO for timeout bigger than 300, and debounce used in multiple places, remove the object.
  return (callback: () => void, timeout: Timeout = 300) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback();
      clearTimeout(timer);
    }, timeout);
  };
})();

export const getExtension = (file?: string): string => {
  let extension = '';
  if (!file?.length) return extension;
  for (let i = file.length - 1; i >= 0; i--) {
    extension = file[i] + extension;
    if (file[i] === '.') return extension;
  }
  return extension.includes('.') ? extension : '';
};

export const formatName = (name: string): string => {
  return name
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(' ')
    .map(s => {
      const [firstLetter, ...rest] = s;
      return `${firstLetter.toUpperCase()}${rest.join('')}`;
    })
    .join(' ');
};

export const reject = (time: number, error: string = '') => {
  return new Promise((_, reject) => {
    const id = setTimeout(() => {
      reject(error);
      clearTimeout(id);
    }, time);
  });
};

export const stopPropagation = (event: any, path: string, nextPath: string) => {
  if (path === nextPath) {
    event.preventDefault();
  }
};

export function deepFreeze<T>(object: T): T {
  Object.freeze(object);

  Object.values(object).map((value: any) => {
    if (typeof value === 'object' && value !== null) {
      return deepFreeze(value);
    }
    return value;
  });

  return object;
}

export function resizeBase64Img(file: any, width: number): Promise<string> {
  const image = new Image();

  image.src = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    image.onload = function () {
      try {
        const { width: w, height: h } = this as any;
        const canvas = document.createElement('canvas');

        canvas.width = width;
        canvas.height = width;

        const ctx = canvas.getContext('2d');

        ctx?.scale(width / w, width / h);
        ctx?.drawImage(image, 0, 0);

        resolve(canvas.toDataURL('image/png'));
      } catch {
        reject('something went wrong');
      }
    };
  });
}

export function preloadLazy(
  dynamicImport: () => any
): React.LazyExoticComponent<React.ComponentType<any>> {
  const promise = dynamicImport();

  const Comp: any = lazy(() => promise);

  return Comp;
}

export function mockFetch(response: any) {
  global.fetch = jest.fn().mockResolvedValue({
    json: () => Promise.resolve(response),
    status: 200,
  });
}

export const getParamsFromQueryString = (url: string): Record<string, string> => {
  if (url?.length) {
    url = url.substring(1);

    if (url?.length) {
      const params = decodeURIComponent(url).split('&'); // ['token=1', 'value=2', 'anyValue=false']

      if (params?.length) {
        return params.reduce((acc: Record<string, string>, next: string) => {
          const [key, value] = next.split('=');

          if (key?.length && value?.length) {
            acc[key] = value;
          }

          return acc;
        }, {});
      }
    }
  }

  return {};
};

export const isExactPath = (url: string, path: string): boolean => {
  const arr = url.split('/');

  return arr.some(string => string === path);
};

export const dateFormatter = (value: any) => {
  if (!value) {
    return '-';
  }

  try {
    const date = new Date(value).toLocaleString();
    let dateString, timeString;

    [dateString, timeString] = date.split(',');
    if (!timeString) {
      [dateString, timeString] = date.split(' ');
    }
    const [hour, minute, rest] = timeString.split(':');
    const meridian = rest[rest.length - 2] + rest[rest.length - 1];

    return (`${dateString} at ${hour}:${minute} ${isNaN(parseInt(meridian)) ? meridian : ''}`).trim();
  } catch {
    return '-';
  }
};

export const dateFormatterWithoutYear = (value: string): string => {
  if (!value) {
    return '';
  }
  const [year, month, restDay] = value.split('-');
  const [day] = restDay.split('T');

  return `${day}/${month}/${year}`;
};

export const trimFileName = (name: string): string => {
  const [, ...rest] = name.split('_');

  return rest.join('_');
};

export const isDeepEqual = <T, R>(firstValue: T, secondValue: R) => {
  return JSON.stringify(firstValue) === JSON.stringify(secondValue);
};

export const formatValueChart = (value: number): number => {
  if (value >= 1_000_000) {
    return value / 1_000_000;
  }
  return value;
};

export const formatValue = (value: number | string) => {
  if (!Number(value)) return value;

  let stringValue = String(value);
  let decimal = '';
  if (!Number.isInteger(value)) {
    const index = stringValue.indexOf('.');
    decimal = stringValue.slice(index, index + 3);
    stringValue = stringValue.slice(0, index);
  }

  let str = '';
  let arr = [];
  for (let i = stringValue.length - 1; i >= 0; i--) {
    if (str.length < 3) {
      str = stringValue[i] + str;
      continue;
    }
    arr.push(str);
    str = stringValue[i];
  }
  arr.push(str);

  const integerPart = arr.reverse().join(' ');
  return decimal ? `${integerPart}${decimal}` : integerPart;
};

export const generateRandom = (min: number, max: number): string =>
  String(Math.floor(Math.random() * (max - min + 1)) + min);

// for testing
export function getByClassName(container: HTMLElement, className: string): HTMLElement {
  return container?.querySelector(`.${className}`) as HTMLElement;
}
export function getAllByClassName(container: HTMLElement, className: string): HTMLElement[] {
  return Array.from(container?.querySelectorAll(`.${className}`));
}

export const flushPromises = () =>
  new Promise(resolve => {
    const timeoutId = setTimeout(() => {
      resolve({});
      clearTimeout(timeoutId);
    });
  });

type State = {
  initialState: AnalysisState;
  steps: Array<{ isDone: boolean; isActive: boolean }>;
};
export function getState(data: Dataset, services: ClientService[], mode: string): State {
  const [service] = services.filter(({ _id }) => _id === data.service);

  const partialData = {
    dataset: data,
    service,
    options: null,
  };
  switch (mode) {
    case AnalysisDirectionMode.alreadyModified:
      switch (data.status) {
        case Status.ToValidate:
          return {
            initialState: {
              ...partialData,
              validation: null,
            },
            steps: [
              { isDone: true, isActive: false },
              { isDone: true, isActive: false },
              { isDone: false, isActive: true },
            ],
          };
        case Status.Validated:
        case Status.Analyzed:
          return {
            initialState: {
              ...partialData,
              validation: data.validation,
            },
            steps: [
              { isDone: true, isActive: false },
              { isDone: true, isActive: false },
              { isDone: true, isActive: false },
              { isDone: false, isActive: true },
            ],
          };
        default:
          throw new Error('This status is not valid');
      }
    case AnalysisDirectionMode.fromMenuInput:
      return {
        initialState: {
          ...partialData,
          validation: data.validation,
        },
        steps: [
          { isDone: true, isActive: false },
          { isDone: true, isActive: false },
          { isDone: true, isActive: false },
          { isDone: false, isActive: true },
        ],
      };
    default:
      throw new Error('This status is not valid');
  }
}

export function getStateFromLastService(data: Dataset, services: ClientService[], mode: string): State {
  const [service] = services.filter(({ _id }) => _id === data.lastServiceUsed);

  const partialData:any = {
    dataset: data,
    service,
    options: null,
  };
  if(datasetHasFiles(data)) {
    partialData.selectedFiles = data.files.map((file: DatasetFile) => file.lastServiceUsed == data.lastServiceUsed)
  }
  switch (mode) {
    case AnalysisDirectionMode.alreadyModified:
      switch (data.status) {
        case Status.ToValidate:
          return {
            initialState: {
              ...partialData,
              validation: null,
            },
            steps: [
              { isDone: true, isActive: false },
              { isDone: true, isActive: false },
              { isDone: false, isActive: true },
            ],
          };
        case Status.Validated:
        case Status.Analyzed:
          return {
            initialState: {
              ...partialData,
              validation: data.validation,
            },
            steps: [
              { isDone: true, isActive: false },
              { isDone: true, isActive: false },
              { isDone: true, isActive: false },
              { isDone: false, isActive: true },
            ],
          };
        default:
          throw new Error('This status is not valid');
      }
    case AnalysisDirectionMode.fromMenuInput:
      return {
        initialState: {
          ...partialData,
          validation: data.validation,
        },
        steps: [
          { isDone: true, isActive: false },
          { isDone: true, isActive: false },
          { isDone: true, isActive: false },
          { isDone: false, isActive: true },
        ],
      };
    default:
      throw new Error('This status is not valid');
  }
}

export const datasetHasFiles = (dataset: Dataset | null) => {
  return dataset && dataset.files && dataset.files.length > 0
}

export const getDatasetFilesAsList = (files: DatasetFile[]) => {
  return files.map((file:DatasetFile, index:number) => {
    return {
      label: `${file.filePath.replace(/^.*(\\|\/|\:)/, '')} ${dateFormatter(file.uploadedDate)}, ${file.columns.length}x${file.rows}`,
      args: { offset: index, file }
    }
  })
}