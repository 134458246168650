import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../static';

type Props = {
  label?: string;
  min: number;
  max: number;
  callback: (min: number, max: number) => void;
  style?: React.CSSProperties;
  data: number[] | string[];
};

const Container = styled.section`
  display: flex;

  label {
    font-size: 13px;
    padding-right: 15px;
  }

  .range-results {
    width: 100%;
    font-weight: 500;
    padding: 10px 5px 0 5px;
    display: flex;
    justify-content: space-between;
  }

  .range-content {
    padding: 10px 0;
    width: 50%;
    position: relative;

    input[type='range'] {
      appearance: none;
      width: 100%;
      outline: none;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      background-color: transparent;
      pointer-events: none;
    }

    .slider-track {
      width: 100%;
      height: 5px;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      border-radius: 5px;
    }

    input[type='range']::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      height: 5px;
    }
    input[type='range']::-moz-range-track {
      -moz-appearance: none;
      height: 5px;
    }
    input[type='range']::-ms-track {
      appearance: none;
      height: 5px;
    }
    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 1.7em;
      width: 1.7em;
      background-color: ${Colors.blue};
      cursor: pointer;
      margin-top: -9px;
      pointer-events: auto;
      border-radius: 50%;
    }
    input[type='range']::-moz-range-thumb {
      -webkit-appearance: none;
      height: 1.7em;
      width: 1.7em;
      cursor: pointer;
      border-radius: 50%;
      background-color: ${Colors.blue};
      pointer-events: auto;
    }
    input[type='range']::-ms-thumb {
      appearance: none;
      height: 1.7em;
      width: 1.7em;
      cursor: pointer;
      border-radius: 50%;
      background-color: ${Colors.blue};
      pointer-events: auto;
    }
    input[type='range']:active::-webkit-slider-thumb {
      background-color: ${Colors.white};
      border: 3px solid ${Colors.blue};
    }
  }
`;

const minGap = 1;
export const RangeField = ({ min, max, callback, style, label, data }: Props) => {
  const slider1Ref = React.useRef<HTMLInputElement | null>(null);
  const slider2Ref = React.useRef<HTMLInputElement | null>(null);
  const sliderTrackRef = React.useRef<HTMLDivElement | null>(null);
  const val1Ref = React.useRef<HTMLSpanElement | null>(null);
  const val2Ref = React.useRef<HTMLSpanElement | null>(null);

  React.useEffect(() => {
    if (slider1Ref.current && slider2Ref.current && sliderTrackRef.current && max) {
      fillColor(slider1Ref.current, slider2Ref.current, sliderTrackRef.current, max);
    }
  }, [max]);

  return (
    <Container>
      <label>{label}</label>
      <section>
        <section className='range-content' style={style}>
          <div className='range-container'>
            <div className='slider-track' ref={sliderTrackRef}></div>
            <input
              ref={slider1Ref}
              type='range'
              min={min}
              max={max}
              defaultValue={min}
              onInput={(event: any) => {
                if (slider2Ref.current && sliderTrackRef.current && val1Ref.current) {
                  if (parseInt(slider2Ref.current.value) - parseInt(event.target.value) >= minGap)
                    callback(+event.target.value, +slider2Ref.current.value);

                  if (parseInt(slider2Ref.current.value) - parseInt(event.target.value) <= minGap)
                    event.target.value = parseInt(slider2Ref.current.value) - minGap;

                  val1Ref.current.textContent = String(data[+event.target.value]);
                  fillColor(event.target, slider2Ref.current, sliderTrackRef.current, max);
                }
              }}
            />

            <input
              ref={slider2Ref}
              type='range'
              min={min}
              max={max}
              defaultValue={max}
              onInput={(event: any) => {
                if (slider1Ref.current && sliderTrackRef.current && val2Ref.current) {
                  if (parseInt(event.target.value) - parseInt(slider1Ref.current.value) >= minGap)
                    callback(+slider1Ref.current.value, +event.target.value);

                  if (parseInt(event.target.value) - parseInt(slider1Ref.current.value) <= minGap)
                    event.target.value = parseInt(slider1Ref.current.value) + minGap;

                  val2Ref.current.textContent = String(data[+event.target.value - 1]);
                  fillColor(slider1Ref.current, event.target, sliderTrackRef.current, max);
                }
              }}
            />
          </div>
        </section>

        <section className='range-results'>
          <span ref={val1Ref}>{data[min]}</span>
          <span ref={val2Ref}>{data[max - 1]}</span>
        </section>
      </section>
    </Container>
  );
};

function fillColor(
  sliderOne: HTMLInputElement,
  sliderTwo: HTMLInputElement,
  sliderTrack: HTMLDivElement,
  sliderMaxValue: number
) {
  const percent1 = (+sliderOne.value / sliderMaxValue) * 100;
  const percent2 = (+sliderTwo.value / sliderMaxValue) * 100;
  sliderTrack.style.background = `linear-gradient(to right,
    ${Colors.disabled} ${percent1}%,
    ${Colors.blue} ${percent1}%,
    ${Colors.blue} ${percent2}%,
    ${Colors.disabled} ${percent2}%)`;
}
