import * as React from 'react';
import styled from 'styled-components';
import { useCleanPopup } from '../../hooks/useCleanPopup';
import { useAuth, useTranslation } from '../../providers';
import { useServices } from '../../providers/services-context';
import { getResultsSummary } from '../../services/results';
import { ResultSummary } from '../../services/types/results';
import { TIMERs, Translation } from '../../static';
import { Header, Loader, MainContainer } from '../common';
import { ResultTile } from './ResultTile';

const Container = styled(MainContainer)`
  display: grid;
  row-gap: 30px;
  overflow-y: auto;
  padding: 20px 0 20px 60px;

  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1601px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const AllResultsComponent = () => {
  const { translate } = useTranslation();

  return (
    <>
      <Header
        children={null}
        title={translate(Translation.analysis.summary.results)}
        subtitle={translate(Translation.results.allResults)}
      />
      <Container>
        <ResultsSummary />
      </Container>
    </>
  );
};

const ResultsSummary = () => {
  const { activeServices } = useServices();
  const {
    userInfo: { user },
    isAdmin,
    isManager,
  } = useAuth();
  const { show, clean } = useCleanPopup();
  const { translate } = useTranslation();
  const [data, setData] = React.useState<ResultSummary[] | null>(null);

  React.useEffect(() => {
    const payload = isAdmin || isManager ? { clientId: user.client._id } : { userId: user._id };

    getResultsSummary(payload)
      .then(data => setData(data))
      .catch(() => {
        show(translate(Translation.results.failedToGetResults));
        clean(TIMERs.threeSeconds);
      });
  }, [clean, isAdmin, isManager, show, translate, user._id, user.client._id]);

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      {activeServices?.map((service, index) => {
        const result = data.find(result => service._id === result.serviceId && service.enabled);
        if (!result) return null;

        return <ResultTile result={result} key={index} service={service} />;
      })}
    </>
  );
};
