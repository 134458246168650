import { Colors } from '../../../static';

type Props = {
  style?: React.CSSProperties;
  onClick?: () => void;
  size?: number;
  color?: string;
  disabled?: boolean;
};

export const EditIcon = ({
  style = {},
  onClick,
  size = 25,
  color = Colors.menuColor,
  disabled = false,
}: Props) => {

  const disabledStyle = disabled
    ? { cursor: 'not-allowed', opacity: 0.6 }
    : { cursor: 'pointer', opacity: 1 };

  return (
    <svg viewBox='0 0 30 30' style={{ height: size, ...Object.assign({}, style, disabledStyle) }} onClick={onClick}>
      <g transform='translate(0,30) scale(0.005859,-0.005859)' fill={color}>
        <path
          d='M4350 5099 c-51 -20 -142 -108 -1227 -1192 -645 -644 -1177 -1183
  -1182 -1197 -17 -42 -151 -732 -151 -775 0 -78 67 -145 145 -145 43 0 733 134
  775 151 14 5 553 537 1197 1182 1135 1136 1171 1174 1193 1232 18 50 21 75 18
  150 -6 135 -25 164 -237 376 -214 215 -240 231 -381 236 -78 3 -105 -1 -150
  -18z m307 -441 c97 -97 153 -160 153 -173 0 -12 -36 -56 -90 -110 l-90 -90
  -172 173 -173 172 90 90 c53 54 98 90 110 90 12 0 77 -57 172 -152z m-1167
  -1513 l-920 -921 -212 -42 c-116 -23 -213 -40 -215 -38 -2 2 17 99 41 216 l44
  213 919 919 918 918 172 -172 173 -173 -920 -920z'
        />
        <path
          d='M379 4277 c-180 -61 -309 -196 -363 -376 -14 -49 -16 -220 -16 -1752
  0 -1658 0 -1698 20 -1760 53 -174 184 -306 359 -366 l66 -22 1701 -1 c1535 0
  1706 2 1755 16 185 55 322 191 382 379 l22 70 0 1165 c0 1147 0 1166 -20 1198
  -56 93 -201 99 -263 11 -16 -22 -17 -115 -22 -1184 l-5 -1160 -27 -50 c-31
  -59 -57 -84 -118 -116 l-45 -24 -1655 0 -1655 0 -50 27 c-59 31 -84 57 -116
  118 l-24 45 0 1655 0 1655 27 50 c31 59 57 84 118 116 l45 24 1160 5 c1069 5
  1162 6 1184 22 42 30 61 69 61 128 0 62 -20 100 -66 131 -28 18 -70 19 -1209
  19 l-1180 -1 -66 -22z'
        />
      </g>
    </svg>
  );
}
