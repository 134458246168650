import styled from 'styled-components';
import { Colors } from '../../../static';

export const DataCellRendererContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.orange};
  font-weight: 500;
`;
