import { Suspense } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from './components/common';
import ResetPasswordComponent from './components/resetPassword/ResetPassword';
import { AuthProvider, PopupProvider, useAuth } from './providers';
import { Routes } from './static';
import { getParamsFromQueryString, isExactPath, preloadLazy } from './utils';
import {TooltipContextProvider} from "./providers/TooltipContextProvider";

const Container = styled.section`
  height: 100%;
  width: 100%;
`;

const DownloadResultComponent = preloadLazy(
  () => import('./components/results/download/DownloadResults')
);
const LoginComponent = preloadLazy(() => import('./components/login/Login'));
const SecuredApplication = preloadLazy(() => import('./components/routing/Application'));

function App() {
  return (
    <Container>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <AuthProvider>
            <TooltipContextProvider>
              <PopupProvider>
                <Application />
              </PopupProvider>
            </TooltipContextProvider>
          </AuthProvider>
        </BrowserRouter>
      </Suspense>
    </Container>
  );
}

function Application() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const isResetPasswordPath = isExactPath(location.pathname, Routes.ResetPassword.substring(1));

  if (isResetPasswordPath) {
    const { token } = getParamsFromQueryString(location.search);

    return <ResetPasswordComponent token={token} />;
  }

  const isDownloadResultPath = isExactPath(location.pathname, Routes.DownloadResult.substring(1));
  if (isDownloadResultPath) {
    const { token, resultId } = getParamsFromQueryString(location.search);

    return <DownloadResultComponent token={token} resultId={resultId} />;
  }

  return isAuthenticated ? <SecuredApplication /> : <LoginComponent />;
}

export default App;
