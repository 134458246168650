import * as React from 'react';
import { useAuth } from '.';
import { getServices } from '../services/settings';
import { ClientService } from '../services/types/settings';

interface State {
  services: ClientService[];
  currentService?: ClientService;
  activeServices: ClientService[];
}

interface Context extends State {
  setServices: (services: ClientService[], currentService?: ClientService) => void;
}

const ServicesContext = React.createContext<Context>({} as Context);
const ServicesProvider = ({ children }: { children: React.ReactNode }) => {
  const { _id: clientId } = useAuth().userInfo?.user?.client ?? { _id: null };
  const [state, setState] = React.useState<State>({
    services: [],
    activeServices: [],
    currentService: undefined,
  });

  React.useEffect(() => {
    if (clientId) {
      getServices(clientId).then((clientServices: ClientService[]) => {
        const { activeServices, inactiveServices } = getSortedServices(clientServices, clientId);

        setState({
          services: [...activeServices, ...inactiveServices],
          activeServices,
          currentService: [...activeServices, ...inactiveServices][0],
        });
      });
    }
  }, [clientId]);

  const { current } = React.useRef({
    setServices: (services: ClientService[], currentService?: ClientService) => {
      setState(() => ({
        services,
        activeServices: services.filter(({ display }) => display),
        currentService,
      }));
    },
  });

  const value = {
    ...state,
    setServices: current.setServices,
  };

  return <ServicesContext.Provider value={value}>{children}</ServicesContext.Provider>;
};

function isValidService(service: ClientService): boolean {
  return Boolean(service._id && service.name);
}
type Label = keyof ClientService;
export function getSortedServices(services: ClientService[], id?: string, text: Label = 'display') {
  if (!services?.length) return { activeServices: [], inactiveServices: [] };

  const { activeServices, inactiveServices } = services.reduce(
    (acc, service: ClientService) => {
      if (isValidService(service)) {
        service.key = service.key ?? id + service._id;
        if (service[text]) acc.activeServices.push(service);
        else acc.inactiveServices.push(service);
      }

      return acc;
    },
    { activeServices: [] as ClientService[], inactiveServices: [] as ClientService[] }
  );

  activeServices.sort((a, b) => a.order - b.order);
  inactiveServices.sort((a, b) => a.order - b.order);

  return { activeServices, inactiveServices };
}

const useServices = () => React.useContext(ServicesContext);

export { useServices, ServicesProvider };
