import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth, useTranslation } from '../../providers';
import { Translation } from '../../static';
import { Header, MainContainer, Navigation } from '../common';
import { SecuredRoute } from '../routing';
import { SettingsNavRoutes } from '../routing/NavItems';

const { header } = Translation.settings;
const commonProps = { className: 'link', activeClassName: 'active' };

export const SettingsComponent = () => {
  const { translate } = useTranslation();
  const { userInfo } = useAuth();
  const [views, setViews] = useState([]);
  const routes = SettingsNavRoutes[userInfo.user.role.type];

  useEffect(() => {
    (async function () {
      const promises = routes.map(async value => {
        const View: any = await value.Component();

        return <View />;
      });

      Promise.all(promises).then(resp => {
        setViews(
          resp.map((c, i) => ({
            Component: c,
            key: routes[i].key,
            name: routes[i].name,
            path: routes[i].path,
          })) as any
        );
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header flex subtitle={translate(header.subtitle)} title={translate(header.title)}>
        <Navigation>
          {routes?.map(({ key, path }) => (
            <NavLink key={key} to={path as string} {...commonProps} children={translate(key)} />
          ))}
        </Navigation>
      </Header>

      <MainContainer scrollY>
        {views?.map(({ path, key, name, Component }) => (
          <SecuredRoute path={path} key={key} name={name} children={Component} />
        ))}
      </MainContainer>
    </>
  );
};
