import styled from 'styled-components';
import { useTranslation } from '../../providers';
import { Colors, Translation } from '../../static';
import { Button } from '.';

export const DeletionPopupContainer = styled.section`
  width: 500px;
  padding: 20px;
  border-radius: 20px;
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.menuColor};

  &.error {
    font-size: 12px;
    color: ${Colors.error};
  }

  .btns {
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    .btn {
      width: 80px;
      height: 40px;
      :first-child {
        margin-right: 10px;
      }
    }
  }
  
  .content {
    word-break: break-word;
    margin-bottom: 20px;
    justify-content: left !important;
  }
`;

type DeleteProps = {
  close: () => void;
  yesCbk: () => void;
  text: string;
};

const { deleteLanguage } = Translation.account;

export const DeletePopupComponent = ({ yesCbk, close, text }: DeleteProps) => {
  const { translate } = useTranslation();

  return (
    <DeletionPopupContainer>
      <span className={'content'}>{text}</span>

      <span className='btns'>
        <Button className='btn' color={Colors.blue} onClick={() => close()}>
          {translate(deleteLanguage.noBtn)}
        </Button>

        <Button className='btn' color={Colors.blue} onClick={() => yesCbk()}>
          {translate(deleteLanguage.yesBtn)}
        </Button>
      </span>
    </DeletionPopupContainer>
  );
};
