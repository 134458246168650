import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../providers';
import { RangeDate } from '../../services/types/results';
import { ClientService, ListType, OnlineOptionType, Option } from '../../services/types/settings';
import { Colors, Translation } from '../../static';
import {
  DateField,
  Form,
  MessageComponent,
  RangeDateField,
  SelectField,
  TextField,
} from '../common';
import { Slider } from '../common/Slider';
import { useAnalysis } from './analysis-context';
import { FileHeaderComponent } from './AnalysisFileHeader';
import { AnalysisFooterComponent } from './AnalysisFooter';

const Container = styled.section`
  height: 100%;
  padding: 20px;
  color: ${Colors.menuColor};

  form {
    overflow-y: scroll;
  }

  .levelInfo {
    align-self: flex-start;
    font-size: 14px;
    padding-top: 14px;
  }

  .row {
    display: grid;
    grid-template-columns: 42% minmax(0, 1fr);
    height: 60px;
    align-items: center;
  }

  label {
    font-size: 14px;
  }
`;

const { validation, options } = Translation.analysis;
const { settings } = Translation;
export const AnalysisOptionsComponent = () => {
  const { translate } = useTranslation();
  const { goBackward, goForward, state } = useAnalysis();
  const btnRef = React.useRef<HTMLButtonElement>(null);

  const formNames = state.service?.options?.reduce((acc: Record<string, string>, { name }) => {
    return { ...acc, [name]: name };
  }, {});

  const onSubmit = (options: any) => {
    state.service?.options.forEach(({ name, type }) => {
      if (type === ListType.RangeDate) {
        options[name] = getValueFromRangeDate(options[name]);
      }
    });

    options = Object.entries(options).reduce(
      (acc: Record<string, any>, [key, value]: [string, any]) => {
        return { ...acc, [key]: value?.length === 0 ? null : value };
      },
      {}
    );

    goForward({ options }, false);
  };

  return (
    <Container className='validation'>
      <FileHeaderComponent type={OnlineOptionType.Options} />

      <Form
        name='options'
        formNames={formNames}
        onSubmit={onSubmit}
        className='border v-body options'
      >
        <section className='v-title' style={{ alignSelf: 'flex-start' }}>
          {translate(options.optionsTitle)}
        </section>
        <section className='levelInfo'>{translate(getServiceLevelInfoKey(state.service))}</section>

        <section className='v-form' style={{ padding: '10px', width: '85%' }}>
          {state.service?.options?.length > 0 ? (
            state.service.options.map((option, index) => (
              <section className='row' key={index}>
                <label>{option.title}</label>
                {getFielByType(option, state.service.level, state.options)}
              </section>
            ))
          ) : (
            <MessageComponent
              color={Colors.menuColor}
              children={translate(options.noOptions)}
              style={{ marginTop: 50, fontSize: 14 }}
            />
          )}
        </section>
        <button style={{ display: 'none' }} ref={btnRef}></button>
      </Form>

      <AnalysisFooterComponent
        className='v-footer'
        btnText={translate(validation.validate)}
        goBackward={goBackward}
        forwardDisabled={false}
        goForward={() => btnRef.current?.click()}
      />
    </Container>
  );
};

function getFielByType(
  option: Option,
  serviceLevel: string,
  savedOption: Record<string, string> | null
): React.ReactNode {
  const levelOfSubscription = option.serviceLevels.find(({ _id }) => _id === serviceLevel);
  const enabledOptionSubscription = option.serviceLevels.filter(({ enabled }) => enabled);

  const disabled = !enabledOptionSubscription.some(
    ({ priority }) => (levelOfSubscription?.priority ?? -1) >= priority
  );

  const name = option.name;
  const optionName: any = savedOption?.[name];

  switch (option.type) {
    case ListType.Slider: {
      const defaultChecked = Boolean(optionName ?? option.defaultValue);

      return <Slider name={name} defaultChecked={defaultChecked} disabled={disabled} />;
    }

    case ListType.List: {
      const defaultValue =
        optionName ?? option.optionList.filter(({ enabled }) => enabled)[0]?.value ?? '';
      return (
        <SelectField
          disabled={disabled}
          values={option.optionList.map(oList => ({
            label: oList.name,
            value: oList.value,
          }))}
          defaultValue={defaultValue}
          name={name}
        />
      );
    }

    case ListType.Value:
    case ListType.Text: {
      const defaultValue = optionName ?? option.defaultValue ?? '';
      return <TextField height={40} defaultValue={defaultValue} name={name} readonly={disabled} />;
    }
    case ListType.RangeDate: {
      const { to, from } = optionName || { to: '', from: '' };

      return (
        <RangeDateField
          name={name}
          disabled={disabled}
          toDefaultValue={to}
          fromDefaultValue={from}
        />
      );
    }
    case ListType.Date:
      return <DateField name={name} disabled={disabled} defaultValue={optionName ?? ''} />;

    default:
      return null;
  }
}

function getValueFromRangeDate(rangeDate: string): RangeDate {
  if (!!rangeDate) {
    const value = JSON.parse(rangeDate);
    return {
      from: value.from?.length === 0 ? null : value.from,
      to: value.to?.length === 0 ? null : value.to,
    };
  }
  return { from: null, to: null };
}

function getServiceLevelInfoKey(service: ClientService): string {
  const key = service.levels.find(({ _id }) => _id === service.level)?.name;

  if (key === settings.options.serviceLevelBasic) return options.serviceLevelBasicInfo;
  if (key === settings.options.serviceLevelAdvanced) return options.serviceLevelAdvancedInfo;
  if (key === settings.options.serviceLevelPremium) return options.serviceLevelPremiumInfo;
  return '';
}
