import { Fragment } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from '../../providers';
import { Routes, Translation } from '../../static';
import { CloseIcon } from '../common/icons';
import { useAnalysis } from './analysis-context';

const Header = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 7%;
`;

const {
  analysis: { header },
} = Translation;
const keys = [
  header.service.service,
  header.upload.upload,
  header.validation.validation,
  header.options.options,
  header.submission.submission,
];

export const AnalysisHeaderComponent = () => {
  const { translate } = useTranslation();
  const history = useHistory();
  const { steps, state, go, launched } = useAnalysis();
  const [activeStep] = steps.filter(step => step.isActive);

  return (
    <Header>
      <section className='header'>
        <section className='wizzard-bar'>
          {steps.map((step, index) => {
            const isEnabled = steps[index - 1]?.isDone || index === 0;
            const className =
              step.isActive && !launched ? 'active' : step.isDone || launched ? 'done' : '';

            return (
              <Fragment key={index}>
                <section
                  className={`option ${className}`}
                  style={{ cursor: isEnabled && !launched ? 'pointer' : 'not-allowed' }}
                  onClick={() => {
                    if (isEnabled && !launched) go(index);
                  }}
                >
                  {translate(keys[index])}
                </section>
                {index === steps.length - 1 ? null : <i className='dot' />}
              </Fragment>
            );
          })}
        </section>

        <section className='header-description'>
          <section className='header'>
            {state?.service?.name || translate(Translation.menu.analysis)}
          </section>
          <section className='title'>{translate(activeStep?.title)}</section>
          <section className='description'>{translate(activeStep?.description)}</section>
        </section>
      </section>

      <CloseIcon
        onClose={() => history.push(Routes.Overview)}
        style={{ margin: '10px 35px', alignSelf: 'flex-start' }}
      />
    </Header>
  );
};
