import { RowClickedEvent } from 'ag-grid-community';
import { AgGridColumn, AgGridReact, AgGridReactProps } from 'ag-grid-react';
import * as React from 'react';
import { useAuth, useTranslation } from '../../providers';
import {
  getClientReportsByService,
  getClientResultsByService,
  getResultsWithService,
} from '../../services/results';
import { Result, StatusCode } from '../../services/types/results';
import { Service } from '../../services/types/settings';
import { tableShadow, Translation } from '../../static';
import { DateCol } from '../common/ag-grid/date-column';
import { EnumFilter } from '../common/ag-grid/enum-filter';
import { getStatusRenderer, ManageResultCellRenderer } from '../common/cell-renderers';
import { useResults, useResultsDispatch } from './results-context';

const gridColProps = {
  flex: 1,
  sortable: true,
  filter: true,
  resizable: true,
};
const manageCellMinWidth = 100;
const tableStyle = { height: '100%', width: '100%', filter: tableShadow };
const { table } = Translation.inputFiles;

export const ResultsTable = () => {
  const GridRef = React.useRef<AgGridReactProps>(null);
  const { userInfo, isManager, isAdmin } = useAuth();
  const { _id: clientId } = userInfo?.user?.client ?? {};
  const { service } = useResults();
  const dispatch = useResultsDispatch();
  const { translate } = useTranslation();
  const translations = React.useMemo(
    () => ({
      [StatusCode.Done]: translate(table.done),
      [StatusCode.Error]: translate(table.error),
      [StatusCode.InLaunchProgress]: translate(table.inProgress),
    }),
    [translate]
  );

  React.useEffect(() => {
    GridRef.current?.api?.showLoadingOverlay();
    (async () => {
      if (GridRef.current) {
        const payload = isManager || isAdmin ? { clientId } : { userId: userInfo.user._id };
        try {
          const results = await (service?.isReport
            ? getClientReportsByService({ clientId, serviceId: service?._id })
            : getClientResultsByService({ ...payload, serviceId: service?._id }));
          const rowData = getResultsWithService([service as Service], results, translations);

          GridRef.current?.api?.setRowData(rowData);
        } catch (err) {
          GridRef.current?.api?.setRowData([]);
        }
      }
    })();
  }, [clientId, isManager, isAdmin, service, service?._id, userInfo.user._id, translations]);

  const getRowStyle = ({ data }: { data: Result }) => {
    return data.statusCode === translations[StatusCode.Done]
      ? { cursor: 'pointer' }
      : { cursor: 'not-allowed' };
  };

  return (
    <section className='ag-theme-alpine ag-grid' style={tableStyle}>
      <AgGridReact
        animateRows
        getRowStyle={getRowStyle}
        ref={GridRef as any}
        tooltipShowDelay={0}
        onRowClicked={({ data: result, event }: RowClickedEvent) => {
          if ((event?.target as any)?.className?.includes('delete-action')) return;

          if (result.statusCode === translations[StatusCode.Done])
            dispatch({ type: 'setResult', result });
        }}
        frameworkComponents={{
          managedataCellRenderer: ManageResultCellRenderer,
          statusCellRenderer: getStatusRenderer('Code'),
        }}
      >
        <AgGridColumn
          headerName={translate(Translation.table.results.resultName)}
          valueGetter={({ data }) => data.datasetName ?? data.name}
          {...gridColProps}
          flex={2}
        />
        {DateCol({
          ...gridColProps,
          field: 'updatedAt',
          headerName: translate(Translation.table.results.date),
          cellClass: 'date',
        })}

        <AgGridColumn
          headerName={translate(Translation.table.results.service)}
          field='service'
          {...gridColProps}
          filter={false}
        />
        <AgGridColumn
          {...gridColProps}
          headerName={translate(Translation.table.status)}
          field='statusCode'
          tooltipField='statusText'
          cellRenderer='statusCellRenderer'
          filterFramework={EnumFilter}
          filterParams={{ values: Object.values(translations) }}
        />
        <AgGridColumn
          cellStyle={{ padding: 0 }}
          cellRenderer='managedataCellRenderer'
          maxWidth={manageCellMinWidth}
        />
      </AgGridReact>
    </section>
  );
};
