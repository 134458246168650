import styled from 'styled-components';
import { Colors } from '../../static/colors';

export const Navigation = styled.section`
  min-width: 280px;
  height: 30%;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;

  & .link {
    text-decoration: none;
    margin-right: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    color: ${Colors.link};
  }

  & .active {
    color: ${Colors.white};
    border-bottom: 2px solid ${Colors.white};
  }
`;
