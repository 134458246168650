import * as React from 'react';
import styled from 'styled-components';
import { RangeDate } from '../../services/types/results';

const Container = styled.input`
  padding: 5px;
`;

const RangeDateContainer = styled.section`
  display: grid;
  grid-template-columns: 47% 47%;
  justify-content: space-between;
`;

type DateFieldProps = {
  className?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
};

export const DateField = (props: DateFieldProps) => <Container type='date' {...props} />;

type RangeDateFieldProps = DateFieldProps & {
  onChange?: ({ from, to }: RangeDate) => void;
  toDefaultValue?: string;
  fromDefaultValue?: string;
};

export const RangeDateField = ({ name, onChange, ...rest }: RangeDateFieldProps) => {
  const rangeRef = React.useRef<HTMLInputElement>(null);
  const [{ from, to }, setRangeDate] = React.useState<RangeDate>({
    from: rest.fromDefaultValue || '',
    to: rest.toDefaultValue || '',
  });

  const onChangeHandler = ({ from, to }: RangeDate) => {
    if (rangeRef.current) {
      rangeRef.current.value = JSON.stringify({ from, to });
    }
    setRangeDate({ from, to });
    onChange?.({
      from,
      to,
    });
  };

  return (
    <RangeDateContainer>
      <input
        style={{ display: 'none' }}
        name={name}
        ref={rangeRef}
        defaultValue={JSON.stringify({ from, to })}
      />
      <Container
        type='date'
        {...rest}
        onChange={event => onChangeHandler({ from: event.target.value, to })}
        defaultValue={rest.fromDefaultValue}
      />
      <Container
        type='date'
        min={from}
        {...rest}
        onChange={event => onChangeHandler({ from, to: event.target.value })}
        defaultValue={rest.toDefaultValue}
      />
    </RangeDateContainer>
  );
};
