import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../static';
import { resizeBase64Img } from '../../utils';

const Button = styled.label`
  border-radius: 25px;
  background-color: ${Colors.blue};
  color: ${Colors.white};
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  width: 180px;
  height: 40px;
`;

type InputFileProps = {
  onChange: (url: string) => void;
  text: string;
  style?: React.CSSProperties;
  name?: string;
  defaultValue?: string;
  size?: number;
};

export const ImageFile = ({ onChange, text, style, name, defaultValue, size }: InputFileProps) => {
  const hiddenInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        style={{ display: 'none' }}
        id='uploaded_photo'
        type='file'
        accept='image/png'
        onChange={async event => {
          const [file] = event.target.files as any;

          if (file) {
            try {
              const url = await resizeBase64Img(file, size || 150);
              if (hiddenInputRef.current) hiddenInputRef.current.value = url;

              onChange(url);
            } catch {}
          }
        }}
      />
      <input
        ref={hiddenInputRef}
        type='text'
        style={{ display: 'none' }}
        name={name}
        defaultValue={defaultValue}
      />
      <Button htmlFor='uploaded_photo' style={style} children={text} />
    </>
  );
};
