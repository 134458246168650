import { Endpoint } from '../static';
import { AuthFetch, HTTP, SUCCESS_STATUS } from './request';
import { UserInfo } from './types/authentication';

export const login = async (email: string, password: string): Promise<UserInfo> => {
  const body = JSON.stringify({ email, password });

  try {
    const response = await fetch(Endpoint.login, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    const data = await response.json();

    if (response.status === HTTP.Unauthorized || response.status === HTTP.Forbidden) throw data;

    return data;
  } catch (err) {
    throw err;
  }
};

export const requestResetPassword = async (email: string): Promise<void> =>
  AuthFetch.POST(Endpoint.requestResetPassword, { email });

export const forgotPassword = async (email: string) => {
  const body = JSON.stringify({ email });
  try {
    const response = await fetch(Endpoint.forgotPassword, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });
    const data = await response.json();

    if (!SUCCESS_STATUS.includes(response.status)) throw data;

    return data;
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async (password: string, token: string | null): Promise<string> => {
  try {
    const response: string = await AuthFetch.POST(Endpoint.resetPassword, {
      password,
      token,
    });

    return response;
  } catch (err) {
    throw err;
  }
};

export const checkTokenValidity = async (token: string): Promise<{ valid: boolean }> => {
  const response = await fetch(`${Endpoint.checkTokenValidity}/${token}`);

  return response.ok ? response.json() : { valid: false };
};

export const logout = (): Promise<void> => AuthFetch.GET<void>(Endpoint.logout);
export const acceptPolicy = (): Promise<{ datePolicyAccepted: Date }> =>
  AuthFetch.POST(Endpoint.acceptPrivacyPolicy, {});
