import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../providers';
import { setService } from '../../../services/analysis';
import { Translation } from '../../../static';
import { useAnalysis } from '../analysis-context';
import { AnalysisFooterComponent } from '../AnalysisFooter';
import { DatasetsTable } from './DatasetsTable';
import { useHistory } from 'react-router-dom';
import { useServices } from '../../../providers/services-context';
import { AnalysisDirectionMode } from '../../../services/types/analysis';
import { getStateFromLastService, getDatasetFilesAsList, datasetHasFiles } from '../../../utils';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { ChooseDatasetFilePopup } from '../../common/ChooseDatasetFilePopup';

const Container = styled.section`
  height: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-rows: 12% 78% auto;
  row-gap: 1%;
`;

const clearState = {
  options: null,
  validation: null,
};

const { upload } = Translation.analysis;

export const AnalysisDatasetsComponent = () => {
  const { translate } = useTranslation();
  const { services } = useServices();
  const { goBackward, goForward, state } = useAnalysis();
  const [dataset, setDataset] = React.useState(state.dataset);
  const setRef = React.useRef(setDataset);
  const history = useHistory();
  const { show, clean } = useCleanPopup();

  return (
    <Container className='datasets'>
      <DatasetsTable selectDataset={setRef.current} />

      <AnalysisFooterComponent
        goBackward={goBackward}
        goForward={() => {
          const isTheSameDataset = state.dataset?._id === dataset?._id;
          const newState = isTheSameDataset ? state : clearState;

          if(datasetHasFiles(dataset)) {
            return show(
              <ChooseDatasetFilePopup close={clean}
                selectedCbk={(selectedFiles: boolean[]) => {

                  if (state.service._id !== dataset?.service) {
                    setService(state.service._id, dataset?._id as string);
                  }

                  if (dataset?.lastServiceUsed && state.service._id === dataset?.lastServiceUsed) {
                      // only if same selected files
                      let restore = true;
                      const lastState = getStateFromLastService(dataset, services, AnalysisDirectionMode.fromMenuInput);
                      const lastStateSelectedFiles = lastState.initialState.selectedFiles ?? []
                      restore = restore && lastStateSelectedFiles.length == selectedFiles.length
                      if(restore) {
                        let sameSelected = true
                        lastStateSelectedFiles.forEach((v:boolean, index:number) => {
                          sameSelected = sameSelected && (v == selectedFiles[index])
                        })
                        restore = restore && sameSelected
                      }

                      if(restore) {
                        history.replace('/analysis', lastState);
                        window.location.reload();
                        return 
                      }
                  }

                  goForward(
                    {
                      ...newState,
                      dataset,
                      selectedFiles
                    },
                    !isTheSameDataset
                  );

                }}
                menu={getDatasetFilesAsList(dataset?.files ?? [])}
              />
            )
          }

          if (state.service._id !== dataset?.service) {
            setService(state.service._id, dataset?._id as string);
          }

          if (dataset?.lastServiceUsed && state.service._id === dataset?.lastServiceUsed) {
              history.replace(
                '/analysis',
                getStateFromLastService(dataset, services, AnalysisDirectionMode.fromMenuInput)
              );
              window.location.reload();
          } else {
            goForward(
                {
                  ...newState,
                  dataset,
                },
                !isTheSameDataset
            );
          }
        }}
        btnText={translate(upload.selectDataset)}
        forwardDisabled={dataset == null}
      />
    </Container>
  );
};
