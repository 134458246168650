import styled from 'styled-components';
import { useTranslation } from '../../providers';
import { useServices } from '../../providers/services-context';
import { ClientService } from '../../services/types/settings';
import { Colors, Translation } from '../../static';
import { LargeTile } from '../common';
import { useAnalysis } from './analysis-context';

const Container = styled.section`
  overflow-y: scroll;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-items: center;

  .tile {
    margin: 20px 0;
  }
`;

const Empty = styled.section`
  color: ${Colors.menuColor};
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const clearState = {
  dataset: null,
  options: null,
  validation: null,
};

export const AnalysisServicesComponent = () => {
  const { translate } = useTranslation();
  const { goForward } = useAnalysis();
  const { activeServices } = useServices();
  const services = activeServices.filter(service => !service.isReport);

  if (!services?.length) return <Empty>{translate(Translation.overview.noServices)}</Empty>;

  return (
    <Container data-testid='analysis-service'>
      {services.map((service: ClientService, index) => {
        const { name } = service.levels.find(({ _id }) => _id === service.level) ?? { name: '' };

        return service.enabled ? (
          <LargeTile
            key={index}
            className='tile'
            onClick={() => goForward({ ...clearState, service }, true)}
            photo={service.photo}
            description={service.name}
            value={service.description}
            status={translate(name)}
          />
        ) : null;
      })}
    </Container>
  );
};
