import * as React from 'react';
import styled from 'styled-components';
import { useClickOutside } from '../../../hooks/use-click-outside';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { useTranslation } from '../../../providers';
import { Colors, dropShadow, Translation } from '../../../static';
import { getOptionValue } from '../../analysis/summary/SummaryProperties';
import { CloseIcon } from '../../common/icons';

const Container = styled.section`
  width: 700px;
  height: 400px;
  background-color: ${Colors.white};
  filter: ${dropShadow};
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-radius: 10px;
  color: ${Colors.menuColor};

  .header {
    padding-left: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 25px;
  }

  .option {
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .option-row {
      padding: 5px 0 5px 25px;
    }
  }
`;

const nameStyle = { fontSize: 18, fontWeight: 600 };
const valueStyle = { fontSize: 15 };

export const ResultsOptionsPopup = ({ options }: { options: Array<[string, any]> | undefined }) => {
  const { clean } = useCleanPopup();
  const { translate } = useTranslation();
  const ref = React.useRef<HTMLElement | null>(null);
  useClickOutside(ref, clean);
  const values: [string, string] = [
    translate(Translation.account.deleteLanguage.yesBtn),
    translate(Translation.account.deleteLanguage.noBtn),
  ];

  return (
    <Container ref={ref}>
      <CloseIcon onClose={() => clean()} style={{ marginBottom: 10 }} />
      <section className='header'>{translate(Translation.analysis.header.options.options)}</section>

      <section className='option'>
        {options?.map(([name, value], index) => (
          <section key={index} className='option-row'>
            <span style={nameStyle}>{name}: </span>
            <span style={valueStyle}>{getOptionValue(value, values)}</span>
          </section>
        ))}
      </section>
    </Container>
  );
};
