import * as React from 'react';
import styled from 'styled-components';

type Transition = '0.1s' | '0.2s' | '0.3s' | '0.4s' | '0.5s' | '0.8s' | '1s' | '2s' | '3s' | '5s';
type Direction = 'left' | 'right' | 'top' | 'bottom' | 'none';

type MoveProps = {
  children?: React.ReactNode;
  transition: Transition;
  direction: Direction;
  px: number;
  zIndex?: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

const Container = styled.section`
  position: relative;
  z-index: 1;

  & {
    transition: ${({ transition }: MoveProps) => transition};
    transform: ${({ direction, px }: MoveProps) => {
      switch (direction) {
        case 'top':
          return `translateY(-${px}px)`;
        case 'right':
          return `translateX(${px}px)`;
        case 'bottom':
          return `translateY(${px}px)`;
        case 'left':
          return `translateX(-${px}px)`;

        default:
          return '';
      }
    }};
  }
`;

export const Move = React.forwardRef(
  ({ children, transition, direction, px, onClick }: MoveProps, ref: any) => {
    const [config, setConfig] = React.useState<{ direction: Direction; px: number }>({
      direction: 'none',
      px: 0,
    });

    return (
      <Container
        ref={ref}
        transition={transition}
        direction={config.direction}
        px={config.px}
        onClick={onClick}
        onMouseEnter={() => setConfig({ direction, px })}
        onMouseLeave={() => {
          switch (direction) {
            case 'left':
              setConfig({ direction: 'right', px: 0 });
              break;
            case 'right':
              setConfig({ direction: 'left', px: 0 });
              break;
            case 'top':
              setConfig({ direction: 'bottom', px: 0 });
              break;
            case 'bottom':
              setConfig({ direction: 'top', px: 0 });
              break;
            default:
              return '';
          }
        }}
      >
        {children}
      </Container>
    );
  }
);

export const AutomaticMove = React.forwardRef(
  ({ children, transition, direction, px, zIndex }: MoveProps, ref: any) => {
    return (
      <Container transition={transition} direction={direction} px={px} style={{ zIndex }} ref={ref}>
        {children}
      </Container>
    );
  }
);
