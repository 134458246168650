import { Endpoint } from '../static';
import { AuthFetch } from './request';
import { Dataset, OrganizationOfFile } from './types/analysis';

type Payload = { file: any; fileName?: string };

export const uploadFile = async (p: Payload, clientId: string, datasetId: string | null = null): Promise<Dataset> => {
  const formData = new FormData();
  formData.append('file', p.file);
  formData.append('fileName', p.fileName ?? '');
  formData.append('clientId', clientId);

  let url = Endpoint.uploadFile
  if(datasetId != null) {
    url = `${Endpoint.uploadFile}/${datasetId}`
  }

  try {
    const dataset = await AuthFetch.POST<Dataset>(url, formData, 'text');

    return {
      ...dataset,
      name: dataset.name,
      updatedAt: new Date(),
    };
  } catch (err) {
    throw err;
  }
};

export const updateDataset = async (p: Payload, clientId: string, datasetId: string): Promise<Dataset> => {

  const url = `${Endpoint.updateDataset}/${datasetId}`
  const data = {
    fileName: p.fileName
  }

  try {
    const dataset = await AuthFetch.POST<Dataset>(url, data);

    return {
      ...dataset,
      name: dataset.name,
      updatedAt: new Date(),
    };
  } catch (err) {
    throw err;
  }
}

type CheckDatasetsProps = {
  columnSeparator: string;
  datasetId?: string;
  offset?: number
};

export const checkDatasets = async (props: CheckDatasetsProps) => {
  if (!props.datasetId) return { duplicateColumns: true };

  return AuthFetch.POST<{ duplicateColumns: boolean }>(Endpoint.checkDatasets, props);
};

export const getDatasets = async (clientId: string | null): Promise<Dataset[]> =>
  AuthFetch.GET<Dataset[]>(`${Endpoint.datasets}?clientId=${clientId}`);

export const previewDataset = async (id?: string): Promise<Record<string, string>[]> => {
  return id === undefined ? [] : AuthFetch.GET(`${Endpoint.previewDataset}/${id}`);
};

export const previewDatasetFile = async (id?: string, offset?:string): Promise<Record<string, string>[]> => {
  return id === undefined ? [] : AuthFetch.GET(`${Endpoint.previewDataset}/${id}${offset === undefined ? '' : `/${offset}`}`);
};

export const previewDatasetWithColSeparator = async (id?: string, colSeparator?: string, offset?:string): Promise<Record<string, string>[]> => {
  return id === undefined ? [] : AuthFetch.POST(`${Endpoint.previewDataset}/${id}${offset === undefined ? '' : `/${offset}`}`, {columnSeparator: colSeparator});
};

export const getAutoColSeparator = async (id?: string): Promise<string[]> => {
  return id ? AuthFetch.GET(`${Endpoint.getAutoColSDataset}/${id}`) : new Promise(resolve => resolve([]));
};

export const getFileHeader = async (
  file: OrganizationOfFile | undefined,
  datasetId: string | undefined
): Promise<string[]> => {
  try {
    const { headers } = await AuthFetch.POST<{ headers: string[] }>(Endpoint.datasetHeader, {
      datasetId,
      columnSeparator: file?.columnSeparator,
      textQuoted: file?.textQuoted,
    });

    return headers;
  } catch (err) {
    throw err;
  }
};

type Validation = {
  organizationOfFile: OrganizationOfFile | undefined;
  identificationOfColumns: Record<string, string> | undefined;
  datasetId: string | undefined;
  serviceId: string | undefined;
  selectedFiles: boolean[]
};

export const validateColumns = async (validation: Validation): Promise<Record<string, boolean>> =>
  AuthFetch.POST<Record<string, boolean>>(Endpoint.validateDataset, validation);

type AnalysisPayload = Validation & {
  options: Record<string, string> | null;
};

export const launchAnalysis = async (
  analysis: AnalysisPayload
): Promise<{ estimatedTime: number }> => {
  return AuthFetch.POST(Endpoint.launchAnalysis, analysis);
};

export const setService = async (serviceId: string, datasetId: string): Promise<void> => {
  return AuthFetch.POST<void>(Endpoint.setService, {
    serviceId,
    datasetId,
  });
};
