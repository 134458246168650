import styled from 'styled-components';
import { Colors, dropShadow } from '../../static';

export const TileContainer = styled.section`
  min-width: 350px;
  height: 200px;
  background-color: ${Colors.white};
  filter: ${dropShadow};
  padding: 15px;
  border-radius: 10px;
  display: grid;
  grid-template-rows: minmax(0, 40%) minmax(0, 1fr);
  color: ${Colors.menuColor};

  .title {
    font-weight: 600;
    font-size: 20px;
    color: ${Colors.menuColor};
  }

  .currency {
    padding-right: 10px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .value {
    font-weight: 700;
    font-size: 35px;
  }

  .percentage {
    padding-left: 5px;
    font-weight: 700;
    font-size: 25px;
  }
`;

export const KPIContainer = styled.section`
  width: 100%;
  margin: 30px 0;

  .sectionHeader {
    font-size: 25px;
    color: ${Colors.menuColor};
    font-weight: 600;
    padding: 15px 0;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 50px;

    @media (max-width: 1601px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .dataAnalyzedChart {
    width: 100%;
    margin: 25px 0;

    .legend {
      width: 100%;
      padding: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      .legendBar {
        display: flex;
        align-items: center;

        span {
          color: ${Colors.menuColor};
        }

        ._bar {
          height: 6px;
          width: 60px;
          margin-right: 5px;
          margin-left: 50px;
        }
      }
    }

    .tooltip {
      display: none;
      position: absolute;
      padding: 5px;
      color: ${Colors.menuColor};
      font-size: 15px;
      background-color: ${Colors.bgColorTransactions};
      border-radius: 3px;
      font-weight: 600;
    }

    .axis-x {
      path,
      .tick line {
        display: none;
      }
    }

    .axis-y-left,
    .axis-y-right {
      path {
        display: none;
      }

      .tick {
        line {
          color: ${Colors.grey};
          stroke-width: 1px;
        }

        text {
          font-size: 13px;
        }
      }
    }

    .data-rect {
      cursor: pointer;
    }

    .text {
      fill: ${Colors.menuColor};
      font-weight: 700;
      font-size: 17px;
      text-anchor: middle;
    }

    .line {
      fill: none;
      stroke: ${Colors.grey};
      stroke-width: 3;
    }

    .dot {
      fill: ${Colors.grey};
      stroke: ${Colors.grey};
      cursor: pointer;
    }
  }

  .analysisResults {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column: 5%;

    .donutChart {
      width: 100%;
      margin: 25px 0;

      .legend {
        width: 100%;
        padding-left: 10px;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        column-gap: 5px;
        align-items: center;

        .legendBar {
          display: flex;
          align-items: center;
          padding: 10px 0;

          span {
            margin-left: 5px;
            color: ${Colors.menuColor};
          }

          ._bar {
            height: 10px;
            width: 10px;
          }
        }
      }
    }
  }
`;
