import { v4 as uuid } from 'uuid';
import { FormServiceParam } from '../components/settings/servicesAndLevel/CreateService';
import { Endpoint } from '../static';
import { AuthFetch } from './request';
import { PartialUser, Role, User, UserPayload } from './types/authentication';
import {
  ClientService,
  ListType,
  OnlineOptionType,
  Option,
  Service,
  ServiceLevel,
  SystemParameters,
} from './types/settings';

const Keys = Object.freeze({ roles: 'roles' });

export const saveEmailPreferences = async (receiveEmails: boolean) => {
  return AuthFetch.POST<{ receiveEmails: boolean }>(Endpoint.settings.saveEmailPreferences, {
    receiveEmails,
  });
};

export const addUser = async (user: UserPayload): Promise<Pick<User, '_id'>> =>
  AuthFetch.POST(Endpoint.settings.users, user);

export const getUsers = async (id: string): Promise<PartialUser[]> =>
  AuthFetch.GET(`${Endpoint.settings.users}?clientId=${id}`);

export const deleteUser = async (_id: string, transferId: string): Promise<void> =>
  AuthFetch.DELETE(`${Endpoint.settings.users}/${_id}/${transferId}`);

export const getRoles = async (): Promise<Role[]> => {
  const cachedRoles = await sessionStorage.getItem(Keys.roles);

  if (cachedRoles) return JSON.parse(cachedRoles);

  const roles = await AuthFetch.GET<Role[]>(Endpoint.acccount.roles);
  sessionStorage.setItem(Keys.roles, JSON.stringify(roles));

  return roles;
};

export const submitSystemParameters = (systemParameters: SystemParameters): Promise<void> =>
  AuthFetch.POST(Endpoint.settings.systemParameters, systemParameters);

export const getSystemParameters = (): Promise<SystemParameters> =>
  AuthFetch.GET(Endpoint.settings.systemParameters);

export const getServices = async (clientId: string): Promise<ClientService[]> => {
  const response = await AuthFetch.GET<
    {
      service: Service;
      enabled: boolean;
      display: boolean;
      level: string;
    }[]
  >(`${Endpoint.settings.clientServices}?clientId=${clientId}`);

  return response.map(({ service, ...rest }) => ({ ...service, ...rest }));
};

type ServiceProps = {
  clientId: string;
  serviceId: string;
  enabled: boolean;
  display: boolean;
  levelId: string;
};

export const changeService = async (service: ServiceProps) => {
  return AuthFetch.POST<{ success: boolean }>(Endpoint.settings.clientServices, service);
};

export const saveService = async ({ _id, ...service }: FormServiceParam) => {
  return AuthFetch.POST<{ _id: string }>(Endpoint.settings.saveServices, {
    ...service,
    serviceId: _id,
  });
};
export const deleteService = async (serviceId: string): Promise<void> => {
  return AuthFetch.DELETE<void>(`${Endpoint.settings.deleteServices}/${serviceId}`);
};

export const OPTIONS_LIST: Array<{ label: ListType; value: ListType }> = [
  {
    label: ListType.List,
    value: ListType.List,
  },
  {
    label: ListType.Slider,
    value: ListType.Slider,
  },
  {
    label: ListType.Value,
    value: ListType.Value,
  },
  {
    label: ListType.Text,
    value: ListType.Text,
  },
  {
    label: ListType.Date,
    value: ListType.Date,
  },
  {
    label: ListType.RangeDate,
    value: ListType.RangeDate,
  },
];

export const saveOptions = async (serviceId: string, options: Option[]): Promise<void> => {
  return AuthFetch.POST(Endpoint.settings.options, { serviceId, options });
};

export const generateNewOption = (serviceLevels: ServiceLevel[]): Option => ({
  _id: uuid(),
  defaultValue: null,
  name: '',
  title: 'Option',
  type: ListType.List,
  optionList: [],
  serviceLevels: serviceLevels.map(serv => ({ ...serv, enabled: serv.enabled || false })),
});

type SaveOnlineHelpOptionsProps = {
  serviceId: string;
  type: OnlineOptionType;
  enabled: boolean;
};

export const saveOnlineHelpOptions = async (payload: SaveOnlineHelpOptionsProps) => {
  return AuthFetch.POST(Endpoint.settings.saveAllOptionsHelp, payload);
};

export const getOnlineHelpOptions = async (serviceId: string): Promise<Record<string, boolean>> => {
  return AuthFetch.GET(`${Endpoint.settings.getAllOptionsHelp}?serviceId=${serviceId}`);
};

type MarkdownContentProps = { type: OnlineOptionType; serviceId: string; isoCode: string };
export const getMarkdownContent = async (props: MarkdownContentProps) => {
  const { serviceId, isoCode, type } = props;
  const url = new URL(Endpoint.settings.getMarkdown);
  url.searchParams.set('serviceId', serviceId);
  url.searchParams.set('isoCode', isoCode);
  url.searchParams.set('type', type);

  return AuthFetch.GET<{ content: string }>(url.toString());
};

export const saveMarkdown = async (payload: MarkdownContentProps & { content: string }) => {
  return AuthFetch.POST<void>(Endpoint.settings.saveMarkdown, payload);
};

export const getMaxFileSize = () => AuthFetch.GET<{ maxFileSize: number }>(Endpoint.maxFileSize);

// the size is in KBytes
export const saveFileSizeToLocalStorage = (size: number) => ((window as any).fileSize = size);
export const getFileSizeFromLocalStorage = () =>
  (window as any).fileSize ? (window as any).fileSize : 1000000;
