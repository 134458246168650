import { Colors } from '../../../static';

type EditFileIconProps = {
  color?: string;
  size?: number;
  onClick?: () => void;
  strokeWidth?: number;
  disabled?: boolean;
};

export const EditFileIcon = ({
  color = Colors.black,
  size = 28,
  onClick,
  strokeWidth = 0,
  disabled = false,
}: EditFileIconProps) => {
  const style = disabled
    ? { cursor: 'not-allowed', opacity: 0.6 }
    : { cursor: 'pointer', opacity: 1 };

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 28 28'
      onClick={() => {
        if (!disabled) onClick?.();
      }}
      style={style}
    >
      <path
        d='M14.0002 0C14.1998 0 14.3913 0.0793006 14.5324 0.220457C14.6736 0.361613 14.7529 0.553062 14.7529 0.752687V19.1183L20.4733 13.4355C20.538 13.3492 20.6205 13.2778 20.7152 13.2262C20.8098 13.1746 20.9145 13.1439 21.0221 13.1363C21.1296 13.1286 21.2376 13.1442 21.3386 13.1819C21.4397 13.2196 21.5314 13.2786 21.6077 13.3549C21.6839 13.4311 21.7429 13.5229 21.7806 13.6239C21.8183 13.7249 21.8339 13.8329 21.8262 13.9404C21.8186 14.048 21.7879 14.1527 21.7363 14.2474C21.6847 14.342 21.6133 14.4245 21.5271 14.4892L14.5271 21.4516C14.3864 21.5895 14.1972 21.6668 14.0002 21.6668C13.8032 21.6668 13.614 21.5895 13.4733 21.4516L6.4733 14.4892C6.38703 14.4245 6.31567 14.342 6.26405 14.2474C6.21243 14.1527 6.18177 14.048 6.17412 13.9404C6.16648 13.8329 6.18203 13.7249 6.21974 13.6239C6.25745 13.5229 6.31643 13.4311 6.39269 13.3549C6.46894 13.2786 6.56069 13.2196 6.66172 13.1819C6.76275 13.1442 6.87071 13.1286 6.97827 13.1363C7.08584 13.1439 7.19051 13.1746 7.28519 13.2262C7.37988 13.2778 7.46236 13.3492 7.52707 13.4355L13.2475 19.1183V0.752687C13.2475 0.553062 13.3268 0.361613 13.4679 0.220457C13.6091 0.0793006 13.8006 0 14.0002 0Z'
        transform="rotate(180 14 12)"
        fill={color}
        strokeWidth={strokeWidth}
        stroke={color}
      />
      <path
        d='M26.5325 19.5699C26.7321 19.5699 26.9235 19.6492 27.0647 19.7903C27.2059 19.9315 27.2852 20.1229 27.2852 20.3226V24.9892C27.2852 25.7877 26.968 26.5535 26.4033 27.1182C25.8387 27.6828 25.0729 28 24.2744 28H3.72604C2.92754 28 2.16175 27.6828 1.59712 27.1182C1.0325 26.5535 0.715292 25.7877 0.715292 24.9892V19.9462C0.715292 19.7466 0.794594 19.5552 0.935751 19.414C1.07691 19.2728 1.26835 19.1935 1.46798 19.1935C1.6676 19.1935 1.85905 19.2728 2.00021 19.414C2.14137 19.5552 2.22066 19.7466 2.22066 19.9462V24.9892C2.22066 25.3885 2.37927 25.7714 2.66158 26.0537C2.94389 26.336 3.32679 26.4946 3.72604 26.4946H24.2744C24.6737 26.4946 25.0566 26.336 25.3389 26.0537C25.6212 25.7714 25.7798 25.3885 25.7798 24.9892V20.3226C25.7798 20.1229 25.8591 19.9315 26.0002 19.7903C26.1414 19.6492 26.3328 19.5699 26.5325 19.5699Z'
        fill={color}
        strokeWidth={strokeWidth}
        stroke={color}
      />
    </svg>
  );
};
