export enum FileExtensions {
  XLXS = '.xlsx',
  XLS = '.xls',
  CSV = '.csv',
  TXT = '.txt',
  XLSB = '.xlsb',
  EMPTY = '',
  ZIP = '.zip',
}

export enum FileExtensionsLetters {
  XLXS = 'xlsx',
  XLS = 'xls',
  CSV = 'csv',
  TXT = 'txt',
  XLSB = 'xlsb',
  EMPTY = '',
  ZIP = 'zip',
}


export const FileTypes: Record<FileExtensions, string> = {
  [FileExtensions.XLXS]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [FileExtensions.XLS]: 'application/vnd.ms-excel',
  [FileExtensions.CSV]: 'text/csv',
  [FileExtensions.TXT]: 'text/plain',
  [FileExtensions.XLSB]: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  [FileExtensions.EMPTY]: '',
  [FileExtensions.ZIP]: 'application/x-zip',
};

export const CSVExtension = [
  '.csv',
  // 'text/csv',
  // 'application/vnd.ms-excel',
  // 'application/csv',
  // 'text/x-csv',
  // 'application/x-csv',
  // 'text/comma-separated-values',
  // 'text/x-comma-separated-values',
];
