import { AgGridColumn, AgGridColumnGroupProps, AgGridColumnProps } from 'ag-grid-react';
import { dateFormatter } from '../../../utils';

const getDate = (date: Date): string => {
  const dd = date.getDate();
  const yy = date.getFullYear();
  const mm = date.getMonth();

  return `${yy}${mm}${dd}`;
};
const filterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    const fDate = +getDate(filterLocalDateAtMidnight);
    const cellDate = +getDate(new Date(cellValue));

    return fDate === cellDate ? 0 : cellDate < fDate ? -1 : 1;
  },
  browserDatePicker: true,
};

export const DateCol = (props: AgGridColumnProps | AgGridColumnGroupProps) => {
  return (
    <AgGridColumn
      {...props}
      sort='desc'
      valueFormatter={({ value }) => dateFormatter(value)}
      filter='agDateColumnFilter'
      filterParams={filterParams}
    />
  );
};
