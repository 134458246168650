import React, {Dispatch, MutableRefObject, SetStateAction, useContext, useMemo, useState} from "react";

export interface TooltipContextType {
  show: boolean,
  setShow: Dispatch<SetStateAction<boolean>>,
  msg: string,
  setMessage: (message: string) => void,
  xPos: number,
  setXPos: Dispatch<SetStateAction<number>>,
  yPos: number,
  setYPos: Dispatch<SetStateAction<number>>,
  turnOnDefaultTooltip: (ref: MutableRefObject<null>, message: string) => void,
  maxWidth: number,
  setMaxWidth: Dispatch<SetStateAction<number>>,
}

export const TooltipContext = React.createContext<TooltipContextType | null>(null)

export const useTooltipContext = () => useContext(TooltipContext) as TooltipContextType

export const useTooltipContextBuilder = () => {
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState('');
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [maxWidth, setMaxWidth] = useState(2000);

  const setMessage = (message: string) => {
    setMsg(message)
    setShow(true)
  }

  const turnOnDefaultTooltip = (ref: MutableRefObject<null>, message: string) => {
    if (ref?.current) {
      const ele = ref.current as HTMLSpanElement
      setXPos(ele.getBoundingClientRect().x + ele.getBoundingClientRect().width / 2)
      setYPos(ele.getBoundingClientRect().y + ele.getBoundingClientRect().height + 5)
      setMessage(message)
    }
  }

  return useMemo(() => ({show, setShow, msg, setMessage, xPos, setXPos, yPos, setYPos, maxWidth, setMaxWidth, turnOnDefaultTooltip}), [show, msg, xPos, yPos]);
}

