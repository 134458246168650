import { AccountRoutes, Routes, RoutesName, SettingsRoutes, Translation } from '../../static';
import { preloadLazy } from '../../utils';
import AccountComponent from '../account/Account';
import { AnalysisComponent } from '../analysis';
import { FilesComponent } from '../files';
import Overview from '../overview/Overview';
import { ResultsComponent } from '../results/Results';
import { SettingsComponent } from '../settings/Settings';

export const Roles = Object.freeze({
  admin: 'platform_admin',
  manager: 'client_admin',
  user: 'user',
});

export const ItemsAuth = {
  overview: {
    path: Routes.Overview,
    roles: [Roles.admin, Roles.manager, Roles.user],
  },
  dataSets: {
    path: Routes.Datasets,
    roles: [Roles.admin, Roles.manager, Roles.user],
  },
  results: {
    path: Routes.Results,
    roles: [Roles.admin, Roles.manager, Roles.user],
  },
  settingsUsers: {
    path: SettingsRoutes.users,
    roles: [Roles.admin, Roles.manager],
  },
};

export const NewAnalysisItem = {
  Component: AnalysisComponent,
  path: Routes.Analysis,
  roles: [Roles.admin, Roles.manager, Roles.user],
};

export const SettingsNavPaths = [
  SettingsRoutes.services,
  SettingsRoutes.options,
  SettingsRoutes.users,
  SettingsRoutes.system,
  SettingsRoutes.languageManagement,
  SettingsRoutes.onlineHelp,
];

export const AccountNavPaths = [
  AccountRoutes.personalInformation,
  AccountRoutes.companyInformation,
];

type RouteObj = {
  key: string;
  path: string | string[];
  name: string;
  Component: () => JSX.Element | React.LazyExoticComponent<React.ComponentType<any>>;
};

type Route = {
  [key: string]: RouteObj;
};

const InnerRoutes: Route = {
  newAnalysis: {
    key: Translation.menu.analysis,
    path: Routes.Analysis,
    name: RoutesName.Analysis,
    Component: AnalysisComponent,
  },
  overview: {
    key: Translation.menu.overview,
    path: Routes.Overview,
    name: RoutesName.Overview,
    Component: Overview,
  },
  dataSets: {
    key: Translation.menu.datasets,
    path: Routes.Datasets,
    name: RoutesName.Datasets,
    Component: FilesComponent,
  },
  results: {
    key: Translation.menu.results,
    path: Routes.Results,
    name: RoutesName.Results,
    Component: ResultsComponent,
  },
  settings: {
    key: Translation.menu.settings,
    path: SettingsNavPaths,
    name: RoutesName.Settings,
    Component: SettingsComponent,
  },
  account: {
    key: Translation.account.viewAccount,
    path: AccountNavPaths,
    name: RoutesName.Account,
    Component: AccountComponent,
  },
  settingsServices: {
    key: Translation.settings.links.services,
    path: SettingsRoutes.services,
    name: RoutesName.Settings,
    Component: () => preloadLazy(() => import('../settings/servicesAndLevel/Services')),
  },
  settingsUsers: {
    key: Translation.settings.links.users,
    path: SettingsRoutes.users,
    name: RoutesName.Settings,
    Component: () => preloadLazy(() => import('../settings/users/Users')),
  },
  settingsSystem: {
    key: Translation.settings.links.system,
    path: SettingsRoutes.system,
    name: RoutesName.Settings,
    Component: () => preloadLazy(() => import('../settings/System')),
  },
  settingsOptions: {
    key: Translation.settings.links.options,
    path: SettingsRoutes.options,
    name: RoutesName.Settings,
    Component: () => preloadLazy(() => import('../settings/options/Options')),
  },
  onlineHelp: {
    key: Translation.settings.links.onlineHelp,
    path: SettingsRoutes.onlineHelp,
    name: RoutesName.Settings,
    Component: () => preloadLazy(() => import('../settings/onlineHelp/OnlineHelp')),
  },
  accountPersonalInfo: {
    key: Translation.account.links.personalInformation,
    path: AccountRoutes.personalInformation,
    name: RoutesName.Account,
    Component: () => preloadLazy(() => import('../account/PersonalInfomation')),
  },
  accountCompanyAccount: {
    key: Translation.account.links.companyInformation,
    path: AccountRoutes.companyInformation,
    name: RoutesName.Account,
    Component: () => preloadLazy(() => import('../account/company/CompanyAccount')),
  },
  accountLanguageManagement: {
    key: Translation.account.links.languageManagement,
    path: SettingsRoutes.languageManagement,
    name: RoutesName.Account,
    Component: () => preloadLazy(() => import('../settings/language/LanguageManagement')),
  },
};

type NavRoute = {
  [key: string]: Array<RouteObj>;
};

export const MainNavRoutes: NavRoute = {
  [Roles.admin]: [
    InnerRoutes.overview,
    InnerRoutes.dataSets,
    InnerRoutes.results,
    InnerRoutes.settings,
    InnerRoutes.account,
  ],
  [Roles.manager]: [
    InnerRoutes.overview,
    InnerRoutes.dataSets,
    InnerRoutes.results,
    {
      key: Translation.menu.settings,
      path: SettingsRoutes.users,
      name: RoutesName.Settings,
      Component: SettingsComponent,
    },
    {
      key: '',
      path: [AccountRoutes.personalInformation, AccountRoutes.companyInformation],
      name: RoutesName.Account,
      Component: AccountComponent,
    },
  ],
  [Roles.user]: [
    InnerRoutes.overview,
    InnerRoutes.dataSets,
    InnerRoutes.results,
    {
      key: '',
      path: [AccountRoutes.personalInformation],
      Component: AccountComponent,
      name: RoutesName.Account,
    },
  ],
};

export const MainNavPaths: Record<string, string[]> = {
  [Roles.admin]: [
    Routes.Overview,
    Routes.Datasets,
    Routes.Results,
    ...AccountNavPaths,
    ...SettingsNavPaths,
  ],
  [Roles.manager]: [
    Routes.Overview,
    Routes.Datasets,
    Routes.Results,
    AccountRoutes.personalInformation,
    AccountRoutes.companyInformation,
    SettingsRoutes.users,
  ],
  [Roles.user]: [
    Routes.Overview,
    Routes.Datasets,
    Routes.Results,
    AccountRoutes.personalInformation,
  ],
};

export const SettingsNavRoutes: NavRoute = {
  [Roles.admin]: [
    InnerRoutes.settingsServices,
    InnerRoutes.settingsOptions,
    InnerRoutes.settingsUsers,
    InnerRoutes.settingsSystem,
    InnerRoutes.accountLanguageManagement,
    InnerRoutes.onlineHelp,
  ],
  [Roles.manager]: [InnerRoutes.settingsUsers],
  [Roles.user]: [],
};

export const AccountNavRoutes: NavRoute = {
  [Roles.admin]: [InnerRoutes.accountPersonalInfo, InnerRoutes.accountCompanyAccount],
  [Roles.manager]: [InnerRoutes.accountPersonalInfo, InnerRoutes.accountCompanyAccount],
  [Roles.user]: [InnerRoutes.accountPersonalInfo],
};
