import { Step } from '../../services/types/analysis';
import { Translation } from '../../static';
import { AnalysisDatasetsComponent } from './datasets/AnalysisDatasets';
import { AnalysisOptionsComponent } from './AnalysisOptions';
import { AnalysisServicesComponent } from './AnalysisService';
import { AnalysisSummaryComponent } from './summary/AnalysisSummary';
import { AnalysisValidationComponent } from './validation/AnalysisValidation';
import { deepFreeze } from '../../utils';

export const Steps: Step[] = deepFreeze([
  {
    isDone: false,
    isActive: true,
    Component: AnalysisServicesComponent,
    title: Translation.analysis.header.service.title,
    description: Translation.analysis.header.service.description,
  },
  {
    isDone: false,
    isActive: false,
    Component: AnalysisDatasetsComponent,
    title: Translation.analysis.header.upload.title,
    description: Translation.analysis.header.upload.description,
  },
  {
    isDone: false,
    isActive: false,
    Component: AnalysisValidationComponent,
    title: Translation.analysis.header.validation.title,
    description: Translation.analysis.header.validation.description,
  },
  {
    isDone: false,
    isActive: false,
    Component: AnalysisOptionsComponent,
    title: Translation.analysis.header.options.title,
    description: Translation.analysis.header.options.description,
  },
  {
    isDone: false,
    isActive: false,
    Component: AnalysisSummaryComponent,
    title: Translation.analysis.header.submission.title,
    description: Translation.analysis.header.submission.description,
  },
]);
