import { useTranslation } from '../../providers';
import { Translation } from '../../static';
import { Button, LongRightArrow } from '../common';

type FooterProps = {
  goForward: () => void;
  goBackward: () => void;
  btnText: string;
  forwardDisabled?: boolean;
  backwardDisabled?: boolean;
  className?: string;
};

export const AnalysisFooterComponent = ({
  goBackward,
  goForward,
  btnText,
  forwardDisabled,
  className,
}: FooterProps) => {
  const { translate } = useTranslation();

  return (
    <section className={`footer ${className}`}>
      <Button className='backward' onClick={() => goBackward()}>
        <LongRightArrow width={50} /> {translate(Translation.back)}
      </Button>
      <Button
        className={`forward ${forwardDisabled ? 'disabled' : ''}`}
        onClick={() => goForward()}
        disabled={forwardDisabled}
      >
        {btnText}
      </Button>
    </section>
  );
};
