import { Colors } from '../../../static';

export const ReadyIcon = ({ border = true }: { border?: boolean }) => (
  <svg width='40' height='40' viewBox='0 0 50 50' fill='none'>
    <circle
      cx='25'
      cy='25'
      r='22.5'
      fill='#95D8FF'
      stroke={border ? Colors.blue : ''}
      strokeWidth='5'
    />
    <path
      d='M20.3566 25.5835V17.4192C20.3566 17.1027 20.3566 16.9444 20.4604 16.8696C20.5642 16.7947 20.7144 16.8448 21.0147 16.9449L32.2204 20.6801C32.8087 20.8762 33.1029 20.9743 33.1029 21.1545C33.1029 21.3347 32.8087 21.4327 32.2204 21.6288L20.3566 25.5835ZM20.3566 25.5835V31.1197V32.2269'
      stroke={Colors.menuColor}
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);

export const InProgressIcon = ({ border = true }: { border?: boolean }) => (
  <svg width='40' height='40' viewBox='0 0 50 50' fill='none'>
    <circle
      cx='25'
      cy='25'
      r='22.5'
      fill='#FFB800'
      stroke={border ? Colors.blue : ''}
      strokeWidth='5'
    />
    <path
      d='M32.9819 28.3704H24.9568C24.8187 28.3704 24.7068 28.2585 24.7068 28.1204V15.8791'
      stroke={Colors.menuColor}
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);

export const AnalyzedIcon = ({ border = true, size = 40 }: { border?: boolean; size?: number }) => (
  <svg width={size} height={size} viewBox='0 0 50 50' fill='none'>
    <circle
      cx='25'
      cy='25'
      r='22.5'
      fill='#BDFF00'
      stroke={border ? Colors.blue : ''}
      strokeWidth='5'
    />
    <path
      d='M17.9585 25L21.6911 28.7326C22.4152 29.4567 22.7772 29.8187 23.2178 29.7987C23.6583 29.7787 23.9861 29.3854 24.6417 28.5987L32.0416 19.7188'
      stroke='#436F0B'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
