import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth, useTranslation } from '../../providers';
import { Translation } from '../../static';
import { Header, MainContainer, Navigation } from '../common';
import { SecuredRoute } from '../routing';
import { AccountNavRoutes } from '../routing/NavItems';

type Comp = React.LazyExoticComponent<React.ComponentType<any>>;
const { header } = Translation.account;
const commonProps = { className: 'link', activeClassName: 'active' };

const AccountComponent = () => {
  const { translate } = useTranslation();
  const { userInfo } = useAuth();
  const [views, setViews] = useState<Comp[]>([]);
  const routes = AccountNavRoutes[userInfo.user.role.type];

  useEffect(() => {
    (async () => {
      const promises = routes.map(async value => {
        const View = (await value.Component()) as React.LazyExoticComponent<
          React.ComponentType<any>
        >;

        return <View />;
      });

      Promise.all(promises).then(setViews as Comp);
    })();
  }, [routes]);

  return (
    <>
      <Header
        flex
        subtitle={translate(header.subtitle, [userInfo.user?.firstName])}
        title={translate(header.title)}
      >
        <Navigation>
          {routes?.map(({ key, path }) => (
            <NavLink to={path as string} key={key} {...commonProps} children={translate(key)} />
          ))}
        </Navigation>
      </Header>

      <MainContainer style={{ padding: 0 }}>
        {routes.map(({ path, key, name }, index) => (
          <SecuredRoute path={path} key={key} children={views[index]} name={name} />
        ))}
      </MainContainer>
    </>
  );
};

export default AccountComponent;
