import React from 'react';

const InputTypes = Object.freeze({ checkbox: 'checkbox' });

export function extractFormElements<T>(keys: string[], elements: any): T {
  return keys.reduce((acc: T, key: string) => {
    const { value, checked, type } = elements[key] ?? {};

    (acc as any)[key] = type === InputTypes.checkbox ? checked : value;

    return acc;
  }, {} as T);
}

type FormProps = {
  children: React.ReactNode;
  onSubmit: (data: any) => void;
  onInput?: React.FormEventHandler<HTMLFormElement>;
  className?: string;
  name: string;
  formNames: Record<string, string>;
  style?: React.CSSProperties;
};
export const Form = React.forwardRef((props: FormProps, ref: any) => {
  const { children, onSubmit, className, name, formNames, onInput, style } = props;

  return (
    <form
      data-testid='form'
      style={style}
      onInput={onInput}
      ref={ref}
      name={name}
      onSubmit={event => {
        const elements = (event.target as HTMLFormElement).elements;
        const values = extractFormElements(Object.values(formNames), elements);

        onSubmit(values);

        event.preventDefault();
      }}
      className={className}
    >
      {children}
    </form>
  );
});
