import {useCleanPopup} from '../../../hooks/useCleanPopup';
import {useTranslation} from '../../../providers';
import {launchAnalysis} from '../../../services/analysis';
import {Translation} from '../../../static';
import {Loader} from '../../common';
import {RectanglePhoto} from '../../common/icons';
import {useAnalysis} from '../analysis-context';
import {AnalysisFooterComponent} from '../AnalysisFooter';
import {useMemo} from "react";
import {isEmpty} from 'lodash';

type Option = {
  title: string;
  text: string;
};

type Item = {
  title: string;
  text?: string;
  go: () => void;
  photo: string;
  options?: Option[];
};

const { summary } = Translation.analysis;

export const SummaryProperties = ({ setTime }: { setTime: any }) => {
  const { state, go, goBackward, setLaunched } = useAnalysis();
  const { translate } = useTranslation();
  const { show, clean } = useCleanPopup();

  const options: Option[] = Object.entries(state.options as Record<string, string>)
    .map(([key, value], index) => {
      const title = state.service.options[index].title ?? key;

      return [title, value];
    })
    .map(([title, text]) => ({ title, text }));

  const items: Item[] = [
    {
      title: translate(summary.analyse),
      text: state.service.name,
      go: () => go(0),
      photo: state.service.photo,
    },
    {
      title: translate(summary.file),
      text: state.dataset?.name,
      go: () => go(1),
      photo: '/file.png',
    },
    {
      title: `${translate(summary.options)}:`,
      go: () => go(3),
      photo: '/summary-preferences.png',
      options,
    },
  ];

  return (
    <>
      <section className='border' style={{ height: '100%', overflowY: 'scroll' }}>
        <section className='v-title'>{translate(summary.summaryTitle)}</section>
        <section className='summaryBody'>
          {items.map((item, index) => (
            <Row item={item} key={index} />
          ))}
        </section>
      </section>

      <AnalysisFooterComponent
        btnText={translate(summary.launch)}
        goBackward={goBackward}
        goForward={() => {
          show(<Loader />);
          launchAnalysis({
            datasetId: state.dataset?._id,
            serviceId: state.service?._id,
            options: state.options,
            organizationOfFile: state.validation?.organizationOfFile,
            identificationOfColumns: state.validation?.identificationOfColumns,
            selectedFiles: state.selectedFiles ?? []
          })
            .then(({ estimatedTime }) => setTime(estimatedTime))
            .catch(() => setTime(null))
            .finally(() => {
              setLaunched(true);
              clean();
            });
        }}
      />
    </>
  );
};

const text1Style = { fontSize: 16, fontWeight: 600 };
const text2Style = { fontSize: 15, fontWeight: 500 };

export const getOptionValue = (value: any, [yes, no]: [string, string]) => {
  if (value == null) return null;
  if (typeof value === 'object') {
    return (
      <>
        <span style={{ paddingRight: 5, paddingLeft: 10 }}>{value.from ?? '-'}</span>
        <span style={{ paddingRight: 5 }}>/</span>
        <span>{value.to ?? '-'}</span>
      </>
    );
  }

  if (value === true) return yes;
  if (value === false) return no;

  return <>{value}</>;
};

const OptionsComponent = ({options, values}: { options: Option[]; values: [string, string] }) => {
  const {state: {service}} = useAnalysis()
  const optionContext = service.options

  const filteredOptions: Option[] = useMemo(() => options.map(item => {
    const option = optionContext
        .filter(option => option.title === item.title && !isEmpty(option.optionList))
        .flatMap(option => option.optionList)
        .find(option => option.value === item.text)
    return option ? ({title: item.title, text: option.name}) : item
  }), [service.options]);

  return (
      <ul style={{paddingLeft: 25}}>
        {filteredOptions.map((item, index) => (
            <li key={index} style={{paddingBottom: 10}}>
              <span style={{...text1Style, paddingRight: 5}}>{item.title}:</span>
              <span style={text2Style}>{getOptionValue(item.text, values)}</span>
            </li>
        ))}
      </ul>
  );
};

const Row = ({ item }: { item: Item }) => {
  const { translate } = useTranslation();
  const values: [string, string] = [
    translate(Translation.account.deleteLanguage.yesBtn),
    translate(Translation.account.deleteLanguage.noBtn),
  ];

  return (
    <section className='row'>
      <section className='info'>
        <RectanglePhoto photo='' url={item.photo || ''} size={40} />

        <section className='text'>
          <span style={text2Style}>{item.title}</span>
          <span style={text1Style}>
            {item.text}
            {item.options ? <OptionsComponent options={item.options} values={values} /> : null}
          </span>
        </section>
      </section>
      <span className='link' onClick={() => item?.go()}>
        {translate(summary.modify)}
      </span>
    </section>
  );
};
