import {useCleanPopup} from '../../../hooks/useCleanPopup';
import {useAuth, useTranslation} from '../../../providers';
import {downloadFile, getResultOptions, previewResult} from '../../../services/results';
import {Result} from '../../../services/types/results';
import {Service} from '../../../services/types/settings';
import {Colors, dropShadow, FileExtensions, FileExtensionsLetters, FileTypes, Translation} from '../../../static';
import {dateFormatter, getExtension} from '../../../utils';
import {PreviewButton} from '../../common/PreviewButton';
import {RawRendererPopup} from '../../common/RawRendererPopup';
import {Circle, RectanglePhoto} from '../../common/icons';
import {ResultsOptionsPopup} from '../download/ResultOptionsPopup';
import {ResultsSummaryHeader, Row} from './styled';
import {useTooltipContext} from "../../../providers/tooltip-context";
import {DownloadResultButton} from "../../common";
import {useEffect, useRef, useState} from "react";

const labelStyle = {paddingRight: 10};
const allowedExtensions = Object.freeze([FileExtensions.CSV, FileExtensions.TXT]);

type ResultSummaryProps = {
  result: Result;
  service: Service | null | undefined;
};
export const ResultSummaryHeader = ({service, result}: ResultSummaryProps) => {
  const {show} = useCleanPopup();
  const {translate} = useTranslation();
  const {userInfo} = useAuth();
  const opacity = userInfo.user.accessRights.downloadResults ? 1 : 0.5;
  const {setShow, setMaxWidth, turnOnDefaultTooltip} = useTooltipContext()
  const labelRef = useRef(null);
  const [nameShown, setNameShown] = useState(result.datasetName);

  const onShowTooltip = () => {
    labelRef?.current && turnOnDefaultTooltip(labelRef, result.datasetName)
    setMaxWidth(2000)
  }
  const onHideTooltip = () => setShow(false)

  useEffect(() => {
    result.datasetName && setNameShown(result.datasetName)
  }, [result.datasetName]);

  useEffect(() => {
    if (labelRef && labelRef.current) {
      const itemDataSetName = document.getElementsByClassName('item-dataset-name')[0] as HTMLSpanElement;
      const nameSpan = labelRef.current as HTMLSpanElement;
      if (nameSpan.getBoundingClientRect().width >= itemDataSetName.getBoundingClientRect().width) {
        const numberChars = itemDataSetName.getBoundingClientRect().width / 30;
        const headStr = nameShown.slice(0, numberChars);
        const tailStr = nameShown.slice(nameShown.length - numberChars, nameShown.length)
        setNameShown(headStr + '...' + tailStr)
      }
    }
  }, [labelRef]);


  return (
      <ResultsSummaryHeader>
        <section className='header'>
          <section className={'grid-container'}>
            <section className={'item-dataset-name'}>
              <span className={'overflow'} ref={labelRef}
                    onMouseEnter={onShowTooltip}
                    onMouseLeave={onHideTooltip}
                    style={{cursor: "default"}}>
                {nameShown}
              </span>
            </section>
            <section className={'item-download'}>
              <PreviewButton
                  disabled={!allowedExtensions.includes(getExtension(result?.name) as FileExtensions)}
                  onClick={() => show(<RawRendererPopup callback={() => previewResult(result?._id)}/>)}
              />
              <DownloadResultButton
                  result={result}
                  accept={FileTypes[getExtension(result?.name) as FileExtensions]}
                  downloadFile={setProgress =>
                      downloadFile(
                          result?._id,
                          result?.resultFileSize ? result?.resultFileSize.toString() : null,
                          setProgress
                      )
                  }
                  downloadFileXLSX={
                    setProgress =>
                        downloadFile(
                            result?._id,
                            result?.resultFileSizeExcel ? result?.resultFileSizeExcel.toString() : null,
                            setProgress,
                            FileExtensionsLetters.XLXS,
                        )
                  }
                  name={result?.name}
                  disabled={!userInfo.user.accessRights.downloadResults}
              />
            </section>
          </section>

          <section className='table'>
            <Circle style={{filter: dropShadow}} size={70} bgColor={Colors.white}>
              <RectanglePhoto size={50} url={service?.photo ?? ''}/>
            </Circle>

            <section className='col'>
              <Row>
                <label style={labelStyle}>{translate(Translation.results.dateOfAnalysis)}:</label>
                {dateFormatter(result?.updatedAt)}
              </Row>

              <Row>
                <label style={labelStyle}>{translate(Translation.results.typeOfAnalysis)}:</label>
                {service?.name}
              </Row>
            </section>

            <section className='col'>
              <Row>
                <label style={labelStyle}>{translate(Translation.results.option)}:</label>
                <span
                    className='view'
                    onClick={() => {
                      show(<ResultsOptionsPopup options={getResultOptions(result, service)}/>, {
                        color: '',
                        opacity: 0.5,
                      });
                    }}
                >
                {translate(Translation.results.view)}
              </span>
              </Row>

              <Row>
                <label style={labelStyle}>{translate(Translation.results.origin)}:</label>
                {result?.source}
              </Row>
            </section>
          </section>
        </section>
      </ResultsSummaryHeader>
  );
};
