import { useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../providers';
import { getSortedServices } from '../../providers/services-context';
import { ClientService } from '../../services/types/settings';
import { Routes, Translation } from '../../static';
import { SmallTile } from '../common';
import { ScrollHeaderWithResults } from './ScrollHeader';
const { overview } = Translation;

const overviewServStyle = { height: 250, paddingTop: 20 };
export const OverviewServices = ({ services }: { services: ClientService[] }) => {
  const history = useHistory();
  const { translate } = useTranslation();
  const servicesRef = useRef<HTMLElement>(null);
  const sortedServices = useMemo(() => {
    const { activeServices, inactiveServices } = getSortedServices(services, undefined, 'enabled');
    return [...activeServices, ...inactiveServices];
  }, [services]);

  return (
    <section className='bSection' style={overviewServStyle}>
      <ScrollHeaderWithResults
        tilesRef={servicesRef}
        length={services.length}
        boxWidth={170}
        header={translate(overview.servicesSectionHeader)}
      />

      <section className='smServContainer' ref={servicesRef}>
        {sortedServices?.length ? (
          sortedServices.map((service, index) => {
            return (
              <SmallTile
                enabled={service.enabled}
                key={index}
                description={service.name}
                photo={service.photo}
                onClick={() =>
                  history.push(Routes.Results, {
                    steps: [{ isActive: false }, { isActive: true }],
                    service,
                    result: null,
                  })
                }
              />
            );
          })
        ) : (
          <section className='empty'>{translate(overview.noServices)}</section>
        )}
      </section>
    </section>
  );
};
