import styled from 'styled-components';
import { Colors, dropShadow } from '../../static';

export const MessageComponent = styled.section`
  color: ${({ color }: { color: string }) => color};
  background-color: ${Colors.white};
  filter: ${dropShadow};
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
