import * as React from 'react';
import styled from 'styled-components';
import { useClickOutside } from '../../hooks/use-click-outside';
import { useCleanPopup } from '../../hooks/useCleanPopup';
import { Colors } from '../../static';

const Container = styled.section`
  height: 100%;
  overflow: auto;
  padding: 5px;
  color: ${Colors.menuColor};
  font-size: 14px;

  details {
    padding: 5px 0;
  }

  summary {
    font-weight: 700;
    cursor: pointer;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  th {
    background-color: ${Colors.grey};
    color: ${Colors.white};
    padding: 5px;
    border-right: 3px solid ${Colors.white};
    border-bottom: 3px solid ${Colors.white};
  }

  tr {
    border-bottom: 3px solid ${Colors.white};
    background-color: ${Colors.lightGrey};
    color: ${Colors.menuColor};
  }

  tr:nth-child(even) {
    background: #e5e5e5;
  }

  td {
    padding: 5px;
    border-right: 3px solid ${Colors.white};
  }
`;

type Props = {
  className?: string;
  markdown: string;
};

export const Markdown = ({ markdown, className }: Props) => {
  const ref = React.useRef(null);
  const { clean } = useCleanPopup();
  useClickOutside(ref, clean);

  return (
    <Container ref={ref} className={className} dangerouslySetInnerHTML={{ __html: markdown }} />
  );
};
