import { Endpoint, FileExtensionsLetters } from '../static';
import { trimFileName } from '../utils';
import { AuthFetch } from './request';
import {
  AnalysisResults,
  DataAnalyzed,
  DuplicatePaymentsKpiData,
  FilesSummary,
  KPIData,
  KPIResponseData,
  KPIResponseDataObsolete,
  OutputIssues,
  Result,
  ResultSummary,
  StatusCode,
} from './types/results';
import { Service } from './types/settings';

type ResultsPayload = {
  clientId?: string;
  userId?: string;
  serviceId?: string;
};

const getMappedResults = (results: Result[]): Result[] => {
  return results.map(result => ({
    _id: result._id,
    name: result.name,
    datasetName: result.datasetName,
    updatedAt: result.updatedAt,
    statusCode: result.statusCode,
    statusText: result.statusText,
    analysisSettings: { options: result?.analysisSettings?.options ?? [] },
    source: result.source,
    service: result.service,
    resultFileSize: result.resultFileSize,
    resultFileSizeExcel: result.resultFileSizeExcel,
    isReport: result.isReport ?? false,
    tooBigExcelFile: result.tooBigExcelFile ?? false
  }));
};

export const getClientReportsByService = async (payload: ResultsPayload): Promise<Result[]> => {
  return AuthFetch.GET(
    `${Endpoint.results.getReportsByService}?clientId=${payload.clientId}&serviceId=${payload.serviceId}`
  );
};

export const getReport = (id = ''): Promise<{ embedUrl: string; embedToken: string }> => {
  return AuthFetch.GET(`${Endpoint.results.report}?reportId=${id}`);
};

export const getClientResultsByService = async (payload: ResultsPayload): Promise<Result[]> => {
  try {
    const { clientId, serviceId = '', userId } = payload;
    const user = clientId ? `clientId=${clientId}` : `userId=${userId}`;
    const results = await AuthFetch.GET<Result[]>(
      `${Endpoint.results.getClientResultsByService}?${user}&serviceId=${serviceId}`
    );

    return getMappedResults(results);
  } catch (err) {
    throw err;
  }
};

export const deleteResult = async (resultId: string): Promise<void> => {
  return AuthFetch.DELETE<void>(`${Endpoint.results.delete}/${resultId}`);
};

export const deleteReport = async (reportId: string): Promise<void> => {
  return AuthFetch.DELETE<void>(`${Endpoint.results.deleteReport}/${reportId}`);
};

export const downloadFile = async (
  resultId: string | undefined,
  resultFileSize?: string | null,
  setProgress?: void,
  extension: FileExtensionsLetters = FileExtensionsLetters.CSV
): Promise<any> => {
  return AuthFetch.GET<string>(
    `${Endpoint.results.download}/${resultId}/${extension}`,
    'blob',
    resultFileSize,
    setProgress
  );
};

export const previewResult = async (resultId?: string): Promise<Record<string, any>[]> => {
  return resultId === undefined ? [] : AuthFetch.GET(`${Endpoint.results.preview}/${resultId}`);
};

export const downloadFileOutsideApp = async (
  resultId: string,
  token: string,
  fileSize: string | null,
  setProgress: void | null,
  extension: FileExtensionsLetters = FileExtensionsLetters.CSV,
): Promise<string> => {
  return AuthFetch.GET<string>(
    `${Endpoint.results.downloadOutside}/${resultId}/${token}/${extension}`,
    'blob',
    fileSize,
    setProgress
  );
};

export const getLast10Results = async ({ clientId, userId }: ResultsPayload): Promise<Result[]> => {
  const link = clientId ? `clientId=${clientId}` : `userId=${userId}`;

  try {
    const results = await AuthFetch.GET<Result[]>(`${Endpoint.results.getLatestResults}?${link}`);

    return results
      .map(result => ({
        ...result,
        datasetName: result?.isReport ? result.name : result.datasetName,
        name: trimFileName(result.name),
      }))
      .reverse();
  } catch (err) {
    throw err;
  }
};

export const getFilesSummary = async (clientId: string): Promise<FilesSummary> => {
  return AuthFetch.GET<FilesSummary>(`${Endpoint.filesSummary}/${clientId}`);
};

export const getResultsWithService = (
  services: Service[] | null,
  results: Result[],
  translations?: Record<string, string>
): Result[] => {
  if (!Array.isArray(results)) return [];

  const servMap = services?.reduce((acc: Record<string, string>, service) => {
    return { ...acc, [service._id]: service.name };
  }, {});

  return results
    .filter(({ service }) => Boolean(servMap?.[service]))
    .map(({ service, ...result }: Result) => ({
      ...result,
      statusCode: statusCodeMapping(result.statusCode, translations),
      serviceId: service,
      service: servMap?.[service] ?? '',
    }));
};

const statusCodeMapping = (statusCode : string | StatusCode, translations?: Record<string, string>) => {
  if (statusCode === StatusCode.InProgress || statusCode === StatusCode.InLaunchProgress) {
    return translations?.[StatusCode.InLaunchProgress] ?? statusCode;
  } else {
    return translations?.[statusCode] ?? statusCode;
  }
}

type ResultAndToken = { token: string; resultId: string };
type ResultWithToken = Result & { service: Service };

export const getResultById = async (payload: ResultAndToken): Promise<ResultWithToken> => {
  try {
    const { token, resultId } = payload;
    const result = await AuthFetch.GET<ResultWithToken>(
      `${Endpoint.results.getResultByIdAndToken}?token=${token}&resultId=${resultId}`
    );
    result.name = trimFileName(result.name);

    return result;
  } catch (err) {
    throw err;
  }
};

export const getResultsSummary = async (payload: ResultsPayload): Promise<ResultSummary[]> => {
  const { clientId, userId } = payload;
  const link = clientId ? `clientId=${clientId}` : `userId=${userId}`;

  return AuthFetch.GET<ResultSummary[]>(`${Endpoint.results.getResultsSummary}?${link}`);
};

export const getKpiData = async (resultId?: string): Promise<KPIData | null> => {
  try {
    const rows = await AuthFetch.GET<Array<KPIResponseDataObsolete & KPIResponseData>>(
      `${Endpoint.results.getKPIData}?resultId=${resultId}`
    );

    if (!rows) throw new Error();
    const isObsoleteData = rows[0].hasOwnProperty('Output_Issues_Type1');

    const data = isObsoleteData ? transformData(rows) : rows;
    const mappedRows = mapRawData(data).sort((a, b) => +a.year - +b.year);

    return getFinalData(mappedRows);
  } catch (err) {
    throw err;
  }
};

export const transformData = (rows: KPIResponseDataObsolete[]): KPIResponseData[] => {
  const maxNumberOfIssues = 50;
  return rows.reduce((acc: KPIResponseData[], next) => {
    const {
      Input_Amounts,
      Input_Transactions,
      Input_Vendors,
      Output_Amounts,
      Output_Vendors,
      Year,
      Input_vendors,
      Output_vendors,
    } = next;

    const Output_Issues: OutputIssues[] = [];

    for (let i = 1; i < maxNumberOfIssues; i++) {
      const item = (next as any)[`Output_Issues_Type${i}`];
      const name = `Type${i}`;
      if (item === undefined) break;
      Output_Issues.push({ Name: name, Type: name, Issues_Number: +item });
    }

    acc.push({
      Input_Amounts,
      Input_Transactions,
      Input_Vendors: Input_Vendors ?? Input_vendors,
      Output_Amounts,
      Output_Vendors: Output_Vendors ?? Output_vendors,
      Year,
      Output_Issues,
    });
    return acc;
  }, []);
};

export const mapRawData = (data: KPIResponseData[]): DuplicatePaymentsKpiData[] => {
  return data.reduce((acc, next) => {
    const d: DuplicatePaymentsKpiData = {
      inputAmounts: +next.Input_Amounts,
      inputTransactions: +next.Input_Transactions,
      inputVendors: +(next.Input_Vendors ?? next.Input_vendors),
      outputAmounts: +next.Output_Amounts,
      outputVendors: +(next.Output_Vendors ?? next.Input_vendors),
      outputIssues: next.Output_Issues,
      year: next.Year,
      issues: next.Output_Issues.reduce((acc, next) => {
        acc += +(next.Issues_Number || next.Issues_number || 0);
        return acc;
      }, 0),
    };

    acc.push(d);

    return acc;
  }, [] as DuplicatePaymentsKpiData[]);
};

export const getFinalData = (data: DuplicatePaymentsKpiData[]): KPIData => {
  const response = data.reduce(
    (acc, next) => {
      acc.dataAnalyzed.totalAmount += +next.inputAmounts;
      acc.dataAnalyzed.transactions += +next.inputTransactions;
      acc.dataAnalyzed.vendors += +next.inputVendors;
      acc.analysisResults.vendors += +next.outputVendors;
      acc.analysisResults.totalAmmountOfIssues += +next.outputAmounts;
      acc.analysisResults.issues += next.issues;
      acc.rawData.push(next);

      return acc;
    },
    {
      dataAnalyzed: { totalAmount: 0, transactions: 0, vendors: 0 } as DataAnalyzed,
      analysisResults: { vendors: 0, issues: 0, totalAmmountOfIssues: 0 } as AnalysisResults,
      rawData: [] as DuplicatePaymentsKpiData[],
    }
  );
  response.analysisResults.percentageIssues =
    (response.analysisResults.issues / response.dataAnalyzed.transactions) * 100;
  response.analysisResults.totalPercentageIssues =
    (response.analysisResults.totalAmmountOfIssues / response.dataAnalyzed.totalAmount) * 100;

  return response;
};

export const getResultOptions = (result: Result, service: Service | null | undefined) => {
  const optionEntries = result?.analysisSettings?.options ?? {};
  const options = service?.options.reduce((acc: Array<[string, any]>, next) => {
    if (optionEntries.hasOwnProperty(next.name)) {
      acc.push([next.title, optionEntries[next.name]]);
    }
    return acc;
  }, []);
  return options;
};
