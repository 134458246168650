/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import {useState} from 'react';
import styled from 'styled-components';
import {useCleanPopup} from '../../hooks/useCleanPopup';
import {useDownload} from '../../hooks/useDownload';

import {useTranslation} from '../../providers';
import {Colors, FileExtensions, FileTypes, Translation} from '../../static';
import {DraggableInput} from './DraggableInput';
import {Circle, CrossIcon, DownloadIcon} from './icons';
import {getDenominatedSizeValue} from '../../utils';
import {ArrowUp} from './icons/ArrowUp';
import {ArrowDown} from './icons/ArrowDown';
import {Result} from "../../services/types/results";

import "./result-download-button.scss";

const Container = styled.section`
  justify-self: center;
  align-self: center;

  .wrapper {
    position: relative;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;

  }

  .dropdown__active {
    background-color: white;
  }

  .download-result-dropdown-content {
    display: block !important;
  }

  .dropdown-content {
    position: absolute;

    display: block;
    background-color: #ffffff;
    min-width: 160px;

    z-index: 1;
    height: 0;

    .extension-dropdown-button {
      width: 230px;
      display: flex;
      padding: 10px;

      .addFileExtensions-style {
        width: 45%;
        font-size: 16px;
        color: #000000;
      }

      .fileSize-style {
        width: 45%;
        color: #4B5257;
        font-size: 14px
      }

      .download-circle-section-style {
        width: 10%;
      }
    }

    ul {
      background: #fff;
      margin: 0;
      padding: 0;
      overflow: hidden;
      height: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .dropdown-card {
      display: flex
    }
  }

  .dropdown-content__active {
    display: block;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 18px;
    border-radius: 5px;
    border: 2px solid #3A4146;
    height: auto !important;

    -webkit-transition: height 0.2s ease;
    -webkit-transition-delay: 0s;

  }

  .extension-dropdown-button:hover {
    background-color: #f2f2f2;
  }

  .addDataset {
    &:hover download-result-dropdown-content {
      display: block !important;

    }

    width: 270px;
    height: 60px;
    border-radius: 60px;
    background-color: ${Colors.menuColor};
    color: ${Colors.white};
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .text {
      padding: 5px 3px 5px 20px;
      width: 205px;
      height: 100%;
      display: grid;
      align-items: center;

      .title {
        height: 100%;
        font-size: 16px;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        :hover {
          text-overflow: clip;
          white-space: normal;
        }
      }

      .fileTypes {
        font-size: 12px;
        color: ${Colors.grey};
      }
    }
  }

  .addDataset__active {
    background-color: white;
    color: #000000;
    background-color: transparent;
    border-bottom: 0;
  }
}
`;

type CommonProps = { style?: React.CSSProperties; className?: string };
type UploadFileProps = {
  accept: FileExtensions[] | string[];
  onChange: (payload: { file: any; fileName?: string }) => void;
  uploadResources?: boolean;
} & CommonProps;

type DownloadFileProps = {
  downloadFile: (arg: any) => Promise<any>;
  downloadFileXLSX?: (arg: any) => Promise<any>;
  accept: string; // accept one of file types in '../../static.FileTypes'
  name: string;
  disabled?: boolean;
  result: Result;
  hasToken?: boolean;
} & CommonProps;

const {upload} = Translation.analysis;
export const UploadFile = ({className, onChange, accept, style, uploadResources}: UploadFileProps) => {
  const {translate} = useTranslation();
  const {show, clean} = useCleanPopup();
  return (
      <Container
          className={className}
          style={style}
          onClick={() => show(<DraggableInput onClose={clean} accept={accept} onChange={onChange}/>)}>
        <section className='addDataset'>
          <section className='text'>
            <div className='title'>{translate(upload.addFileTitle)}</div>
            <div className='fileTypes'>{translate(upload.addFileExtensions)}</div>
          </section>

          {uploadResources ?
              <Circle size={65} bgColor={Colors.analysisCircleBg} children={<CrossIcon/>}/> :
              <Circle size={65} bgColor={Colors.analysisCircleBg} children={<CrossIcon/>}/>
          }

        </section>
      </Container>
  );
};

export const DownloadResultButton = (props: DownloadFileProps) => {
  const {translate} = useTranslation();

  const {
    downloadFile,
    downloadFileXLSX,
    name,
    accept = FileTypes[FileExtensions.CSV],
    result,
    hasToken
  } = props;

  const [hover, setHover] = useState(false);

  const downloadResultDropdown = [
    {
      addFileExtensions: FileExtensions.CSV,
      size: result?.resultFileSize,
    },
  ];

  if (!!result?.tooBigExcelFile) {
    // do nothing in purpose
  } else {
    downloadResultDropdown.push({
      addFileExtensions: FileExtensions.XLXS,
      size: result?.resultFileSizeExcel,
    });
  }

  const downloadCSV = useDownload(downloadFile, name.split('.')[0] ?? 'download', accept);
  const downloadXLSX = useDownload(
      downloadFileXLSX as (arg: any) => Promise<any>,
      name.split('.')[0] ?? 'download',
      FileTypes[FileExtensions.XLXS]
  );

  return (
      <div className="menu-container"
           style={{marginLeft: "60px"}}
           onMouseEnter={() => setHover(true)}
           onMouseOver={() => setHover(true)}
           onMouseLeave={() => setHover(false)}>
        <ul className="download-menu">
          <li className="dropdown dropdown-2">
            <div className="dropdown-title"><span>{translate(Translation.results.downloadResults)} </span> <Circle size={65}
                                                                                                                   style={{
                                                                                                                     marginRight: "-5px",
                                                                                                                     marginTop: "-4.5px"
                                                                                                                   }}
                                                                                                                   bgColor={Colors.analysisCircleBg}
                                                                                                                   children={hover ? <ArrowDown/> :
                                                                                                                       <ArrowUp/>}/></div>
            <ul className={`dropdown_menu dropdown_menu-2 dropdown_menu--${hasToken}`}
            >
              {downloadResultDropdown.map(
                  (dropdown: { addFileExtensions: string; size?: number }, index: number) => {
                    const size = getDenominatedSizeValue(dropdown.size);
                    return (
                        <li className={`dropdown-card dropdown_item-${index} dropdown_item${size}-disable`}
                            key={dropdown.addFileExtensions}>
                          <div
                              className='extension-dropdown-button'
                              onClick={() => {
                                if (accept === FileExtensions.EMPTY) return;

                                switch (dropdown.addFileExtensions) {
                                  case FileExtensions.XLXS:
                                    downloadXLSX();
                                    break;
                                  case FileExtensions.CSV:
                                    downloadCSV();
                                    break;
                                }
                              }}>
                            <div className='addFileExtensions-style'>{dropdown.addFileExtensions}</div>

                            <div className='fileSize-style'>{size}</div>
                            <div className='download-circle-section-style'>
                              <Circle size={20} children={<DownloadIcon/>} style={{marginRight: -15}}/>
                            </div>
                          </div>
                        </li>
                    )
                  }
              )}
            </ul>
          </li>

        </ul>
      </div>
  )
}
