import createStore from '../../providers/create-store';

type Action = { type: 'setCounter'; counter: number };
type State = {
  counter: number;
};
const initialState: State = {
  counter: 0,
};

function reducer(initialState: State, action: Action): State {
  switch (action.type) {
    case 'setCounter':
      return {
        ...initialState,
        counter: action.counter,
      };
    default:
      throw new Error('Action not implemented');
  }
}

export const {
  StoreProvider: FileProvider,
  useDispatch: useFileDispatch,
  useStore: useFiles,
} = createStore<State, Action>(reducer, initialState);
