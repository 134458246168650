import styled from 'styled-components';
import {Result} from '../../services/types/results';
import {Colors, dropShadow} from '../../static';
import {dateFormatter} from '../../utils';
import {CreditCardBgIcon, FileIcon, SearchIconMain} from './icons';
import React, {useRef} from 'react';
import {useTooltipContext} from "../../providers/tooltip-context";

const Container = styled.section`
  margin: 0 20px;
  min-width: 230px;
  max-width: 230px;
  height: 265px;
  background-color: ${Colors.white};
  filter: ${dropShadow};
  padding: 20px;
  border-radius: 30px;
  display: grid;
  grid-template-rows: 90px 100px 35px;
`

const BodyContainer = styled.section`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .overflow {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tooltip {
    display: none;
    position: absolute;
    padding: 5px;
    color: ${Colors.menuColor};
    font-size: 15px;
    background-color: ${Colors.bgColorTransactions};
    border-radius: 3px;
    font-weight: 600;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Service = styled.span`
  border-radius: 25px;
  font-size: 14px;
  height: 38px;
  display: grid;
  padding-left: 15px;
  grid-template-columns: 20px 1fr;
  align-items: center;
  background-color: ${Colors.tileBtnBgColor};
  color: ${Colors.menuColor};
  cursor: auto;
`;

const ViewResults = styled.section`
  color: ${Colors.orange};
  font-weight: 800;
  cursor: pointer;
  display: grid;
  grid-template-columns: 15px minmax(0, 1fr);
  align-items: flex-end;

  svg {
    width: 14px;
    fill: rgb(255, 118, 77);
    transform: rotateY(180deg);
  }
`;

type BigTileProps = {
  result: Result;
  text: string;
  viewResultsHandler: () => void;
};

export const BigTile = ({result, text, viewResultsHandler}: BigTileProps) => {
  const {setShow, setMaxWidth, turnOnDefaultTooltip} = useTooltipContext()
  const labelRef = useRef(null);

  const onShowTooltip = () => {
    labelRef?.current && turnOnDefaultTooltip(labelRef, result.datasetName)
    setMaxWidth(500)
  }
  const onHideTooltip = () => setShow(false)

  return (
      <Container>
        <section style={{alignSelf: 'center', justifySelf: 'center'}}>
          <FileIcon/>
        </section>

        <BodyContainer>
          <span
              className='overflow'
              ref={labelRef}
              onMouseEnter={onShowTooltip}
              onMouseLeave={onHideTooltip}
              style={{cursor: "default"}}
          >
            {result?.datasetName}
          </span>
          <section style={{color: Colors.grey, fontSize: 14}}>
            {dateFormatter(result?.updatedAt)}
          </section>

          <Service>
            <CreditCardBgIcon/>
            <span style={{paddingLeft: 10}}>{result?.service}</span>
          </Service>
        </BodyContainer>

        <ViewResults onClick={viewResultsHandler}>
          <SearchIconMain/>
          <span style={{fontSize: 13, marginLeft: '5px'}}>{text}</span>
        </ViewResults>
      </Container>
  );
};
