import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AnalysisState } from '../../services/types/analysis';
import { AnalysisProvider, useAnalysis } from './analysis-context';
import { AnalysisHeaderComponent } from './AnalysisHeader';
import { Steps } from './steps';
import './styles.scss';

const Container = styled.section`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 260px minmax(0, 1fr);
  min-width: 1300px;
  overflow-x: auto;
`;

const MainContainer = styled.section`
  display: flex;
  justify-content: center;

  .content {
    @media (min-width: 1401px) {
      width: 85%;
    }

    @media (max-width: 1400px) {
      width: 80%;
    }
  }
`;

const MainComponent = () => {
  const { steps } = useAnalysis();
  const [{ Component }] = steps.filter(step => step.isActive);

  return (
    <MainContainer>
      <section className='content'>
        <Component />
      </section>
    </MainContainer>
  );
};
export const AnalysisComponent = () => {
  const { state }: any = useLocation();
  const initialState = state?.initialState ?? ({} as AnalysisState);
  const steps = state?.steps
    ? Steps.map((step, index) => ({
        ...step,
        ...state.steps[index],
      }))
    : Steps;

  return (
    <AnalysisProvider state={initialState} steps={steps}>
      <Container className='analysis'>
        <AnalysisHeaderComponent />
        <MainComponent />
      </Container>
    </AnalysisProvider>
  );
};
