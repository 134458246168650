import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../static';
import { FieldContainer } from './styled';

export type TextFieldProps = {
  value?: string | number;
  defaultValue?: string | number;
  name?: string;
  errorMsg?: string;
  label?: string;
  radius?: number;
  height?: number;
  padding?: number;
  type?: 'text' | 'number' | 'password';
  required?: boolean;
  readonly?: boolean;
  placeholder?: string;
  children?: React.ReactNode;
  isValidFn?: (value: string | number) => boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  hide?: boolean;
  inline?: boolean;
  min?: string;
  pattern?: string;
  style?: React.CSSProperties;
  maxLength?: number;
};

type ContainerProps = {
  padding?: number;
  isError?: boolean;
  radius?: number;
};

const InputContainer = styled.section`
  display: flex;
  align-items: center;
  border: ${({ isError = false }) => (isError ? '1px solid red' : 'none')};
  border-radius: ${({ radius = 0 }: ContainerProps) => radius}px;
  padding: ${({ padding }) => padding}px;
  background-color: ${Colors.tileBtnBgColor};
`;

const Input = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 15px;
  background-color: ${Colors.tileBtnBgColor};
`;

export const TextField = React.forwardRef(
  (
    {
      errorMsg,
      label,
      radius,
      padding = 8,
      height = 45,
      children,
      isValidFn = (value: string | number) => !!value,
      hide = false,
      inline = false,
      readonly,
      style,
      maxLength,
      ...rest
    }: TextFieldProps,
    ref: any
  ) => {
    const [isError, setIsError] = React.useState(false);

    return (
      <FieldContainer
        style={style}
        height={height}
        hide={hide}
        hasLabel={Boolean(label)}
        className={inline ? '_inline' : '_block'}
      >
        {label ? <label>{label}</label> : null}

        <InputContainer isError={isError} radius={radius} padding={padding}>
          <Input
            ref={ref}
            style={{ cursor: readonly ? 'not-allowed' : 'default' }}
            disabled={readonly}
            autoComplete=''
            maxLength={maxLength}
            onInput={({ target }) => {
              if (!rest.required) {
                return;
              }

              const { value } = target as any;
              const { msg, isErr } = isValidFn?.(value)
                ? { isErr: false, msg: '' }
                : { msg: errorMsg, isErr: true };

              (target as any)?.setCustomValidity?.(msg);
              setIsError(isErr);
            }}
            onInvalid={({ target }) => {
              setIsError(true);
              (target as any)?.setCustomValidity?.(errorMsg);
            }}
            {...rest}
          />
          {children}
        </InputContainer>
      </FieldContainer>
    );
  }
);
