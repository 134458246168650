export const ArrowDown = () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 0.96 0.96"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h0.96v0.96H0z" fill="none"/>
      <g id="Shopicon">
        <path
            points="24,29.172 9.414,14.586 6.586,17.414 24,34.828 41.414,17.414 38.586,14.586  "
            d="M0.48 0.583L0.188 0.292L0.132 0.348L0.48 0.697L0.828 0.348L0.772 0.292Z"
        />
      </g>
    </svg>
);
