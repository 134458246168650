import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../providers';
import { Dataset } from '../../../services/types/analysis';
import { Result } from '../../../services/types/results';
import { Colors, Translation } from '../../../static';

const Container = styled.section`
  color: ${Colors.menuColor};
  font-weight: 500;
  font-size: 14px;

  .style {
    border-radius: 15px;
    padding: 2px 10px;
  }
`;
type Translate = (key: string, params?: any[] | undefined) => string;

export const getStatusRenderer = (statusType: 'Code' | 'Status') =>
  function StatusRenderer({ data }: { data: Dataset & Result }) {
    const { translate } = useTranslation();

    return (
      <Container>
        {statusType === 'Status'
          ? getStatus(data.statusForFilter ?? data.status, translate)
          : getStatusByCode(data.statusCode as any, translate)}
      </Container>
    );
  };

const { table } = Translation.inputFiles;

function getStatus(status: string, translate: Translate): React.ReactNode {

  switch (status) {
    case translate(table.analyzed):
      return (
        <span className='style' style={{ backgroundColor: Colors.lightGreen }}>
          {translate(table.analyzed)}
        </span>
      );
    case translate(table.toValidate):
      return (
        <span className='style' style={{ backgroundColor: Colors.lightBlue }}>
          {translate(table.toValidate)}
        </span>
      );
    case translate(table.validated):
      return (
        <span className='style' style={{ backgroundColor: Colors.lightBlue }}>
          {translate(table.validated)}
        </span>
      );
    default:
      return '';
  }
}

function getStatusByCode(status: string, translate: Translate): React.ReactNode {
  switch (status) {
    case translate(table.inProgress):
      return (
        <span className='style' style={{ backgroundColor: Colors.inProgress }}>
          {translate(table.inProgress)}
        </span>
      );
    case translate(table.done):
      return (
        <span className='style' style={{ backgroundColor: Colors.lightGreen }}>
          {translate(table.done)}
        </span>
      );
    case translate(table.error):
      return (
        <span className='style' style={{ backgroundColor: Colors.error }}>
          {translate(table.error)}
        </span>
      );
    default:
      return '';
  }
}
