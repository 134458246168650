import styled from 'styled-components';
import { useAuth, useTranslation } from '../../providers';
import { ResultSummary } from '../../services/types/results';
import { ClientService } from '../../services/types/settings';
import { Colors, dropShadow, Translation } from '../../static';
import {
  AnalyzedIcon,
  Circle,
  InProgressIcon,
  ReadyIcon,
  RectanglePhoto,
  SearchIconMain,
} from '../common/icons';
import { useResults, useResultsDispatch } from './results-context';
import * as React from 'react';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px 20px;
  background-color: ${Colors.white};
  filter: ${dropShadow};
  width: 300px;
  height: 370px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.menuColor};
  position: relative;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .row span {
    display: flex;
    align-items: center;
  }
  
  .row, .allResults {
    height: 45px
  }

  .allResults {
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    color: rgb(255, 118, 77);
    text-decoration: none;,
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .allResults svg {
    margin-right: 7px;   
    height: 16px;
    width: 16px;    
    fill: rgb(255, 118, 77);
    transform: rotateY(180deg); 
  }
  
  // the order has to be like this. cursor will override in case the user doesn't have access to results.
  .disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const ResultTile = ({
  result,
  service,
}: {
  result: ResultSummary;
  service?: ClientService;
}) => {
  //for update
  const { translate } = useTranslation();
  const dispatch = useResultsDispatch();
  const { steps } = useResults();
  const { userInfo } = useAuth();

  return (
    <Container>
      <Circle size={100} style={{ filter: dropShadow }} bgColor={Colors.white}>
        <RectanglePhoto photo={undefined} size={70} url={service?.photo ?? ''} />
      </Circle>

      <span className='row' style={{ fontWeight: 700, fontSize: 16, justifyContent: 'center' }}>
        {result.serviceName}
      </span>

      <section className='row'>
        <span style={{ fontWeight: 700, fontSize: 35, minWidth: '40px' }}>
          {result.readyToLaunch}
        </span>
        <span style={{ fontSize: 14, fontWeight: 600 }}>
          {translate(Translation.overview.readyToLaunch)}
        </span>
        <span>
          <ReadyIcon border={false} />
        </span>
      </section>

      <section className='row'>
        <span style={{ fontWeight: 700, fontSize: 35, minWidth: '40px' }}>{result.inProgress}</span>
        <span style={{ fontSize: 14, fontWeight: 600 }}>
          {translate(Translation.overview.inProgress)}
        </span>
        <span>
          <InProgressIcon border={false} />
        </span>
      </section>

      <section className='row'>
        <span style={{ fontWeight: 700, fontSize: 35, minWidth: '40px' }}>{result.analyzed}</span>

        <span style={{ fontSize: 14, fontWeight: 600 }}>
          {translate(Translation.overview.analyzed)}
        </span>

        <span>
          <AnalyzedIcon border={false} />
        </span>
      </section>

      <span
        className={`allResults ${userInfo.user.accessRights.viewResults ? '' : 'disabled'}`}
        onClick={() => {
          if (userInfo.user.accessRights.viewResults) {
            const [FirstStep, SecondStep] = steps;
            dispatch({
              type: 'setState',
              state: {
                result: null,
                service,
                steps: [
                  { ...FirstStep, isActive: false },
                  { ...SecondStep, isActive: true },
                ],
              },
            });
          }
        }}
      >
        <SearchIconMain />
        {translate(Translation.settings.users.accessRights.viewResults)}
      </span>
    </Container>
  );
};
