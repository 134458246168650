import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

const Container = styled.section`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .input {
    padding: 3px 2px;
    display: grid;
    grid-template-columns: 25px minmax(0, 1fr);
    align-items: center;
  }
`;

type Checkbox = {
  label: string;
  selected: boolean;
};
export const EnumFilter = forwardRef((props: any, ref) => {
  const [values, setValues] = useState<Checkbox[]>([]);
  useEffect(() => {
    setValues(
      props.values?.map((label: string) => ({
        label,
        selected: false,
      }))
    );
  }, [props.values]);

  useImperativeHandle(ref, () => {
    return {
      isFilterActive: () => true,
      doesFilterPass(params: any) {
        const value = params.data[props.colDef.field];
        const elem = values.find(el => el.label === value);
        const allValid = values.every(({ selected }) => !selected);

        return elem?.selected || allValid;
      },
    };
  });
  useEffect(() => props.filterChangedCallback(), [props, values]);

  return (
    <Container>
      {values.map(({ label, selected }, index) => {
        return (
          <span key={index} className='input'>
            <input
              type='checkbox'
              defaultChecked={selected}
              onChange={event => {
                setValues(values =>
                  values.map((value, idx) => ({
                    ...value,
                    selected: idx === index ? event.target.checked : value.selected,
                  }))
                );
              }}
            />
            <span>{label}</span>
          </span>
        );
      })}
    </Container>
  );
});
