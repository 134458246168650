import * as React from 'react';
import styled from 'styled-components';
import { Button, TextField } from '.';
import { useTranslation } from '../../providers';
import { Colors, dropShadow, FileExtensions, FileTypes, Translation } from '../../static';
import { AddFileIcon } from './icons/AddFile';
import { CloseIcon } from './icons';
import { getFileSizeFromLocalStorage } from '../../services/settings';
import { Dataset } from '../../services/types/analysis';

const Container = styled.section`
  width: 600px;
  height: 300px;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .draggable {
    width: 80%;
    height: 150px;
    border-radius: 5px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: ${Colors.tileBtnBgColor};

    .file {
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      width: 150px;
      height: 40px;
      border-radius: 15px;
      padding: 5px;
      background-color: ${Colors.white};
      filter: ${dropShadow};
    }
  }

  .btn {
    margin-right: 20px;
    width: 70px;
    height: 35px;
  }
`;

const stopEvent = (event: any) => {
  event.preventDefault();
  event.stopPropagation();
};

const addEvents = () => {
  document.body.addEventListener('dragover', stopEvent);
  document.body.addEventListener('drop', stopEvent);
};

const removeEvents = () => {
  document.body.removeEventListener('dragover', stopEvent);
  document.body.removeEventListener('drop', stopEvent);
};

type Props = {
  onChange: (payload: { file: any; fileName?: string }) => void;
  onClose: () => void;
  accept: FileExtensions[] | string[];
  data?: Dataset;
};
const defaultStyle = { border: `2px solid ${Colors.grey}` };
const { upload } = Translation.analysis;

const isFiletypeSupported = (extensions: FileExtensions[] | string[], type?: string): boolean => {
  return extensions.some(extension => FileTypes[extension as FileExtensions] === type);
};

export const DraggableInput = ({ onClose, accept, onChange, data }: Props) => {
  const { translate } = useTranslation();
  const [style, setStyle] = React.useState(defaultStyle);
  const textRef = React.useRef<HTMLInputElement>();
  const labelRef = React.useRef<any>();
  const [{ file, errorMsg }, setFile] = React.useState<{ file: any; errorMsg: string }>({
    file: null,
    errorMsg: '',
  });
  const [fileName, setFileName] = React.useState<string>('');
  const oneGB = getFileSizeFromLocalStorage();

  React.useEffect(() => {
    addEvents();

    if(data != null) {
      setFileName(data.name)
      if(textRef != undefined && textRef.current != undefined) {
        textRef.current.value = data.name
      }
    }

    return removeEvents;
  }, []);

  const generatePayload = (selectedFile: File) => {
    const fileSizeInKB = Number(selectedFile.size) / 1024 ?? 0; // the current size is in bytes.
    if (fileSizeInKB >= oneGB) {
      return {
        file: null,
        errorMsg: `${translate(
            upload.excedeedFileSize
        )} - fileSize = ${fileSizeInKB}kb - limitSize = ${oneGB}kb`,
      };
    } else if (selectedFile && selectedFile.name) {
      const filenameArr = selectedFile.name.split('.');
      const extension = filenameArr.pop();
      const filenameWithoutExtension = filenameArr.join('.');

      if (filenameWithoutExtension.length > 100) {
        const newFilename = filenameWithoutExtension.substring(0, 100) + '.' + extension;
        const newFile = new File([selectedFile], newFilename, {
          type: selectedFile.type
        });
        return {
          file: newFile,
          errorMsg: ''
        };
      } else {
        return {
          file: selectedFile,
          errorMsg: ''
        }
      }

    } else {
      return {
        file: selectedFile,
        errorMsg: ''
      };
    }
  }

  const isDisabled = () => {
    if(data) {
      return fileName == ''
    }

    return !file
  }

  const datasetNameChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value)
  }

  return (
    <Container>
      <CloseIcon size={15} onClose={onClose} />
      <TextField
        name='datasetName'
        ref={textRef}
        label={translate(upload.datasetName)}
        inline
        style={{ width: '80%' }}
        maxLength={100}
        onChange={datasetNameChange}
      />

      { data == null ? (
        <section
          className='draggable'
          style={style}
          onDragEnter={event => {
            stopEvent(event);
            setStyle({ border: `2px dashed ${Colors.grey}` });
          }}
          onDragLeave={({ target, relatedTarget }) => {
            const isOk = target !== labelRef.current && !labelRef.current.contains(relatedTarget);
            if (isOk) setStyle(defaultStyle);
          }}
          onDrop={event => {
            const [file] = event.dataTransfer.files as any;

            if (isFiletypeSupported(accept, file?.type)) {
              const payload = generatePayload(file)
              setFile(payload);

            } else setFile({ file: null, errorMsg: translate(upload.unsupportedFile, [file.type]) });

            setStyle(defaultStyle);
            stopEvent(event);
          }}
        >
          <input
            style={{ display: 'none' }}
            id='file'
            type='file'
            accept={accept.join(',')}
            onChange={event => {
              if (event.target.files?.length) {
                const [file] = event.target.files as any;

                if (isFiletypeSupported(accept, file?.type)) {
                  const payload = generatePayload(file)
                  setFile(payload);

                } else
                  setFile({ file: null, errorMsg: translate(upload.unsupportedFile, [file.type]) });

                setStyle(defaultStyle);
              }
            }}
          />

          <label htmlFor='file' className='file' ref={labelRef}>
            <AddFileIcon />
            <span style={{ alignSelf: 'center', pointerEvents: 'none' }}>
              {translate(upload.selectFile)}
            </span>
          </label>

          {file ? (
            <span style={{ fontSize: 13, fontWeight: 600, pointerEvents: 'none', wordBreak: 'break-word', margin: '0 5px 0 5px' }}>{file?.name}</span>
          ) : (
            <span
              style={{ pointerEvents: 'none', color: errorMsg ? Colors.error : Colors.menuColor, wordBreak: 'break-word', margin: '0px 10px' }}
            >
              {errorMsg ? errorMsg : translate(upload.dropCsvText)}
            </span>
          )}
        </section>
        ) : (<></>)}

      <section>
        <Button onClick={onClose} className='btn'>
          {translate(Translation.cancel)}
        </Button>
        <Button
          disabled={isDisabled()}
          onClick={() => {
            if (file) {
              onChange({ file, fileName: textRef.current?.value });
              onClose();
            }
            if(data) {
              onChange({ file, fileName: textRef.current?.value });
              onClose();
            }
          }}
          className='btn'
        >
          {translate(Translation.ok)}
        </Button>
      </section>
    </Container>
  );
};
