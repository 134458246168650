import { Colors } from '../../../static';

export const BigErrorIcon = ({ size = 32 }: { size?: number }) => (
  <svg
    height={size}
    width={size}
    style={{ overflow: 'visible' }}
    viewBox='0 0 32 32'
    xmlSpace='preserve'
  >
    <g>
      <g>
        <g>
          <circle cx='16' cy='16' id='BG' r='16' style={{ fill: Colors.error }} />
          <polygon
            points='24,10.1 21.9,8 16,13.9 10.1,8 8,10.1 13.9,16 8,21.9 10.1,24 16,18.1 21.9,24 24,21.9 18.1,16'
            style={{ fill: Colors.white }}
          />
        </g>
      </g>
    </g>
  </svg>
);
