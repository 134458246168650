import * as React from 'react';
import styled from 'styled-components';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { useTranslation } from '../../../providers';
import {checkDatasets, getAutoColSeparator} from '../../../services/analysis';
import { OrganizationOfFile } from '../../../services/types/analysis';
import { TIMERs, Translation } from '../../../static';
import { isDeepEqual, datasetHasFiles } from '../../../utils';
import { Form, SelectField } from '../../common';
import { Slider } from '../../common/Slider';
import { useAnalysis } from '../analysis-context';
import { AnalysisFooterComponent } from '../AnalysisFooter';
import { useValidation } from './validation-context';
import {useEffect, useState} from "react";

const ValidationBody = styled(Form)`
  height: 70%;
  margin-top: 20px;
  display: grid;
  grid-template-rows: 35px 1fr;

  .v-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
`;
const { validation } = Translation.analysis;

const columnSeparatorsValues = [
  {
    label: 'Vertical bar (|)',
    value: '|',
  },
  {
    label: 'Comma (,)',
    value: ',',
  },
  {
    label: 'Semicolon (;)',
    value: ';',
  },
  {
    label: 'Tilda (~)',
    value: '~',
  },
];

const decimalSeparatorValues = [
  {
    label: 'Dot (.)',
    value: '.',
  },
  {
    label: 'Comma (,)',
    value: ',',
  },
];

const formNames = Object.freeze({
  columnSeparator: 'columnSeparator',
  textQuoted: 'textQuoted',
  decimalSeparator: 'decimalSeparator',
  dateFormat: 'dateFormat',
});

const initialState = {
  columnSeparator: columnSeparatorsValues[0].value,
  textQuoted: false,
  decimalSeparator: columnSeparatorsValues[2].value,
  dateFormat: '',
};
const fixedWithStyled = { width: 250 };
const LIST_OF_DATES = Object.freeze([
  'YYYY-MM-DD',
  'YYYY/MM/DD',
  'YY-MM-DD',
  'YY/MM/DD',
  'DD-MM-YYYY',
  'DD/MM/YYYY',
  'DD-MM-YY',
  'DD/MM/YY',
  'YYYYMMDD',
  'DDMMYYYY',
  'YYMMDD',
  'DDMMYY',
]);

export const OrganizationOfFiles = ({ goToSecondPage }: { goToSecondPage: () => void }) => {
  const { translate } = useTranslation();
  const { show, clean } = useCleanPopup();
  const { goBackward, state, setState } = useAnalysis();
  const { setFileHeader, setColumnSeparator, columnSeparator } = useValidation();
  const orgOfFiles = state.validation?.organizationOfFile || initialState;
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const [autoSeparator, setAutoSeparator] = useState(columnSeparator);

  useEffect(() => {
    if(datasetHasFiles(state.dataset)) {
      const selectedFiles = state.selectedFiles ?? []
      const firstSelectedFile = state.dataset?.files.filter((_, index:number) => selectedFiles[index]).find(() => true)
      if(firstSelectedFile != null) {
        setAutoSeparator(firstSelectedFile.separator)
        setColumnSeparator(firstSelectedFile.separator)
      }
      return
    }
    const fetchData = async () => {
      await getAutoColSeparator(state.dataset?._id)
          .then(result => {
            setAutoSeparator(result[0]);
            setColumnSeparator(result[0]);
          });
    }

    fetchData().catch(console.error);
  }, [state.dataset?._id]);

  const onSubmit = async (organizationOfFiles: OrganizationOfFile) => {
    if (organizationOfFiles.columnSeparator === organizationOfFiles.decimalSeparator) {
      show(translate(validation.sameOperatorValidationMsg));
      clean(TIMERs.threeSeconds);
      return;
    }

    let checkDatasetParams:any = {
      columnSeparator: organizationOfFiles.columnSeparator,
      datasetId: state.dataset?._id,
    }
    if(datasetHasFiles(state.dataset)) {
      const selectedFiles = state.selectedFiles ?? []
      const firstSelectedIndex = selectedFiles.indexOf(true)
      checkDatasetParams.offset = firstSelectedIndex
    }

    checkDatasets(checkDatasetParams).then(({ duplicateColumns }) => {
      if (duplicateColumns) {
        show(translate(validation.sameColsValidationMsg));
        clean(TIMERs.threeSeconds);
        return;
      }

      const callback = () => {
        const isTheSameConfig = isDeepEqual<OrganizationOfFile, OrganizationOfFile | {}>(
          organizationOfFiles,
          state.validation?.organizationOfFile ?? {}
        );

        const newState = isTheSameConfig
          ? state
          : {
              ...state,
              options: null,
              validation: {
                identificationOfColumns: null,
              },
            };

        setState({
          ...newState,
          validation: {
            identificationOfColumns: newState.validation?.identificationOfColumns,
            organizationOfFile: organizationOfFiles,
          },
        });

        goToSecondPage();
      };

      setFileHeader(organizationOfFiles, callback);
    });
  };

  const onColSeparatorSelectChange = (val: any) => {
    if (val) {
      setColumnSeparator(val.value);
    }
  }

  return (
    <>
      <ValidationBody
        className='border v-body'
        name='organizationOfFiles'
        formNames={formNames}
        onSubmit={onSubmit}
      >
        <section className='v-title'>{translate(validation.fileOrganizationTitle)}</section>

        <section className='v-form'>
          <SelectField
            style={fixedWithStyled}
            inline={false}
            defaultValue={autoSeparator}
            values={columnSeparatorsValues}
            name={formNames.columnSeparator}
            label={translate(validation.columnSeparatorLabel)}
            onChange={onColSeparatorSelectChange}
          />

          <Slider
            style={fixedWithStyled}
            defaultChecked={orgOfFiles?.textQuoted}
            name={formNames.textQuoted}
            label={translate(validation.textQuotedLabel)}
            inline={false}
          />

          <SelectField
            inline={false}
            style={fixedWithStyled}
            defaultValue={orgOfFiles?.decimalSeparator}
            values={decimalSeparatorValues}
            name={formNames.decimalSeparator}
            label={translate(validation.decimalSeparatorLabel)}
          />

          <SelectField
            inline={false}
            style={fixedWithStyled}
            defaultValue={orgOfFiles.dateFormat}
            values={LIST_OF_DATES.map(value => ({ label: value, value }))}
            name={formNames.dateFormat}
            label={translate(validation.dateFormat)}
          />
        </section>
        <button style={{ display: 'none' }} ref={btnRef}></button>
      </ValidationBody>

      <AnalysisFooterComponent
        className='v-footer'
        btnText={translate(validation.validate)}
        goBackward={goBackward}
        goForward={() => btnRef.current?.click()}
      />
    </>
  );
};
