import { NavLink, NavLinkProps } from 'react-router-dom';
import { Role } from '../../services/types/authentication';
import { stopPropagation } from '../../utils';

type LinkProps = {
  roles: string[];
  currRole: Role;
  path: string;
};

export const Link = ({ roles, currRole, path, to, ...restProps }: NavLinkProps & LinkProps) => {
  return roles.includes(currRole?.type) ? (
    <NavLink {...restProps} to={to} onClick={event => stopPropagation(event, path, to as string)} />
  ) : null;
};
