import { Loader } from '../components/common';
import { DownloadError, PopUpMessageColor } from '../components/settings/users/constants';
import { useTranslation } from '../providers';
import {FileExtensions, FileTypes, TIMERs, Translation} from '../static';
import { useCleanPopup } from './useCleanPopup';
import { useEffect, useState } from 'react';
import {isNil} from "lodash";

export const useDownload = (
  callback: (arg: any) => Promise<string>,
  name: string,
  type: string = 'text/csv'
) => {
  const { show, clean } = useCleanPopup();
  const { translate } = useTranslation();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (showLoader) show(<Loader progress={progress} />);
  }, [progress, show, showLoader]);

  return () => {
    setShowLoader(true);
    callback(setProgress)
      .then((content: string) => {
        setShowLoader(true);
        content && downloader(name ?? 'download.csv', content, type);
        setShowLoader(false);
        clean();
      })
      .catch(async err => {
        const text = await err.response.data.text();
        const textError = JSON.parse(text).error;
        switch (textError) {
          case DownloadError.socketHangUp:
            show(translate(Translation.download.requireTimeDownload), {
              color: PopUpMessageColor.green,
            });
            clean(TIMERs.fiveSeconds);
            break;
          default:
            show(translate(Translation.download.downloadLinkAlreadyUsed));
            clean(TIMERs.fiveSeconds);
        }
      });
  };
};

function downloader(name: string, content: string, type = 'text/csv') {
  const blob = new Blob([content], { type });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  if (name !== 'download.csv') {
    const nameArr = name.split('.')
    const tmpExtension = '.' + nameArr[nameArr.length - 1]
    const fExtension = Object.values(FileExtensions).find(value => value === tmpExtension)

    if (isNil(fExtension)) {
      const fileExtension = (Object.keys(FileTypes) as Array<FileExtensions>)
          .find(key => FileTypes[key] === type)
      name = name + fileExtension
    }
  }

  link.download = name;
  link.href = url;
  link.click();
}
