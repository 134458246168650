import * as React from 'react';
import styled from 'styled-components';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { useTranslation } from '../../../providers';
import { validateColumns } from '../../../services/analysis';
import { Validation } from '../../../services/types/analysis';
import { ServiceParameter } from '../../../services/types/settings';
import { Colors, TIMERs, Translation } from '../../../static';
import { debounce, delay, isDeepEqual, validateField } from '../../../utils';
import {
  extractFormElements,
  Form,
  Loader,
  LongLeftArrow,
  MessageComponent,
  SelectField,
  SelectFieldValue,
} from '../../common';
import { Circle, SmallErrorIcon, SuccessIcon } from '../../common/icons';
import { useAnalysis } from '../analysis-context';
import { AnalysisFooterComponent } from '../AnalysisFooter';
import { useValidation } from './validation-context';

const ValidationBody = styled(Form)`
  display: grid;
  grid-template-rows: 35px minmax(0, 1fr);

  .row {
    display: grid;
    grid-template-columns: 33% 12% 50% 5%;
    align-items: center;
    padding: 15px;
  }
`;
const { validation } = Translation.analysis;
type IdenOfCols = Record<string, string>;

export const IdentificationOfColumns = ({ goToFirstPage }: { goToFirstPage: () => void }) => {
  const { translate } = useTranslation();
  const { state, goForward } = useAnalysis();
  const { show, clean } = useCleanPopup();
  const { fileHeader: dropdownValues = [] } = useValidation();
  const [statuses, setStatuses] = React.useState<Record<string, boolean>>({});
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const formNames = generateFormNames(state?.service?.parameters);
  const parameters = state?.service?.parameters;
  const formRef = React.useRef<HTMLFormElement>(null);

  const onSubmit = (identificationOfColumns: IdenOfCols) => {
    show(<Loader />);
    validateColumns({
      identificationOfColumns,
      organizationOfFile: state.validation?.organizationOfFile,
      datasetId: state.dataset?._id,
      serviceId: state.service._id,
       selectedFiles: state.selectedFiles ?? []
    })
      .then(response => {
        const isFileValid = Object.values(response).every(Boolean);

        if (isFileValid) {
          const isTheSameConfig = isDeepEqual<IdenOfCols, any>(
            identificationOfColumns,
            state.validation?.identificationOfColumns ?? {}
          );

          goForward(
            {
              options: isTheSameConfig ? state.options : null,
              validation: {
                ...state.validation,
                identificationOfColumns,
              } as Validation,
            },
            !isTheSameConfig
          );
        } else setStatuses(response);

        clean();
      })
      .catch(() => {
        show(translate(validation.failedToOpenFile, [state.dataset?.name]));
        clean(TIMERs.threeSeconds);
      });
  };
  return (
    <>
      <ValidationBody
        ref={formRef}
        formNames={formNames}
        name='identificationOfColumns'
        onSubmit={onSubmit}
        className='border v-body'
      >
        <section className='v-title'>{translate(validation.identificationOfColsTitle)}</section>

        <section style={{ overflowY: 'auto', padding: '0 10%' }} data-testid='parameters-form'>
          {parameters?.length ? (
            <>
              {parameters.map(({ name: parameter, mandatory }, index: number) => {
                const status = statuses?.[parameter];
                const name = formNames[parameter];
                const defaultValue = state.validation?.identificationOfColumns?.[name];
                const validateFn = (target?: (EventTarget & HTMLSelectElement) | null) =>
                  delay(
                    () => validateField(translate(validation.idenOfColsErrorValidationMsg), target),
                    parameters.length - index
                  );

                return (
                  <section key={index} className='row'>
                    <label>{parameter}</label>
                    <LongLeftArrow width={50} />
                    <SelectField
                      includeStar
                      includeNone
                      callback={status === false ? validateFn : undefined}
                      errorMsg={translate(validation.identificationOfColsValidationMsg, [name])}
                      required={mandatory}
                      onChange={(data, target) => {
                        if (!data) {
                          const message = mandatory
                            ? translate(validation.identificationOfColsValidationMsg, [name])
                            : '';
                          validateField(message, target);
                        } else if (formRef.current) {
                          const keys = Object.keys(formNames);
                          const elements: Record<string, string> = extractFormElements(
                            keys,
                            formRef.current
                          );

                          const isFound = keys
                            .filter(key => key !== target?.name)
                            .some(key => elements[key] === data.value);
                          if (isFound)
                            debounce(() => {
                              validateField(
                                translate(validation.duplicateOfColsValidationMsg),
                                target
                              );
                            }, 0);
                        }
                      }}
                      name={name}
                      values={dropdownValues as SelectFieldValue[]}
                      defaultValue={defaultValue}
                      inline
                    />

                    {status == null ? null : (
                      <Circle
                        size={20}
                        bgColor={status ? Colors.lightGreen : Colors.orange}
                        style={{ justifySelf: 'flex-end' }}
                      >
                        {status ? <SuccessIcon /> : <SmallErrorIcon />}
                      </Circle>
                    )}
                  </section>
                );
              })}
              <section style={{ color: Colors.error, textAlign: 'right' }}>
                {translate(validation.identificationOfColsMandatoryFields)}
              </section>
            </>
          ) : (
            <MessageComponent
              color={Colors.menuColor}
              children={translate(validation.identificationOfColsNoParameters)}
              style={{ marginTop: 50, fontSize: 14 }}
            />
          )}
        </section>
        <button style={{ display: 'none' }} ref={btnRef}></button>
      </ValidationBody>

      <AnalysisFooterComponent
        className='v-footer'
        btnText={translate(validation.validate)}
        forwardDisabled={!parameters?.length}
        goBackward={() => goToFirstPage()}
        goForward={() => btnRef.current?.click()}
      />
    </>
  );
};

function generateFormNames(parameters: ServiceParameter[]): Record<string, string> {
  return parameters?.reduce((acc: Record<string, string>, { name }: ServiceParameter) => {
    acc[name] = name;

    return acc;
  }, {});
}
