import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
  initialState,
  ResultsProvider,
  State,
  useResults,
  useResultsDispatch,
} from './results-context';

const Results = ({ state }: { state: State | null }) => {
  const { steps } = useResults();
  const dispatch = useResultsDispatch();

  React.useEffect(() => {
    if (state !== null) dispatch({ type: 'setState', state });
  }, [dispatch, state]);

  const { Component } = steps.find(({ isActive }) => isActive) ?? { Component: () => <></> };

  return <Component />;
};

export const ResultsComponent = () => {
  let { state }: { state: State } = useLocation();
  if (!state) {
    state = {
      steps: [{ isActive: true, Component: () => <></> }],
      service: initialState.service,
      result: null,
    };
  }

  state = state
    ? {
        steps: initialState.steps.map(({ Component }, index) => ({
          ...state.steps[index],
          Component,
        })),
        service: state?.service ?? initialState.service,
        result: state?.result ?? null,
      }
    : initialState;

  return (
    <ResultsProvider>
      <Results state={state} />
    </ResultsProvider>
  );
};
