import * as React from 'react';
import { useCleanPopup } from '../../../hooks/useCleanPopup';
import { useTranslation } from '../../../providers';
import { getKpiData } from '../../../services/results';
import { DuplicatePaymentsKpiData, KPIData } from '../../../services/types/results';
import { TIMERs, Translation } from '../../../static';
import { Loader } from '../../common';
import { useResults } from '../results-context';
import { AnalysisResultsComponent } from './AnalysisResults';
import { DataAnalysedComponent } from './DataAnalysed';
import { NoData } from './styled';

const { results } = Translation;
type Props = KPIData & { data: DuplicatePaymentsKpiData[] };

export const KPIComponent = () => {
  const { translate } = useTranslation();
  const { service, result } = useResults();
  const { show, clean } = useCleanPopup();
  const [kpiData, setKpiData] = React.useState<Props | null>(null);

  React.useEffect(() => {
    show(<Loader />);
    getKpiData(result?._id)
      .then((data: KPIData | null) => {
        setKpiData(data ? { ...data, data: [...data.rawData] } : null);
        clean();
      })
      .catch(() => {
        show(translate(results.failedToGetKpiData));
        clean(TIMERs.threeSeconds);
      });
  }, [clean, result?._id, service?.name, show, translate]);

  return kpiData?.rawData?.length ? (
    <>
      <DataAnalysedComponent kpiData={kpiData} />
      <AnalysisResultsComponent kpiData={kpiData} />
    </>
  ) : (
    <NoData>{translate(results.noKpiData)}</NoData>
  );
};
