import React from 'react';
import { Colors } from '../../../static';

export const Logo = () => (
  <svg width='56' height='56' viewBox='0 0 56 56' fill='none'>
    <path
      d='M46.6444 36.3532H39.0263C37.5561 36.3532 36.3532 35.2172 36.2864 33.747V22.9212C36.2864 21.1169 34.8162 19.6468 33.0119 19.6468H22.3198C20.8496 19.6468 19.6468 18.4439 19.58 16.9737V9.35561C19.58 4.1432 15.3699 0 10.2243 0H3.47494C1.53699 0 0 1.53699 0 3.47494V10.2243C0 15.4368 4.21002 19.58 9.35561 19.58H16.9069C18.3771 19.58 19.58 20.7828 19.6468 22.253V33.5465C19.6468 35.0167 18.4439 36.2196 16.9737 36.2864H9.35561C4.1432 36.2864 0 40.4964 0 45.642V52.3914C0 54.3294 1.53699 55.8663 3.47494 55.8663H10.2243C15.4368 55.8663 19.58 51.6563 19.58 46.5107V39.0263C19.58 37.5561 20.7828 36.3532 22.253 36.2864H33.5465C35.0167 36.2864 36.2864 37.4893 36.2864 39.0263V46.5776C36.2864 51.79 40.4964 55.9332 45.642 55.9332H52.3914C54.3294 55.9332 55.8663 54.3962 55.8663 52.4582V45.7088C56 40.5632 51.79 36.3532 46.6444 36.3532Z'
      fill={Colors.black}
    />
    <path
      d='M41.5656 18.9785H45.5083C48.3818 18.9785 50.7876 16.6396 50.7876 13.6993V7.35083C50.7876 5.74702 49.5179 4.47733 47.9141 4.47733H41.5656C38.6921 4.47733 36.2864 6.81623 36.2864 9.75656V13.6993C36.3532 16.6396 38.6921 18.9785 41.5656 18.9785Z'
      fill={Colors.black}
    />
  </svg>
);

type CircleProps = {
  size: number;
  borderWidth?: number;
  borderColor?: string;
  color?: string;
  bgColor?: string;
  fontSize?: number;
  children?: React.ReactNode | React.ReactNode[];
  onClick?: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
};

export const Circle = ({
  size,
  color,
  bgColor,
  children,
  onClick,
  fontSize,
  borderColor,
  borderWidth,
  style = {},
}: CircleProps) => (
  <section
    style={{
      ...style,
      height: size,
      width: size,
      borderRadius: '50%',
      color,
      backgroundColor: bgColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: fontSize,
      border: `${borderWidth}px solid ${borderColor}`,
    }}
    onClick={onClick}
  >
    {children}
  </section>
);

type ScrollProps = {
  disabled: boolean;
  onClick: React.MouseEventHandler<SVGSVGElement>;
};

export const ScrollRightIcon = ({ disabled, onClick }: ScrollProps) => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    opacity={disabled ? '0.5' : '1'}
    cursor={disabled ? 'not-allowed' : 'pointer'}
  >
    <g style={{ pointerEvents: disabled ? 'none' : 'auto' }} onClick={onClick}>
      <rect width='28' height='28' rx='14' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.002 8.92082C11.002 7.99427 11.5843 7.72162 12.3021 8.31138L19.4323 14.1697C19.751 14.4316 19.7472 14.8507 19.4219 15.1071L12.3238 20.7026C11.5938 21.2781 11.002 20.993 11.002 20.0669L11.002 8.92082Z'
        fill={Colors.blue}
      />
    </g>
  </svg>
);

export const ScorllLeftIcon = ({ disabled, onClick }: ScrollProps) => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    opacity={disabled ? '0.5' : '1'}
    cursor={disabled ? 'not-allowed' : 'pointer'}
  >
    <g onClick={onClick} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
      <rect
        x='28'
        y='28'
        width='28'
        height='28'
        rx='14'
        transform='rotate(-180 28 28)'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 20.0792C17 21.0057 16.4177 21.2784 15.6999 20.6886L8.56968 14.8303C8.25092 14.5684 8.2548 14.1493 8.58005 13.8929L15.6782 8.29741C16.4082 7.72192 17 8.00704 17 8.93306L17 20.0792Z'
        fill={Colors.blue}
      />
    </g>
  </svg>
);

export * from './menu';
export * from './submenu';
export * from './big-tile';
export * from './login';
export * from './header';
export * from './Photo';
export * from './checkmark';
export * from './bin';
export * from './Download';
export * from './SmallErrorIcon';
export * from './SuccessIcon';
export * from './BigErrorIcon';
export * from './CloseIcon';
export * from './EditFileIcon';