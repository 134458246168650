import { Endpoint } from '../static';
import { AuthFetch } from './request';
import { Dataset } from './types/analysis';

export const deleteDataset = async (datasetId: string): Promise<Dataset> => {
  return AuthFetch.DELETE<Dataset>(`${Endpoint.deleteDataset}/${datasetId}`);
};

export const deleteDatasetFile = async (datasetId: string, offset?:string): Promise<Dataset> => {
  return AuthFetch.DELETE<Dataset>(`${Endpoint.deleteDataset}/${datasetId}${offset === undefined ? '' : `/${offset}`}`);
};

export const downloadDataset = async (
  datasetId: string,
  fileSize: string,
  setProgress: any
): Promise<string> => {
  return AuthFetch.GET<string>(
    `${Endpoint.downloadDataset}/${datasetId}`,
    'blob',
    fileSize,
    setProgress
  );
};
