export enum ServiceEnum {
  DuplicatePayments = 'Duplicate Payments',
  VatErrors = 'VAT Errors',
  AnalyticsReports = 'Analytics Reports',
  PriceAnalysis = 'Price Analysis',
}

export enum ListType {
  List = 'List',
  Slider = 'Slider',
  Value = 'Value',
  Text = 'Text',
  Date = 'Date',
  RangeDate = 'RangeDate',
}

interface OptionsList {
  _id: string;
  name: string;
  value: string | number;
  enabled: boolean;
}

export interface ServiceLevel {
  _id: string;
  priority: number;
  name: string;
  enabled: boolean;
}

export interface Option {
  _id?: string;
  title: string;
  name: string;
  type: ListType;
  defaultValue: string | number | null;
  serviceLevels: ServiceLevel[];
  optionList: OptionsList[];
}

export interface ServiceParameter {
  name: string;
  type: string;
  mandatory: boolean;
  field: string;
}

export interface Service {
  _id: string;
  name: ServiceEnum;
  description: string;
  levels: ServiceLevel[];
  parameters: ServiceParameter[];
  options: Option[];
  servicePlatformId: number;
  order: number;
  photo: string;
  isReport: boolean;
  hasDashboardOnResults: boolean;
}

export interface ClientService extends Service {
  key?: string;
  enabled: boolean;
  display: boolean;
  level: string;
}

export interface SystemParameters {
  outputFolder: string;
  inputFolder: string;
  maxFileSize: number | undefined;
  disabled: boolean;
  retentionPeriod: string;
}

export enum OnlineOptionType {
  FileFormat = 'fileFormat',
  Options = 'options',
  ListOfFields = 'listOfFields',
}

export interface OnlineHelpOption {
  name: string;
  enabled: boolean;
  type: OnlineOptionType;
}
