import * as React from 'react';
import {ReactNode} from 'react';
import {TooltipContext, useTooltipContextBuilder} from "./tooltip-context";
import {Tooltip} from "./Tooltip";

type TooltipContextProviderProps = {
  children: ReactNode
};
export const TooltipContextProvider = ({children}: TooltipContextProviderProps) => {
  const context = useTooltipContextBuilder()
  return (
      <TooltipContext.Provider value={context}>
        <Tooltip>{children}</Tooltip>
      </TooltipContext.Provider>
  );
};
