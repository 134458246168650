import { getLanguage } from './account';

type Translate = (key: string, params: any[], lang: string) => string;

type LanguageResource = Record<string, Record<string, string>>;

interface ITranslationService {
  setLanguageResources: (language: string, resource: Record<string, any>) => void;
  translate: Translate;
  has: (language: string) => boolean;
}

const getMessageWithParams = (message: string, params: any[]): string =>
  message?.split('${}').reduce((acc, value, index) => `${acc}${params[index - 1]}${value}`);

export const DEFAULT_LANG = localStorage.getItem('lang') || 'en';
export const TranslationService = ((): ITranslationService => {
  const languages: LanguageResource = {};

  return {
    setLanguageResources: (lang: string, resource: Record<string, string>) =>
      (languages[lang] = resource),
    translate: (key, params, lang) => {
      const message = languages?.[lang]?.[key];

      return params.length > 0 ? getMessageWithParams(message, params) : message;
    },
    has: (language: string) => languages[language] != null,
  };
})();

export const saveLangToLocalStorage = (lang: string) => localStorage.setItem('lang', lang);

export const getLanguageResources = async (isoCode: string) => {
  try {
    if (!TranslationService.has(isoCode)) {
      const { translations } = await getLanguage(isoCode);

      if (!translations) throw Error('Language not found');

      TranslationService.setLanguageResources(isoCode, translations);
    }
  } catch (err) {
    throw err;
  }
};

export const _forTesting = {
  getMessageWithParams,
  Translation: TranslationService,
  getLanguageResources,
};
